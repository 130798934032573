import { createStore } from "vuex";
// import { app } from "../main"; //匯入我們的Vue Instance

export default createStore({
  state: {
    lang: "cn",
    user: null,
    employer: null,
    employer_detail: null,
    employer_check: null,
    employer_search: "employer",
    borrow: null,
    holiday: {
      apply_days: 0,
      apply_start_date: "",
      apply_end_date: "",
      reason: "",
      apply_type: [],
    },
    salary: {
      reason: "",
      effective_date: "",
      before_salary: 0,
      new_salary: 0,
      type: [],
    },
    employer_detail_salary_batch: [],
    salary_batch: [],
    shift_search: "shift",
    water: [],
    logo: null,
    payment: null,
    payment_content: [],
    bouns_employer: [],
    show_position: false,
    payment_batch: [],
    shift_search_type: "Info",
  },
  mutations: {
    set_shift_search_type(state, shift_search_type) {
      state.shift_search_type = shift_search_type;
    },
    set_shift_search(state, shift_search) {
      state.shift_search = shift_search;
    },
    set_employer_detail_salary_batch(state, employer_detail_salary_batch) {
      state.employer_detail_salary_batch = employer_detail_salary_batch;
    },
    set_salary_batch(state, salary_batch) {
      state.salary_batch = salary_batch;
    },
    set_payment_batch(state, payment_batch) {
      state.payment_batch = payment_batch;
    },
    set_show_position(state, show_position) {
      state.show_position = show_position;
    },
    set_bouns_employer(state, bouns_employer) {
      state.bouns_employer = bouns_employer;
    },
    set_payment(state, payment) {
      state.payment = payment;
    },
    set_payment_content(state, payment_content) {
      state.payment_content = payment_content;
    },
    set_logo(state, logo) {
      state.logo = logo;
    },
    set_lang(state, value) {
      state.lang = value;
    },
    set_user(state, user) {
      state.user = user;
    },
    set_employer(state, employer) {
      state.employer = employer;
    },
    set_employer_check(state, employer_check) {
      state.employer_check = employer_check;
    },
    set_employer_detail(state, employer_detail) {
      state.employer_detail = employer_detail;
    },
    set_employer_search(state, employer_search) {
      state.employer_search = employer_search;
    },
    set_borrow(state, borrow) {
      state.borrow = borrow;
    },
    set_holiday(state, holiday) {
      state.holiday = holiday;
    },
    set_salary(state, salary) {
      state.salary = salary;
    },
    set_water(state, water) {
      state.water = water;
    },
  },
  actions: {},
  modules: {},
});
