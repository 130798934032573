<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import Chip from "primevue/chip";
import router from "../../router";
import Toast from "primevue/toast";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputNumber";
import Button from "primevue/button";
import DataTable from "primevue/dataTable";
import Sidebar from "primevue/sidebar";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import { dateFormate } from "../../tool/formate";
import PrintSalary from "../../components/PrintSalary.vue";
import PrintSalaryBatch from "../../components/PrintSalaryBatch.vue";
import { printHTML } from "../../tool/print";
import Employer from "../../components/Employer.vue";
import User from "../../api/salary/auth/user";

import ManagerEmployer from "../../api/salary/manager/employer";
import AdminEmployer from "../../api/salary/admin/employer";
import ManagerEmployerDetail from "../../api/salary/manager/employer_detail";
import AdminEmployerDetail from "../../api/salary/admin/employer_detail";

import ManagerSalaryRaise from "../../api/salary/manager/salary_raise";
import AdminSalaryRaise from "../../api/salary/admin/salary_raise";

let manager_salary_raiseAPI = new ManagerSalaryRaise();
let admin_salary_raiseAPI = new AdminSalaryRaise();

let manager_employerAPI = new ManagerEmployer();
let admin_employerAPI = new AdminEmployer();
let manager_employer_detailAPI = new ManagerEmployerDetail();
let admin_employer_detailAPI = new AdminEmployerDetail();

let userApi = new User();
const i18n = useI18n();
const toast = useToast();
const store = useStore();
const printDom = ref(null);
const data = reactive({
  print: true,
  reason: "",
  minDate: new Date(),
  salary_raises: { data: null },
  salary_raise: null,
  salary_raiseLoading: false,
  page: 1,
  page_num: 10,
  totalRecords: 0,
  chooseDialog: false,
  printDialog: false,
  send: true,
  add_salary: 0,
  date_search: "",
  employer: {
    organisation_name: "",
    name: "",
    english_name: "",
    position_name: "",
  },
  // add_salary_raise: {
  //   reason: "",
  //   effective_date: "",
  //   before_salary: 0,
  //   new_salary: 0,
  //   type: [],
  // },
  add_salary_raise: [],
  salary_raiseFilters: {
    apply_date_begin: { value: "", matchMode: "contains" },
    apply_date_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  CheckUser();
  getSalaryRaiseList();
  // store.commit("set_employer_search", "apply_salary");
  // store.commit("set_employer", null);
  store.commit("set_employer_search", "apply_bouns");
  store.commit("set_bouns_employer", []);
  data.minDate.setDate(data.minDate.getDate() + 1);
});
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      store.commit("set_user", null);
    }
  });
}
function ChooseEmployer() {
  data.chooseDialog = true;
  // console.log(store.state.employer);
}
// async function printApplySalary(salary, dom) {
//   await store.commit("set_salary", salary);
//   switch (store.state.user.role) {
//     case 1:
//       admin_employerAPI
//         .getEmployerInfo(salary.apply_employer_id)
//         .then(async (res) => {
//           if (res.data.error_code == 0) {
//             await store.commit("set_employer", res.data.data);
//           } else {
//             toast.add({
//               severity: "warn",
//               summary: "Warn Message",
//               detail: res.data.error_text,
//               life: 3000,
//             });
//           }
//         })
//         .then(() => {
//           admin_employer_detailAPI
//             .getEmployerDetailInfo(salary.apply_employer_id)
//             .then(async (res) => {
//               if (res.data.error_code == 0) {
//                 await store.commit("set_employer_detail", res.data.data);
//               } else {
//                 toast.add({
//                   severity: "warn",
//                   summary: "Warn Message",
//                   detail: res.data.error_text,
//                   life: 3000,
//                 });
//               }
//             })
//             .then(() => {
//               printHTML(dom);
//             });
//         });
//       break;
//     case 2:
//       manager_employerAPI
//         .getEmployerInfo(salary.apply_employer_id)
//         .then(async (res) => {
//           if (res.data.error_code == 0) {
//             await store.commit("set_employer", res.data.data);
//           } else {
//             toast.add({
//               severity: "warn",
//               summary: "Warn Message",
//               detail: res.data.error_text,
//               life: 3000,
//             });
//           }
//         })
//         .then(() => {
//           manager_employer_detailAPI
//             .getEmployerDetailInfo(salary.apply_employer_id)
//             .then(async (res) => {
//               if (res.data.error_code == 0) {
//                 await store.commit("set_employer_detail", res.data.data);
//               } else {
//                 toast.add({
//                   severity: "warn",
//                   summary: "Warn Message",
//                   detail: res.data.error_text,
//                   life: 3000,
//                 });
//               }
//             })
//             .then(() => {
//               printHTML(dom);
//             });
//         });

//       break;
//   }
// }

async function printApplySalary(dom) {
  let employer_id = [];
  for (let i = 0; i < store.state.bouns_employer.length; i++) {
    employer_id.push(store.state.bouns_employer[i].id);
  }
  console.log(data.add_salary_raise);
  switch (store.state.user.role) {
    case 1:
      admin_employer_detailAPI
        .getEmployerDetailReportList(employer_id)
        .then(async (res) => {
          if (res.data.error_code == 0) {
            // await store.commit(
            //   "set_employer_detail_salary_batch",
            //   res.data.data.datas
            // );
            let tmp_employer = res.data.data.datas;
            // await store.commit("set_salary_batch", data.add_salary_raise);

            let tmp = [];
            for (let i = 0; i < data.add_salary_raise.length; i++) {
              await tmp.push({
                organisation_name: data.add_salary_raise[i].organisation_name,
                apply_employer_id: data.add_salary_raise[i].apply_employer_id,
                employer_name: data.add_salary_raise[i].employer_name,
                employer_english_name:
                  data.add_salary_raise[i].employer_english_name,
                positions: data.add_salary_raise[i].positions,
                effective_date: data.add_salary_raise[i].effective_date,
                before_salary: data.add_salary_raise[i].before_salary,
                add_salary: data.add_salary_raise[i].add_salary,
                new_salary: data.add_salary_raise[i].new_salary,

                birthday: tmp_employer.find(
                  (person) =>
                    person.employer_id ===
                    data.add_salary_raise[i].apply_employer_id
                ).birthday,
                identity_card_number: tmp_employer.find(
                  (person) =>
                    person.employer_id ===
                    data.add_salary_raise[i].apply_employer_id
                ).identity_card_number,
                start_working: tmp_employer.find(
                  (person) =>
                    person.employer_id ===
                    data.add_salary_raise[i].apply_employer_id
                ).start_working,
                department:
                  (await tmp_employer.find(
                    (person) =>
                      person.employer_id ===
                      data.add_salary_raise[i].apply_employer_id
                  ).departments) != undefined
                    ? await tmp_employer.find(
                        (person) =>
                          person.employer_id ===
                          data.add_salary_raise[i].apply_employer_id
                      ).departments[0].name
                    : "",
              });
            }
            // tmp.sort((a, b) => a.departments - b.departments);
            // console.log(
            //  await tmp.sort((a, b) => {
            //     return a.department - b.department;
            //   })
            // );
            await tmp.sort((a, b) => b.department.localeCompare(a.department));
            // console.log(tmp);
            store.commit("set_salary_batch", tmp);
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          // console.log(data.add_salary_raise);
          data.print = false;
          printHTML(dom);
        });
      break;
    case 2:
      // manager_employerAPI

      break;
  }
}

function saveApplySalary() {
  let type = "";
  for (let i = 0; i < data.add_salary_raise.type.length; i++) {
    type = type + `(${data.add_salary_raise.type[i]})`;
  }
  for (let i = 0; i < data.add_salary_raise.length; i++) {
    data.add_salary_raise[i].reason = type + data.reason;
    data.add_salary_raise[i].effective_date =
      data.add_salary_raise[i].effective_date != "" &&
      data.add_salary_raise[i].effective_date != null
        ? data.add_salary_raise[i].effective_date.toISOString()
        : "";
  }
  switch (store.state.user.role) {
    case 1:
      admin_salary_raiseAPI
        .batchSalaryRaise(data.add_salary_raise)
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("ApplySalary").AddSuccess,
              life: 3000,
            });
            data.salary_raiseLoading = true;
            data.send = true;
            getSalaryRaiseList();
            store.commit("set_bouns_employer", []);
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            store.commit("set_bouns_employer", []);
          }
        });
      break;
    case 2:
      manager_salary_raiseAPI
        .batchSalaryRaise(data.add_salary_raise)
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("ApplySalary").AddSuccess,
              life: 3000,
            });
            data.salary_raiseLoading = true;
            data.send = true;
            getSalaryRaiseList();
            store.commit("set_bouns_employer", []);
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            store.commit("set_bouns_employer", []);
          }
        });
      break;
  }
  data.print = true;
  // switch (store.state.user.role) {
  //   case 1:
  //     admin_salary_raiseAPI
  //       .addSalaryRaise(
  //         String(data.add_salary_raise.before_salary),
  //         String(data.add_salary_raise.new_salary),
  //         type + data.add_salary_raise.reason,
  //         data.add_salary_raise.effective_date != "" &&
  //           data.add_salary_raise.effective_date != null
  //           ? data.add_salary_raise.effective_date.toISOString()
  //           : "",
  //         store.state.employer != null ? store.state.employer.id : 0
  //       )
  //       .then((res) => {
  //         if (res.data.error_code == 0) {
  //           toast.add({
  //             severity: "success",
  //             summary: "Success Message",
  //             detail: i18n.$t("ApplySalary").AddSuccess,
  //             life: 3000,
  //           });
  //           data.salary_raiseLoading = true;
  //           data.send = true;
  //           getSalaryRaiseList();
  //         } else {
  //           toast.add({
  //             severity: "warn",
  //             summary: "Warn Message",
  //             detail: res.data.error_text,
  //             life: 3000,
  //           });
  //         }
  //       });
  //     break;
  //   case 2:
  //     manager_salary_raiseAPI
  //       .addSalaryRaise(
  //         String(data.add_salary_raise.before_salary),
  //         String(data.add_salary_raise.new_salary),
  //         type + data.add_salary_raise.reason,
  //         data.add_salary_raise.effective_date != "" &&
  //           data.add_salary_raise.effective_date != null
  //           ? data.add_salary_raise.effective_date.toISOString()
  //           : "",
  //         store.state.employer != null ? store.state.employer.id : 0
  //       )
  //       .then((res) => {
  //         if (res.data.error_code == 0) {
  //           toast.add({
  //             severity: "success",
  //             summary: "Success Message",
  //             detail: i18n.$t("ApplySalary").AddSuccess,
  //             life: 3000,
  //           });
  //           data.salary_raiseLoading = true;
  //           data.send = true;
  //           getSalaryRaiseList();
  //         } else {
  //           toast.add({
  //             severity: "warn",
  //             summary: "Warn Message",
  //             detail: res.data.error_text,
  //             life: 3000,
  //           });
  //         }
  //       });
  //     break;
  // }
}
function getSalaryRaiseList() {
  switch (store.state.user.role) {
    case 1:
      admin_salary_raiseAPI
        .getSalaryRaiseList(
          0,
          data.salary_raiseFilters.apply_date_begin.value,
          data.salary_raiseFilters.apply_date_end.value,
          0,
          "",
          0,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.salary_raises.data = res.data.data.datas;
            data.page = res.data.data.page;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            if (res.data.error_code == 8106) {
              data.totalRecords = 0;
              data.salary_raises.data = [];
              // data.page = 0;
            }
          }
          data.salary_raiseLoading = false;
        });
      break;
    case 2:
      manager_salary_raiseAPI
        .getSalaryRaiseList(
          0,
          data.salary_raiseFilters.apply_date_begin.value,
          data.salary_raiseFilters.apply_date_end.value,
          0,
          "",
          0,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.salary_raises.data = res.data.data.datas;
            data.page = res.data.data.page;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            if (res.data.error_code == 8106) {
              data.totalRecords = 0;
              data.salary_raises.data = [];
              // data.page = 0;
            }
          }
          data.salary_raiseLoading = false;
        });
      break;
  }
}
watch(
  () => data.add_salary_raise,
  () => {
    data.print = true;
    for (let i = 0; i < data.add_salary_raise.length; i++) {
      data.add_salary_raise[i].new_salary = (
        parseFloat(data.add_salary_raise[i].before_salary) +
        parseFloat(data.add_salary_raise[i].add_salary)
      )
        .toFixed(2)
        .toString();
    }
  },
  { deep: true }
);

watch(
  () => store.state.bouns_employer,
  () => {
    data.add_salary_raise = [];
    data.print = true;
    if (store.state.bouns_employer.length != 0) {
      // add_salary_raise: {
      //   reason: "",
      //   effective_date: "",
      //   before_salary: 0,
      //   new_salary: 0,
      //   type: [],
      // },
      for (let i = 0; i < store.state.bouns_employer.length; i++) {
        data.add_salary_raise.push({
          employer_name: store.state.bouns_employer[i].name,
          employer_english_name: store.state.bouns_employer[i].english_name,
          apply_employer_id: store.state.bouns_employer[i].id,
          before_salary: store.state.bouns_employer[i].salary,
          new_salary: store.state.bouns_employer[i].salary,
          add_salary: 0,
          effective_date: "",
          reason: "",
          organisation_name: store.state.bouns_employer[i].organisation_name,
          positions: store.state.bouns_employer[i].positions,
          // employer_detail: {
          //   departments: [],
          //   birthday: "",
          //   identity_card_number: "",
          //   start_working: "",
          // },
        });
      }
    }
  },
  { deep: true }
);

// watch(
//   () => store.state.employer,
//   () => {
//     if (store.state.employer != null) {
//       data.add_salary_raise.before_salary = parseFloat(
//         store.state.employer.salary
//       );
//       data.add_salary_raise.new_salary =
//         parseFloat(store.state.employer.salary) + data.add_salary;
//     }
//   }
// );
// watch(
//   () => data.add_salary,
//   () => {
//     data.add_salary_raise.new_salary =
//       data.add_salary_raise.before_salary + data.add_salary;
//   }
// );
watch(
  () => data.date_search,
  () => {
    data.holidayLoading = true;
    if (data.date_search != null) {
      data.salary_raiseFilters.apply_date_begin.value = new Date(
        data.date_search.getFullYear(),
        data.date_search.getMonth(),
        1
      ).toISOString();
      data.salary_raiseFilters.apply_date_end.value = new Date(
        data.date_search.getFullYear(),
        data.date_search.getMonth() + 1,
        1
      ).toISOString();
    } else {
      data.salary_raiseFilters.apply_date_begin.value = "";
      data.salary_raiseFilters.apply_date_end.value = "";
    }
    getSalaryRaiseList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 90px" class="p-text-left">
      <div class="p-grid">
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplySalary").Employer }}
        </div>
        <div class="p-col-12">
          <Button
            :label="i18n.$t('ApplySalary').SelectEmployer"
            class="p-mr-2 p-button-sm"
            @click="ChooseEmployer"
          />
          <span style="line-height: 35px">
            <Chip
              :key="index"
              :label="employer.id + ':' + employer.name"
              v-for="(employer, index) in store.state.bouns_employer"
            />
          </span>
          <!-- <span style="line-height: 35px">
            {{ i18n.$t("Employer").EmployerTable.Organisation.Show }}:<span
              v-if="store.state.employer != null"
              >{{ store.state.employer.organisation_name }}</span
            >
            {{ i18n.$t("Employer").EmployerTable.Name.Show }}:<span
              v-if="store.state.employer != null"
              >{{ store.state.employer.name }}</span
            >
            {{ i18n.$t("Employer").EmployerTable.EnglishName.Show }}:<span
              v-if="store.state.employer != null"
              >{{ store.state.employer.english_name }}</span
            >
            {{ i18n.$t("Employer").EmployerTable.PositionName.Show }}:<span
              v-if="store.state.employer != null"
            >
              <span
                v-if="
                  store.state.employer.positions != null &&
                  store.state.employer.positions != []
                "
              >
                <Chip
                  :key="position_idex"
                  class="p-mr-1"
                  v-for="(position, position_idex) in store.state.employer
                    .positions"
                  :label="position.name"
                />
              </span>
            </span>
          </span> -->
        </div>
        <!-- <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplySalary").SalaryDate }}
        </div>
        <div class="p-col-12">
          <Calendar
            id="range"
            v-model="data.add_salary_raise.effective_date"
            :minDate="data.minDate"
            :manualInput="false"
            :showIcon="true"
            :placeholder="
              i18n.$t('ApplySalary').ApplySalaryTable.EffectiveDate.Show
            "
            :showButtonBar="true"
          />
        </div> -->
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplySalary").SalaryTotal }}
        </div>
        <div v-if="data.add_salary_raise.length == 0" class="p-col-12">
          <br />
        </div>
        <div
          :key="index"
          v-for="(item, index) in data.add_salary_raise"
          class="p-col-12"
        >
          <Chip :label="item.apply_employer_id + ':' + item.employer_name" />
          <span class="p-ml-1"> {{ i18n.$t("ApplySalary").SalaryDate }} </span>:
          <Calendar
            id="range"
            v-model="item.effective_date"
            :minDate="data.minDate"
            :manualInput="false"
            :showIcon="true"
            :placeholder="
              i18n.$t('ApplySalary').ApplySalaryTable.EffectiveDate.Show
            "
            :showButtonBar="true"
          />
          <span class="p-ml-1"> {{ i18n.$t("ApplySalary").SalaryTotal }} </span
          >:
          <InputNumber
            class="p-inputtext-sm"
            inputId="minmax-buttons"
            v-model="item.add_salary"
            mode="decimal"
            :min="0"
            :minFractionDigits="2"
          />
          <span class="p-ml-1"> {{ i18n.$t("ApplySalary").SalaryNow }} </span>:
          {{ item.before_salary }}
          <span class="p-ml-1"> {{ i18n.$t("ApplySalary").SalaryAfter }} </span
          >:
          {{ item.new_salary }}
        </div>
        <!-- <div class="p-col-12">
          <span class="p-ml-1"> {{ i18n.$t("ApplySalary").SalaryDate }} </span>:
          <Calendar
            id="range"
            v-model="data.add_salary_raise.effective_date"
            :minDate="data.minDate"
            :manualInput="false"
            :showIcon="true"
            :placeholder="
              i18n.$t('ApplySalary').ApplySalaryTable.EffectiveDate.Show
            "
            :showButtonBar="true"
          />
          <span class="p-ml-1"> {{ i18n.$t("ApplySalary").SalaryTotal }} </span
          >:
          <InputNumber
            class="p-inputtext-sm"
            inputId="minmax-buttons"
            v-model="data.add_salary"
            mode="decimal"
            :min="0"
            :minFractionDigits="2"
          />
          <span class="p-ml-1"> {{ i18n.$t("ApplySalary").SalaryNow }} </span>:
          {{ data.add_salary_raise.before_salary }}
          <span class="p-ml-1"> {{ i18n.$t("ApplySalary").SalaryAfter }} </span
          >:
          {{ data.add_salary_raise.new_salary }}
        </div> -->
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplySalary").SalaryItem }}
        </div>
        <div class="p-col-12">
          <div class="field-checkbox">
            <Checkbox
              name="city"
              value="年度調薪(Annual Increment)"
              class="p-mr-1"
              v-model="data.add_salary_raise.type"
            />
            <label for="city3">年度調薪(Annual Increment)</label>
            <Checkbox
              name="city"
              value="轉調(Change Position)"
              class="p-ml-2 p-mr-1"
              v-model="data.add_salary_raise.type"
            />
            <label for="city3">轉調(Change Position)</label>
          </div>
          <div class="field-checkbox">
            <Checkbox
              name="city"
              value="試用期滿(Finish Probation)"
              class="p-mr-1"
              v-model="data.add_salary_raise.type"
            />
            <label for="city3">試用期滿(Finish Probation)</label>
            <Checkbox
              name="city"
              value="兼任(Add Workloads)"
              class="p-ml-2 p-mr-1"
              v-model="data.add_salary_raise.type"
            />
            <label for="city3">兼任(Add Workloads)</label>
          </div>
          <div class="field-checkbox">
            <Checkbox
              name="city"
              value="晉升(Promote)"
              class="p-mr-1"
              v-model="data.add_salary_raise.type"
            />
            <label style="margin-right: 39px" for="city3">晉升(Promote)</label>
            <Checkbox
              name="city"
              value="其他(Other)"
              class="p-ml-5 p-mr-1"
              v-model="data.add_salary_raise.type"
            />
            <label for="city3">其他(Other)</label>
          </div>
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplySalary").SalaryReason }}
        </div>
        <div class="p-col-12">
          <Textarea
            id="description"
            v-model="data.reason"
            required="true"
            rows="4"
            cols="43"
          />
          <!-- <Textarea
            id="description"
            v-model="data.add_salary_raise.reason"
            required="true"
            rows="4"
            cols="43"
          /> -->
        </div>
        <div class="p-col-12">
          <Button
            :disabled="data.print"
            :label="i18n.$t('ApplySalary').Send"
            icon="pi pi-send"
            class="p-button-info p-mr-2"
            @click="saveApplySalary"
          />
          <Button
            :disabled="data.add_salary_raise.length == 0"
            :label="i18n.$t('ApplySalary').Print"
            icon="pi pi-print"
            class="p-button-success p-mr-2"
            @click="printApplySalary(printDom)"
          />
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplySalary").History }}
        </div>
        <div class="p-col-12">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.salary_raises.data"
            :loading="data.salary_raiseLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 335px)"
            style="min-width: 375px; margin-top: -5px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.salary_raiseFilters"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.date_search"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('SpotExchangeRate').SpotExchangeRateTable
                        .RateOfMonth.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </div>
            </template>
            <Column
              class="p-col-1"
              :header="i18n.$t('ApplySalary').ApplySalaryTable.ApplyDate.Show"
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplySalary').ApplySalaryTable.ApplyEmployerName.Show
              "
              :field="
                i18n.$t('ApplySalary').ApplySalaryTable.ApplyEmployerName.Field
              "
            >
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplySalary').ApplySalaryTable.BeforeSalary.Show
              "
              :field="
                i18n.$t('ApplySalary').ApplySalaryTable.BeforeSalary.Field
              "
            >
            </Column>
            <Column
              class="p-col-2"
              :header="i18n.$t('ApplySalary').ApplySalaryTable.NewSalary.Show"
              :field="i18n.$t('ApplySalary').ApplySalaryTable.NewSalary.Field"
            >
            </Column>
            <Column
              class="p-col-2"
              :header="i18n.$t('ApplySalary').ApplySalaryTable.Reason.Show"
              :field="i18n.$t('ApplySalary').ApplySalaryTable.Reason.Field"
            >
            </Column>
            <Column
              class="p-col-1"
              :header="
                i18n.$t('ApplySalary').ApplySalaryTable.EffectiveDate.Show
              "
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.effective_date, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="i18n.$t('ApplySalary').ApplySalaryTable.Status.Show"
            >
              <template #body="slotProps">
                {{
                  slotProps.data.status == 1
                    ? i18n.$t("ApplySalary").Reject
                    : slotProps.data.status == 2
                    ? i18n.$t("ApplySalary").Pass
                    : slotProps.data.status == 3
                    ? i18n.$t("ApplySalary").Doing
                    : i18n.$t("ApplySalary").UnKnow
                }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplySalary').ApplySalaryTable.AuditEmployerName.Show
              "
              :field="
                i18n.$t('ApplySalary').ApplySalaryTable.AuditEmployerName.Field
              "
            >
            </Column>
            <!-- <Column class="p-col-2">
              <template #body="slotProps">
                <Button
                  :label="i18n.$t('ApplyBorrow').Print"
                  icon="pi pi-print"
                  class="p-button-success p-mr-2"
                  @click="printApplySalary(slotProps.data, printDom)"
                />
              </template>
            </Column> -->
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <Sidebar
    v-model:visible="data.chooseDialog"
    style="width: 70%; min-width: 375px"
    position="right"
  >
    <div style="margin-top: -80px">
      <Employer />
    </div>
  </Sidebar>
  <Dialog v-model:visible="data.printDialog">
    <div ref="printDom">
      <!-- <PrintSalary /> -->
      <PrintSalaryBatch :tmp_salary="store.state.salary_batch" />
    </div>
  </Dialog>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
