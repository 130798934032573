<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  onUpdated,
  toRefs,
  computed,
  watch,
} from "vue";
import { dateFormate } from "../tool/formate";
import { useStore } from "vuex";
const store = useStore();
// const i18n = useI18n();
// const toast = useToast();
const data = reactive({
  department: [],
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
</script>

<template>
  <div
    style="
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    "
  >
    <div
      style="
        width: 100%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div style="width: 25%; text-align: left">
        <img style="height: 80px; width: 80px" :src="store.state.logo" />
      </div>
      <div style="width: 75%; text-align: center; margin-left: -10%">
        <h2>
          {{
            store.state.payment == null ? "" : store.state.payment.company_name
          }}
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;零用金預支 / PETTY CASH
          VOUCHER
        </h2>
      </div>
    </div>
    <div
      style="
        border-bottom: 1px solid black;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div style="width: 100%; text-align: right">
        系統單號：
        {{ store.state.payment == null ? "" : store.state.payment.id }}
      </div>
      <div style="width: 100%; text-align: right">
        &nbsp; 申請日期(Date): &nbsp;
        {{
          store.state.payment == null
            ? ""
            : dateFormate(store.state.payment.created_at, "YYYY-MM-DD")
        }}
      </div>
    </div>
    <div style="border: 1px solid black; width: 100%">
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          廠別：<br />
          (Factory)
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          <div v-if="store.state.payment != null">
            <span
              :key="index"
              v-for="(name, index) in store.state.payment.department"
            >
              {{ name }}
            </span>
          </div>
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          預付金額：<br />
          (Prepayment Amount)
        </div>
        <div
          style="
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        ></div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          申請人：<br />
          (Applicant))
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment != null
              ? store.state.payment.apply_employer_name
              : ""
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          報銷金額：<br />
          (Actual Amount)
        </div>
        <div
          style="
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment != null ? store.state.payment.total_dollar : ""
          }}
        </div>
      </div>

      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          text-align: left;
        "
      >
        <h4 style="width: 100%">申請事項(Subject)：</h4>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          &nbsp; 申請品項 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          &nbsp; Amount &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          &nbsp; VAT &nbsp;
        </div>
        <div style="width: 24%; text-align: center">&nbsp; Total &nbsp;</div>
      </div>
      <div
        :key="index"
        v-for="(content, index) in store.state.payment_content"
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.payment_item_chinese_name }}({{
            content.payment_item_english_name
          }})
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.total_dollar }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            content.payment_item_taxed == 1 ? content.total_dollar * 0.15 : 0
          }}
        </div>
        <div
          style="
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            content.payment_item_taxed == 1
              ? parseFloat(content.total_dollar) +
                parseFloat(content.total_dollar) * 0.15
              : content.total_dollar
          }}
        </div>
      </div>
    </div>
    <div
      style="
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
        "
      >
        授權(authorization)：
      </div>
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
          border-bottom: 1px solid black;
        "
      ></div>
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
        "
      >
        主管復核(review)：
      </div>
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
          border-bottom: 1px solid black;
        "
      ></div>
    </div>

    <div
      style="
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
        "
      ></div>
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
        "
      ></div>
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
        "
      >
        付款人(payer)：
      </div>
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
          border-bottom: 1px solid black;
        "
      ></div>
    </div>
    <div
      style="
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
        "
      ></div>
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
        "
      ></div>
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
        "
      >
        領款人(payee)：
      </div>
      <div
        style="
          width: 24%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
          border-bottom: 1px solid black;
        "
      ></div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
