<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import SelectButton from "primevue/selectbutton";
import * as XLSX from "xlsx/xlsx.mjs";
import Dialog from "primevue/dialog";
import User from "../../api/salary/auth/user";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import Toast from "primevue/toast";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Organisation from "../../api/salary/admin/organisation";
import Shift from "../../api/salary/admin/shift";
import ManagerShift from "../../api/salary/manager/shift";
import Employer from "../../api/salary/admin/employer";
import MakeUpAttendance from "../../api/salary/admin/make_up_attendance";
import ManagerMakeUpAttendance from "../../api/salary/manager/make_up_attendance";
import InputText from "primevue/inputText";
import { QrcodeStream } from "vue-qrcode-reader";
import { useToast } from "primevue/usetoast";
import { dateFormate } from "../../tool/formate";
import { useI18n } from "../../i18nPlugin";
import { useStore } from "vuex";

const toast = useToast();
const i18n = useI18n();
const store = useStore();

let userApi = new User();
let make_up_attendanceAPI = new MakeUpAttendance();
let manager_make_up_attendanceAPI = new ManagerMakeUpAttendance();
let organisationAPI = new Organisation();
let shiftAPI = new Shift();
let manager_shiftAPI = new ManagerShift();
let employerAPI = new Employer();

const data = reactive({
  add_type: "ALL DAY",
  EditStatusDialog: false,
  selectEmployerList: [],
  selectedMakeUpAttendance: [],
  time_diff: 0,
  shift: null,
  OrganisationSearch: null,
  selectShiftList: [],
  selectOrganisationList: [],
  today: new Date(),
  make_up_attendance: null,
  make_up_attendanceLoading: false,
  make_up_attendances: { data: null },
  totalRecords: 0,
  page: 1,
  page_num: 10,
  make_up_attendanceFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    organisation_id: { value: "", matchMode: "contains" },
    shift_id: { value: "", matchMode: "contains" },
    punch_date_begin: { value: "", matchMode: "contains" },
    punch_date_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  getShiftSelectList();
  if (store.state.user.role == 1) {
    getOrganisationSelectList();
    getEmployerSelectList();
  }
  getMakeUpAttendanceList();
  data.time_diff = 8 * 60 + new Date().getTimezoneOffset();
});

function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getEmployerSelectList() {
  employerAPI.getEmployerSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectEmployerList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getShiftSelectList() {
  if (store.state.user.role == 1) {
    shiftAPI.getShiftSelectList("").then((res) => {
      if (res.data.error_code == 0) {
        data.selectShiftList = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  } else if (store.state.user.role == 2) {
    manager_shiftAPI.getShiftSelectList("").then((res) => {
      if (res.data.error_code == 0) {
        data.selectShiftList = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  }
}

function getMakeUpAttendanceList() {
  if (store.state.user.role == 1) {
    make_up_attendanceAPI
      .getMakeUpAttendanceList(
        data.make_up_attendanceFilters.id.value,
        data.make_up_attendanceFilters.punch_date_begin.value,
        data.make_up_attendanceFilters.punch_date_end.value,
        data.make_up_attendanceFilters.shift_id.value,
        data.make_up_attendanceFilters.name.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.totalRecords = res.data.data.total_count;
          data.make_up_attendances.data = res.data.data.datas;
          data.make_up_attendanceLoading = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
          data.make_up_attendanceLoading = false;
        }
      });
  } else if (store.state.user.role == 2) {
    manager_make_up_attendanceAPI
      .getMakeUpAttendanceList(
        data.make_up_attendanceFilters.id.value,
        data.make_up_attendanceFilters.punch_date_begin.value,
        data.make_up_attendanceFilters.punch_date_end.value,
        data.make_up_attendanceFilters.shift_id.value,
        data.make_up_attendanceFilters.name.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.totalRecords = res.data.data.total_count;
          data.make_up_attendances.data = res.data.data.datas;
          data.make_up_attendanceLoading = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
          data.make_up_attendanceLoading = false;
        }
      });
  }
}

function onPage(event) {
  data.page = event.page + 1;
}

watch(
  () => data.page,
  () => {
    data.make_up_attendanceLoading = true;
    getMakeUpAttendanceList();
  }
);

watch(
  () => data.make_up_attendanceFilters.name.value,
  () => {
    data.make_up_attendanceLoading = true;
    getMakeUpAttendanceList();
  }
);
watch(
  () => data.make_up_attendanceFilters.id.value,
  () => {
    data.make_up_attendanceLoading = true;
    getMakeUpAttendanceList();
  }
);

watch(
  () => data.OrganisationSearch,
  () => {
    data.make_up_attendanceLoading = true;
    data.make_up_attendanceFilters.organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    getMakeUpAttendanceList();
  }
);

watch(
  () => data.shift,
  () => {
    data.make_up_attendanceLoading = true;
    data.make_up_attendanceFilters.shift_id.value =
      data.shift == null ? "" : data.shift.id;
    getMakeUpAttendanceList();
  }
);

watch(
  () => data.date_search,
  async () => {
    data.make_up_attendanceLoading = true;
    if (data.date_search != null) {
      if (data.date_search[1] != null) {
        //     dateFormate(data.today, "YYYY-MM-DD") + "T00:00:00.000+08:00",
        //   dateFormate(data.today, "YYYY-MM-DD") + "T15:59:59.999+08:00",
        data.make_up_attendanceFilters.punch_date_begin.value =
          dateFormate(data.date_search[0], "YYYY-MM-DD") +
          "T00:00:00.000+08:00";
        data.make_up_attendanceFilters.punch_date_end.value =
          dateFormate(data.date_search[1], "YYYY-MM-DD") +
          "T15:59:59.999+08:00";
      } else {
        data.make_up_attendanceFilters.punch_date_begin.value = "";
        data.make_up_attendanceFilters.punch_date_end.value = "";
      }
    } else {
      data.make_up_attendanceFilters.punch_date_begin.value = "";
      data.make_up_attendanceFilters.punch_date_end.value = "";
    }
    getMakeUpAttendanceList();
  }
);

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

function confirmDeleteSelected() {
  CheckUser();
  data.deleteSelectedDialog = true;
}

function deleteSelectedMakeUpAttendance() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedMakeUpAttendance.length; i++) {
    selectDelete.push(data.selectedMakeUpAttendance[i].id);
  }
  make_up_attendanceAPI.deleteMakeUpAttendance(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Shift").DeleteSuccess,
        life: 3000,
      });

      data.make_up_attendanceLoading = true;
      data.selectedMakeUpAttendance = [];
      getMakeUpAttendanceList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function addMakeUpAttendance() {
  CheckUser();
  data.AddShiftSearch = null;
  data.AddEmployerSearch = null;
  data.addDialog = true;
  data.add_make_up_attendance = {
    shift_id: "",
    punch_date: "",
    punch_time: "",
    after_punch_time: "",
    time_diff: data.time_diff,
    apply_employer_id: "",
  };
}

function saveAddMakeUpAttendance() {
  // if (
  //   data.add_make_up_attendance.punch_date != "" &&
  //   data.add_make_up_attendance.punch_date != null
  // ) {
  //   // 設置
  //   data.add_make_up_attendance.punch_time.setDate(
  //     data.add_make_up_attendance.punch_date.getDate()
  //   ); // 設置為中午12點
  //   data.add_make_up_attendance.after_punch_time.setDate(
  //     data.add_make_up_attendance.punch_date.getDate()
  //   ); // 設置為中午12點
  //   data.add_make_up_attendance.punch_time.setMonth(
  //     data.add_make_up_attendance.punch_date.getMonth()
  //   ); // 設置為30分鐘
  //   data.add_make_up_attendance.after_punch_time.setMonth(
  //     data.add_make_up_attendance.punch_date.getMonth()
  //   ); // 設置為30分鐘
  //   data.add_make_up_attendance.punch_time.setFullYear(
  //     data.add_make_up_attendance.punch_date.getFullYear()
  //   ); // 設置為45秒
  //   data.add_make_up_attendance.after_punch_time.setFullYear(
  //     data.add_make_up_attendance.punch_date.getFullYear()
  //   ); // 設置為30分鐘
  // }

  make_up_attendanceAPI
    .addMakeUpAttendance(
      data.add_make_up_attendance.shift_id,
      data.add_make_up_attendance.punch_time != "" &&
        data.add_make_up_attendance.punch_time != null
        ? dateFormate(
            data.add_make_up_attendance.punch_time,
            "normal YYYY-MM-DD"
          ) + "T00:00:00.000+08:00"
        : dateFormate(
            data.add_make_up_attendance.after_punch_time,
            "normal YYYY-MM-DD"
          ) + "T00:00:00.000+08:00",

      data.add_type == "ALL DAY" || data.add_type == "Clock In"
        ? data.add_make_up_attendance.punch_time
        : "",
      data.add_type == "ALL DAY" || data.add_type == "Clock Out"
        ? data.add_make_up_attendance.after_punch_time
        : "",
      data.add_make_up_attendance.time_diff,
      data.add_make_up_attendance.apply_employer_id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("MakeUpAttendance").AddSuccess,
          life: 3000,
        });

        data.make_up_attendanceLoading = true;
        getMakeUpAttendanceList();

        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

watch(
  () => data.AddShiftSearch,
  () => {
    data.add_make_up_attendance.shift_id =
      data.AddShiftSearch == null ? "" : data.AddShiftSearch.id;
  }
);

watch(
  () => data.AddEmployerSearch,
  () => {
    data.add_make_up_attendance.apply_employer_id =
      data.AddEmployerSearch == null ? "" : data.AddEmployerSearch.id;
  }
);
function SetMakeUpAttendance(id, status) {
  data.id = id;
  data.status = status;
  data.EditStatusDialog = true;
}
function SaveSetMakeUpAttendance() {
  make_up_attendanceAPI
    .editMakeUpAttendanceStatus(data.id, data.status)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("MakeUpAttendance").EditSuccess,
          life: 3000,
        });

        data.make_up_attendanceLoading = true;
        getMakeUpAttendanceList();

        data.EditStatusDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
async function onDecodeScan(qrCode) {
  try {
    let qrcode = await JSON.parse(qrCode[0].rawValue);
    data.AddEmployerSearch = data.selectEmployerList.filter(
      (item) => item.id === qrcode.id
    )[0];
  } catch (d) {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail: "Wrong Template",
      life: 3000,
    });
  }
}
function drawOutline(decodeData, context) {
  var points = [];
  for (let i = 0; i < decodeData[0].cornerPoints.length; i++) {
    switch (i) {
      case 0:
        points[0] = decodeData[0].cornerPoints[i];
        break;
      case 1:
        points[1] = decodeData[0].cornerPoints[i];
        break;
      case 2:
        points[2] = decodeData[0].cornerPoints[i];
        break;
      case 3:
        points[3] = decodeData[0].cornerPoints[i];
        break;
      default:
        break;
    }
  }

  context.font = "13px Arial";
  context.fillText("", points[0].x, points[0].y);
  context.lineWidth = 5;
  context.strokeStyle = "green";
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);

  for (const { x, y } of points) {
    context.lineTo(x, y);
  }
  context.lineTo(points[0].x, points[0].y);
  context.closePath();
  context.stroke();
}
</script>

<template>
  <Toast />
  <div>
    <div style="margin-top: 85px">
      <div class="p-grid">
        <div class="p-col-12" style="height: calc(100vh - 84px)">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.make_up_attendances.data"
            :loading="data.make_up_attendanceLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 120px)"
            style="min-width: 375px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.make_up_attendanceFilters"
            v-model:selection="data.selectedMakeUpAttendance"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <Accordion class="accordion-custom p-col-12" :activeIndex="0">
                  <AccordionTab>
                    <template #header>
                      <i class="pi pi-search p-mr-1"></i>
                      <span>{{ i18n.$t("Shift").ShiftTable.Search }}</span>
                    </template>
                    <p style="text-align: left">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <InputText
                          style="width: 150px"
                          v-model="
                            data.make_up_attendanceFilters[
                              i18n.$t('Employer').EmployerTable.ID.Field
                            ].value
                          "
                          :placeholder="
                            i18n.$t('Employer').EmployerTable.ID.Show
                          "
                        />
                      </span>
                      <span class="p-input-icon-left p-ml-1">
                        <i class="pi pi-search" />
                        <InputText
                          style="width: 150px"
                          v-model="
                            data.make_up_attendanceFilters[
                              i18n.$t('Employer').EmployerTable.Name.Field
                            ].value
                          "
                          :placeholder="
                            i18n.$t('Employer').EmployerTable.Name.Show
                          "
                        />
                      </span>
                      <Dropdown
                        class="p-ml-1"
                        v-model="data.shift"
                        :options="data.selectShiftList"
                        optionLabel="name"
                        :filter="true"
                        :placeholder="i18n.$t('Shift').ShiftTable.Name.Show"
                        :showClear="true"
                      >
                        <template #value="slotProps">
                          <div
                            class="country-item country-item-value"
                            v-if="slotProps.value"
                          >
                            <div>{{ slotProps.value.name }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="country-item">
                            <div>{{ slotProps.option.name }}</div>
                          </div>
                        </template>
                      </Dropdown>
                      <Dropdown
                        class="p-ml-1 p-d-none"
                        v-model="data.OrganisationSearch"
                        :options="data.selectOrganisationList"
                        optionLabel="name"
                        :filter="true"
                        :placeholder="
                          i18n.$t('Employer').EmployerTable.Organisation.Show
                        "
                        :showClear="true"
                      >
                        <template #value="slotProps">
                          <div
                            class="country-item country-item-value"
                            v-if="slotProps.value"
                          >
                            <div>{{ slotProps.value.name }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="country-item">
                            <div>{{ slotProps.option.name }}</div>
                          </div>
                        </template>
                      </Dropdown>
                    </p>
                    <p class="p-mt-1" style="text-align: left">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <Calendar
                          id="range"
                          v-model="data.date_search"
                          class="p-mr-1"
                          selectionMode="range"
                          dateFormat="yy/mm/dd"
                          :manualInput="false"
                          :showIcon="true"
                          :placeholder="
                            i18n.$t('MakeUpAttendance').MakeUpAttendanceTable
                              .PunchDate.Show
                          "
                          :showButtonBar="true"
                        />
                      </span>
                    </p>
                  </AccordionTab>
                </Accordion>

                <div
                  v-if="store.state.user.role == 1"
                  class="p-col-12 p-d-flex p-jc-end"
                >
                  <!-- <Button
                    :label="i18n.$t('make_up_attendance').ImportExcelSalary"
                    icon="pi pi-plus"
                    class="p-button-info p-mr-2"
                    @click="SaveExcel"
                  /> -->
                  <Button
                    :label="i18n.$t('Shift').ShiftTable.Add"
                    icon="pi pi-plus"
                    class="p-button-success p-mr-2"
                    @click="addMakeUpAttendance"
                  />
                  <!-- <Button
                    :label="i18n.$t('Shift').ShiftTable.Delete"
                    icon="pi pi-trash"
                    class="p-button-danger"
                    @click="confirmDeleteSelected"
                    :disabled="
                      !data.selectedMakeUpAttendance ||
                      !data.selectedMakeUpAttendance.length
                    "
                  /> -->
                </div>
              </div>
            </template>
            <!--<Column class="p-col-1" selectionMode="multiple"></Column>-->
            <Column
              class="p-col-1"
              :field="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.ID.Field
              "
              :header="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.ID.Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable
                  .ApplyEmployerName.Field
              "
              :header="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable
                  .ApplyEmployerName.Show
              "
            >
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.Shift.Field
              "
              :header="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.Shift.Show
              "
            >
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.PunchDate
                  .Field
              "
              :header="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.PunchDate.Show
              "
            >
              <template #body="slotProps"
                >{{
                  // dateFormate(slotProps.data.punch_date, "YYYY-MM-DD")
                  slotProps.data.punch_date.split("T")[0]
                }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.PunchTime
                  .Field
              "
              :header="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.PunchTime.Show
              "
            >
              <template #body="slotProps"
                >{{ dateFormate(slotProps.data.punch_time, "normal hh:mm") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.AfterPunchTime
                  .Field
              "
              :header="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.AfterPunchTime
                  .Show
              "
            >
              <template #body="slotProps"
                >{{
                  dateFormate(slotProps.data.after_punch_time, "normal hh:mm")
                }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.Status.Field
              "
              :header="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable.Status.Show
              "
            >
              <template #body="slotProps"
                >{{
                  slotProps.data.status == 1
                    ? i18n.$t("MakeUpAttendance").Status.RejectAuditStatus
                    : slotProps.data.status == 2
                    ? i18n.$t("MakeUpAttendance").Status.PassAuditStatus
                    : slotProps.data.status == 3
                    ? i18n.$t("MakeUpAttendance").Status.DoingAuditStatus
                    : i18n.$t("MakeUpAttendance").Status.Unknow
                }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable
                  .AuditEmployerName.Field
              "
              :header="
                i18n.$t('MakeUpAttendance').MakeUpAttendanceTable
                  .AuditEmployerName.Show
              "
            >
            </Column>
            <Column
              v-if="
                !(2030 <= store.state.user.id && store.state.user.id <= 2034)
              "
            >
              <template #body="slotProps">
                <Button
                  v-if="slotProps.data.status == 3"
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success p-mr-2"
                  @click="SetMakeUpAttendance(slotProps.data.id, 2)"
                />
                <Button
                  v-if="slotProps.data.status == 3"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
                  @click="SetMakeUpAttendance(slotProps.data.id, 1)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增視窗 -->
  <Dialog
    v-model:visible="data.addDialog"
    :style="{ width: '450px', minWidth: '350px' }"
    :header="i18n.$t('MakeUpAttendance').AddMakeUpAttendance.Title"
    :modal="true"
    class="p-fluid"
  >
    <SelectButton
      v-model="data.add_type"
      :options="['ALL DAY', 'Clock In', 'Clock Out']"
      aria-labelledby="basic"
      class="p-mb-1"
    />
    <div class="p-field">
      <qrcode-stream
        style="width: 100%; height: 180px"
        class="p-mt-1"
        @init="onInit"
        @detect="onDecodeScan"
        :track="drawOutline"
        v-if="data.AddEmployerSearch == '' || data.AddEmployerSearch == null"
      >
      </qrcode-stream>
      <label for="name">{{
        i18n.$t("MakeUpAttendance").MakeUpAttendanceTable.ApplyEmployerName.Show
      }}</label>
      <Dropdown
        v-model="data.AddEmployerSearch"
        :options="data.selectEmployerList"
        optionLabel="name"
        :filter="true"
        :placeholder="i18n.$t('Employer').EmployerTable.Name.Show"
        :showClear="true"
      >
        <template #value="slotProps">
          <div class="country-item country-item-value" v-if="slotProps.value">
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="p-field">
      <label for="name">{{
        i18n.$t("MakeUpAttendance").MakeUpAttendanceTable.Shift.Show
      }}</label>
      <Dropdown
        class="p-ml-1"
        v-model="data.AddShiftSearch"
        :options="data.selectShiftList"
        optionLabel="name"
        :filter="true"
        :placeholder="i18n.$t('Shift').ShiftTable.Name.Show"
        :showClear="true"
      >
        <template #value="slotProps">
          <div class="country-item country-item-value" v-if="slotProps.value">
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Dropdown>
    </div>
    <!-- <div class="p-field">
      <label for="name">{{
        i18n.$t("MakeUpAttendance").MakeUpAttendanceTable.PunchDate.Show
      }}</label>
      <Calendar
        id="range"
        v-model="data.add_make_up_attendance.punch_date"
        class="p-mr-1"
        dateFormat="yy/mm/dd"
        :manualInput="false"
        :showIcon="true"
        :placeholder="
          i18n.$t('AttendanceRecords').AttendanceRecordsTable.PunchDate.Show
        "
        :showButtonBar="true"
      />
    </div> -->
    <div
      v-if="data.add_type == 'ALL DAY' || data.add_type == 'Clock In'"
      class="p-field"
    >
      <label for="name">{{
        i18n.$t("MakeUpAttendance").MakeUpAttendanceTable.PunchTime.Show
      }}</label>
      <Calendar
        inputId="time12"
        v-model="data.add_make_up_attendance.punch_time"
        :timeOnly="false"
        showTime
        hourFormat="24"
        :stepMinute="30"
      />
    </div>
    <div
      v-if="data.add_type == 'ALL DAY' || data.add_type == 'Clock Out'"
      class="p-field"
    >
      <label for="name">{{
        i18n.$t("MakeUpAttendance").MakeUpAttendanceTable.AfterPunchTime.Show
      }}</label>
      <Calendar
        inputId="time12"
        v-model="data.add_make_up_attendance.after_punch_time"
        :timeOnly="false"
        showTime
        hourFormat="24"
        :stepMinute="30"
      />
    </div>

    <template #footer>
      <Button
        :label="i18n.$t('Shift').Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.addDialog = false"
      />
      <Button
        :label="i18n.$t('Shift').Save"
        icon="pi pi-check"
        class="p-button-text"
        @click="saveAddMakeUpAttendance"
      />
    </template>
  </Dialog>
  <!-- 詳細視窗 -->
  <Dialog
    v-model:visible="data.editDialog"
    :style="{ width: '450px' }"
    :header="i18n.$t('MakeUpAttendance').EditMakeUpAttendance.Title"
    :modal="true"
    class="p-fluid"
  >
    <template #footer>
      <Button
        :label="i18n.$t('Shift').Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.editDialog = false"
      />
      <Button
        :label="i18n.$t('Shift').Save"
        icon="pi pi-check"
        class="p-button-text"
        @click="saveEditMakeUpAttendance"
      />
    </template>
  </Dialog>
  <!-- 選擇刪除視窗 -->
  <Dialog
    v-model:visible="data.deleteSelectedDialog"
    :style="{ width: '450px' }"
    :header="i18n.$t('Shift').Confirm.Title"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>{{ i18n.$t("Shift").Confirm.SelectContent }}</span>
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('Shift').Confirm.No"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.deleteSelectedDialog = false"
      />
      <Button
        :label="i18n.$t('Shift').Confirm.Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteSelectedMakeUpAttendance"
      />
    </template>
  </Dialog>

  <!-- 審核視窗 -->
  <Dialog
    v-model:visible="data.EditStatusDialog"
    :style="{ width: '450px' }"
    :header="i18n.$t('MakeUpAttendance').Confirm.Title"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span
        >Apply ID:{{ data.id }}
        {{
          data.status == 1
            ? i18n.$t("MakeUpAttendance").Status.RejectAuditStatus
            : data.status == 2
            ? i18n.$t("MakeUpAttendance").Status.PassAuditStatus
            : i18n.$t("MakeUpAttendance").Status.Unknow
        }}</span
      >
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('Shift').Confirm.No"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.EditStatusDialog = false"
      />
      <Button
        :label="i18n.$t('Shift').Confirm.Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="SaveSetMakeUpAttendance"
      />
    </template>
  </Dialog>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
