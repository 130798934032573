<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  ref,
  watch,
} from "vue";
import router from "../../router";
import Toast from "primevue/toast";
import InputNumber from "primevue/inputNumber";
import InputText from "primevue/inputText";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import Employer from "../../api/salary/self/employer";
import ManagerGiveUpHoliday from "../../api/salary/manager/give_up_holiday";
import AdminGiveUpHoliday from "../../api/salary/admin/give_up_holiday";
import { dateFormate } from "../../tool/formate";
import Organisation from "../../api/salary/admin/organisation";
import AdminCurrency from "../../api/salary/admin/currency";
import ManagerCurrency from "../../api/salary/manager/currency";
import ManagerEmployer from "../../api/salary/manager/employer";
import AdminEmployer from "../../api/salary/admin/employer";
import ManagerSalaryItem from "../../api/salary/manager/salary_item";
import AdminSalaryItem from "../../api/salary/admin/salary_item";
import { printHTML } from "../../tool/print";
import PrintGiveUpHoliday from "../../components/PrintGiveUpHoliday.vue";
import africa_logo from "../../assets/africa_logo.jpg";
const PrintGiveUp = ref(null);

let manager_salary_itemAPI = new ManagerSalaryItem();
let admin_salary_itemAPI = new AdminSalaryItem();
let manager_employerAPI = new ManagerEmployer();
let admin_employerAPI = new AdminEmployer();
let admin_currencyAPI = new AdminCurrency();
let manager_currencyAPI = new ManagerCurrency();

const i18n = useI18n();
const toast = useToast();
const store = useStore();
const dt = ref();
let userApi = new User();
let employerApi = new Employer();

// let admin_spot_exchange_rateAPI = new AdminSpotExchangeRate();
// let manager_spot_exchange_rateAPI = new ManagerSpotExchangeRate();

let manager_give_up_holidayAPI = new ManagerGiveUpHoliday();
let admin_give_up_holidayAPI = new AdminGiveUpHoliday();
let organisationAPI = new Organisation();

const data = reactive({
  CurrencySearch: "",
  selectCurrencyList: [],
  add_salary: {
    salary_item_id: 0,
    recipient_employer_id: 0,
    description: "",
    dollar: 0,
    currency_id: 0,
    pay_date: "",
    settlement_of_month: "",
  },
  SalaryItemSearch: "",
  selectSalaryItemList: [],
  addDialog: false,
  OrganisationSearch: "",
  selectOrganisationList: [],
  StatusSearch: "",
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("ApplyGiveUpHoliday").Reject,
    },
    {
      id: 2,
      name: i18n.$t("ApplyGiveUpHoliday").Pass,
    },
    {
      id: 3,
      name: i18n.$t("ApplyGiveUpHoliday").Doing,
    },
  ],
  give_up_holidays: { data: null },
  give_up_holiday: null,
  give_up_holidayLoading: false,
  page: 1,
  page_num: 10,
  totalRecords: 0,
  exchange_rate: 0,
  employer: {
    organisation_name: "",
    name: "",
    english_name: "",
    position_name: "",
  },
  audit_give_up_holidayFilters: {
    apply_employer_id: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    apply_employer_name: { value: "", matchMode: "contains" },
    created_at_begin: { value: "", matchMode: "contains" },
    created_at_end: { value: "", matchMode: "contains" },
    organisation_id: { value: "", matchMode: "contains" },
  },
  excel: { data: null },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  CheckUser();
  getEmployerInfo();
  getGiveUpHolidayList();
  getSalaryItemSelectList();
  getCurrencySelectList();
  if (store.state.user.role == 1) {
    getOrganisationSelectList();
  }
});
function getSalaryItemSelectList() {
  switch (store.state.user.role) {
    case 1:
      admin_salary_itemAPI.getSalaryItemSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectSalaryItemList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_salary_itemAPI.getSalaryItemSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectSalaryItemList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}
function Excel() {
  switch (store.state.user.role) {
    case 1:
      admin_give_up_holidayAPI
        .getGiveUpHolidayList(
          data.audit_give_up_holidayFilters.created_at_begin.value,
          data.audit_give_up_holidayFilters.created_at_end.value,
          data.audit_give_up_holidayFilters.apply_employer_id.value,
          data.audit_give_up_holidayFilters.apply_employer_name.value,
          data.audit_give_up_holidayFilters.status.value,
          data.audit_give_up_holidayFilters.organisation_id.value,
          1,
          500
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            // data.excel.data = res.data.data.datas;
            let tmp = res.data.data.datas;
            for (let i = 0; i < tmp.length; i++) {
              tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");
              tmp[i].status =
                tmp[i].status == 1
                  ? i18n.$t("ApplyGiveUpHoliday").Reject
                  : tmp[i].status == 2
                  ? i18n.$t("ApplyGiveUpHoliday").Pass
                  : tmp[i].status == 3
                  ? i18n.$t("ApplyGiveUpHoliday").Doing
                  : i18n.$t("ApplyGiveUpHoliday").UnKnow;
            }
            data.excel.data = tmp;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          dt.value.exportCSV();
        });
      break;
    case 2:
      manager_give_up_holidayAPI
        .getGiveUpHolidayList(
          data.audit_give_up_holidayFilters.created_at_begin.value,
          data.audit_give_up_holidayFilters.created_at_end.value,
          data.audit_give_up_holidayFilters.apply_employer_id.value,
          data.audit_give_up_holidayFilters.apply_employer_name.value,
          data.audit_give_up_holidayFilters.status.value,
          data.audit_give_up_holidayFilters.organisation_id.value,
          1,
          500
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            // data.excel.data = res.data.data.datas;
            let tmp = res.data.data.datas;
            for (let i = 0; i < tmp.length; i++) {
              tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");
              tmp[i].status =
                tmp[i].status == 1
                  ? i18n.$t("ApplyGiveUpHoliday").Reject
                  : tmp[i].status == 2
                  ? i18n.$t("ApplyGiveUpHoliday").Pass
                  : tmp[i].status == 3
                  ? i18n.$t("ApplyGiveUpHoliday").Doing
                  : i18n.$t("ApplyGiveUpHoliday").UnKnow;
            }
            data.excel.data = tmp;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          dt.value.exportCSV();
        });

      break;
  }
}
function getCurrencySelectList() {
  switch (store.state.user.role) {
    case 1:
      admin_currencyAPI.getCurrencySelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectCurrencyList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_currencyAPI.getCurrencySelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectCurrencyList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}
function saveAuditGiveUpHoliday() {
  switch (store.state.user.role) {
    case 1:
      admin_give_up_holidayAPI
        .editGiveUpHolidayStatusSuccess(
          data.give_up_holiday.id,
          2,
          data.give_up_holiday.reason,
          data.give_up_holiday.description,
          data.SalaryItemSearch != null && data.SalaryItemSearch != ""
            ? data.SalaryItemSearch.id
            : 0,
          // data.CurrencySearch != null && data.CurrencySearch != ""
          //   ? data.CurrencySearch.id
          //   : 0,
          1,
          String(data.add_salary.dollar),
          data.add_salary.settlement_of_month,
          data.add_salary.pay_date,
          data.add_salary.description
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("AuditGiveUpHoliday").Success,
              life: 3000,
            });
            data.addDialog = false;
            data.give_up_holidayLoading = true;
            getGiveUpHolidayList();
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
    case 2:
      manager_give_up_holidayAPI
        .editGiveUpHolidayStatusSuccess(
          data.give_up_holiday.id,
          2,
          data.give_up_holiday.reason,
          data.give_up_holiday.description,
          data.SalaryItemSearch != null && data.SalaryItemSearch != ""
            ? data.SalaryItemSearch.id
            : 0,
          // data.CurrencySearch != null && data.CurrencySearch != ""
          //   ? data.CurrencySearch.id
          //   : 0,
          1,
          String(data.add_salary.dollar),
          data.add_salary.settlement_of_month,
          data.add_salary.pay_date,
          data.add_salary.description
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("AuditGiveUpHoliday").Success,
              life: 3000,
            });
            data.addDialog = false;
            data.give_up_holidayLoading = true;
            getGiveUpHolidayList();
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
  }
}
function SetGiveUpHolidaySuccess(give_up_data) {
  data.addDialog = true;
  data.SalaryItemSearch = "";
  data.give_up_holiday = give_up_data;
  data.add_salary = {
    salary_item_id: 0,
    recipient_employer_id: 0,
    description: "",
    dollar: 0,
    currency_id: 0,
    pay_date: "",
    settlement_of_month: "",
  };
}
function SetGiveUpHoliday(id, status, reason, description) {
  switch (store.state.user.role) {
    case 1:
      admin_give_up_holidayAPI
        .editGiveUpHolidayStatus(id, status, reason, description)
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("AuditGiveUpHoliday").Success,
              life: 3000,
            });
            data.addDialog = false;
            data.give_up_holidayLoading = true;
            getGiveUpHolidayList();
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
    case 2:
      manager_give_up_holidayAPI
        .editGiveUpHolidayStatus(id, status, reason, description)
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("AuditGiveUpHoliday").Success,
              life: 3000,
            });
            data.addDialog = false;
            data.give_up_holidayLoading = true;
            getGiveUpHolidayList();
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
  }
}
function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getGiveUpHolidayList() {
  switch (store.state.user.role) {
    case 1:
      admin_give_up_holidayAPI
        .getGiveUpHolidayList(
          data.audit_give_up_holidayFilters.created_at_begin.value,
          data.audit_give_up_holidayFilters.created_at_end.value,
          data.audit_give_up_holidayFilters.apply_employer_id.value,
          data.audit_give_up_holidayFilters.apply_employer_name.value,
          data.audit_give_up_holidayFilters.status.value,
          data.audit_give_up_holidayFilters.organisation_id.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.give_up_holidays.data = res.data.data.datas;
            data.page = res.data.data.page;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            if (res.data.error_code == 8106) {
              data.totalRecords = 0;
              data.give_up_holidays.data = [];
              // data.page = 0;
            }
          }
          data.give_up_holidayLoading = false;
        });
      break;
    case 2:
      manager_give_up_holidayAPI
        .getGiveUpHolidayList(
          data.audit_give_up_holidayFilters.created_at_begin.value,
          data.audit_give_up_holidayFilters.created_at_end.value,
          data.audit_give_up_holidayFilters.apply_employer_id.value,
          data.audit_give_up_holidayFilters.apply_employer_name.value,
          data.audit_give_up_holidayFilters.status.value,
          data.audit_give_up_holidayFilters.organisation_id.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.give_up_holidays.data = res.data.data.datas;
            data.page = res.data.data.page;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            if (res.data.error_code == 8106) {
              data.totalRecords = 0;
              data.give_up_holidays.data = [];
              // data.page = 0;
            }
          }
          data.give_up_holidayLoading = false;
        });

      break;
  }
}
function getEmployerInfo() {
  employerApi.getEmployerInfo().then((res) => {
    if (res.data.error_code == 0) {
      data.employer = res.data.data;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.give_up_holidayLoading = true;
    getGiveUpHolidayList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.give_up_holidayLoading = true;
    if (data.StatusSearch != null) {
      data.audit_give_up_holidayFilters.status.value =
        data.StatusSearch == null ? "" : data.StatusSearch.id;
    } else {
      data.audit_give_up_holidayFilters.status.value = "";
    }
    getGiveUpHolidayList();
  }
);
watch(
  () => data.audit_give_up_holidayFilters.apply_employer_id.value,
  () => {
    data.give_up_holidayLoading = true;
    getGiveUpHolidayList();
  }
);
watch(
  () => data.audit_give_up_holidayFilters.apply_employer_name.value,
  () => {
    data.give_up_holidayLoading = true;
    getGiveUpHolidayList();
  }
);
watch(
  () => data.date_search,
  async () => {
    data.give_up_holidayLoading = true;
    if (data.date_search != null) {
      const GMT_8 =
        data.date_search.getHours() -
        (8 + data.date_search.getTimezoneOffset() / 60);

      let date = new Date(data.date_search.toISOString());
      await date.setHours(GMT_8);
      data.audit_give_up_holidayFilters.created_at_begin.value =
        date.toISOString();
      data.audit_give_up_holidayFilters.created_at_end.value =
        date.toISOString();
    } else {
      data.audit_give_up_holidayFilters.created_at_begin.value = "";
      data.audit_give_up_holidayFilters.created_at_end.value = "";
    }
    getGiveUpHolidayList();
  }
);
watch(
  () => data.OrganisationSearch,
  () => {
    data.give_up_holidayLoading = true;
    data.audit_give_up_holidayFilters.organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    getGiveUpHolidayList();
  }
);
async function Print(payment, print_give_up_holiday) {
  await store.commit("set_payment", {
    company_name: payment.organisation_name,
    id: payment.id,
    apply_employer_name: payment.apply_employer_name,
    created_at: payment.created_at,
    total_dollar: payment.salary_dollar,
    factory_name: [payment.apply_employer_name],
    description: payment.description,
    remark: payment.reason,
    payment_method: "",
  });
  printHTML(print_give_up_holiday);
  // }, 300);
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 90px" class="p-text-left">
      <div class="p-grid">
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("AuditGiveUpHoliday").Employer }}
        </div>
        <div class="p-col-12">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.give_up_holidays.data"
            :loading="data.give_up_holidayLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 255px)"
            style="min-width: 375px; margin-top: -5px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.give_up_holidayFilters"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.date_search"
                    class="p-mr-1"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('SpotExchangeRate').SpotExchangeRateTable
                        .RateOfMonth.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.audit_give_up_holidayFilters[
                        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable
                          .ApplyEmployerID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable
                        .ApplyEmployerID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.audit_give_up_holidayFilters[
                        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable
                          .ApplyEmployerName.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable
                        .ApplyEmployerName.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable
                        .Status.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span
                  class="p-input-icon-left p-ml-1"
                  v-if="store.state.user.role == 1"
                >
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.OrganisationSearch"
                    :options="data.selectOrganisationList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Employer').EmployerTable.Organisation.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </div>
              <div class="p-w-100 p-text-right">
                <Button
                  icon="pi pi-book"
                  class="p-button-info"
                  label="Excel"
                  @click="Excel"
                />
              </div>
            </template>
            <Column
              class="p-col-1"
              :header="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable.CreatedAt
                  .Show
              "
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-1"
              :field="
                i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable
                  .ApplyEmployerName.Field
              "
              :header="
                i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable
                  .ApplyEmployerName.Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable
                  .Description.Field
              "
              :header="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable
                  .Description.Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable.Reason
                  .Field
              "
              :header="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable.Reason
                  .Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable
                  .AuditEmployerName.Show
              "
              :field="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable
                  .AuditEmployerName.Field
              "
            >
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.Status
                  .Show
              "
            >
              <template #body="slotProps">
                <span
                  style="line-height: 37px"
                  v-if="slotProps.data.status != 3"
                >
                  {{
                    slotProps.data.status == 1
                      ? i18n.$t("ApplyGiveUpHoliday").Reject
                      : slotProps.data.status == 2
                      ? i18n.$t("ApplyGiveUpHoliday").Pass
                      : slotProps.data.status == 3
                      ? i18n.$t("ApplyGiveUpHoliday").Doing
                      : i18n.$t("ApplyGiveUpHoliday").UnKnow
                  }}
                  <Button
                    icon="pi pi-print"
                    class="p-button-rounded p-button-info p-ml-2 p-button-sm"
                    @click="Print(slotProps.data, PrintGiveUp)"
                  />
                  <!-- <Button
                    v-if="slotProps.data.status == 2"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger"
                    @click="
                      SetGiveUpHoliday(
                        slotProps.data.id,
                        1,
                        slotProps.data.reason,
                        slotProps.data.description,
                        0
                      )
                    "
                  /> -->
                </span>
                <div v-else>
                  <Button
                    icon="pi pi-check"
                    class="p-button-rounded p-button-success p-mr-2"
                    @click="SetGiveUpHolidaySuccess(slotProps.data)"
                  />
                  <Button
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger"
                    @click="
                      SetGiveUpHoliday(
                        slotProps.data.id,
                        1,
                        slotProps.data.reason,
                        slotProps.data.description
                      )
                    "
                  />
                  <Button
                    icon="pi pi-print"
                    class="p-button-rounded p-button-info p-ml-2 p-button-sm"
                    @click="Print(slotProps.data, PrintGiveUp)"
                  />
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <!-- 確認視窗 -->
  <Dialog
    v-model:visible="data.addDialog"
    :style="{ width: '450px', minWidth: '350px' }"
    :header="i18n.$t('AuditGiveUpHoliday').Title"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-field">
      <label for="name">{{
        i18n.$t("AuditGiveUpHoliday").AuditGiveUpHolidayTable.SalaryItemID.Show
      }}</label>
      <Dropdown
        v-model="data.SalaryItemSearch"
        :options="data.selectSalaryItemList"
        optionLabel="name"
        :filter="true"
        :placeholder="i18n.$t('Salary').SalaryTable.SalaryItemId.Show"
        :showClear="true"
      >
        <template #value="slotProps">
          <div class="country-item country-item-value" v-if="slotProps.value">
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="p-field p-d-none">
      <label for="name">{{
        i18n.$t("Salary").SalaryTable.CurrencyName.Show
      }}</label>
      <Dropdown
        v-model="data.CurrencySearch"
        :options="data.selectCurrencyList"
        optionLabel="name"
        :filter="true"
        :placeholder="i18n.$t('Salary').SalaryTable.CurrencyName.Show"
        :showClear="true"
      >
        <template #value="slotProps">
          <div class="country-item country-item-value" v-if="slotProps.value">
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="p-field">
      <label for="name">{{ i18n.$t("Salary").SalaryTable.Dollar.Show }}</label>
      <InputNumber
        id="name"
        v-model="data.add_salary.dollar"
        mode="currency"
        currency="USD"
        locale="en-US"
        :minFractionDigits="2"
        :maxFractionDigits="5"
        required="true"
      />
    </div>
    <div class="p-field">
      <label for="name">{{ i18n.$t("Salary").SalaryTable.PayDate.Show }}</label>
      <Calendar
        id="range"
        v-model="data.add_salary.pay_date"
        view="month"
        dateFormat="yy/mm"
        :manualInput="false"
        :showIcon="true"
        :placeholder="i18n.$t('Salary').SalaryTable.PayDate.Show"
        :showButtonBar="true"
      />
    </div>
    <div class="p-field">
      <label for="name">{{
        i18n.$t("Salary").SalaryTable.SettlementOfMonth.Show
      }}</label>
      <Calendar
        id="range"
        v-model="data.add_salary.settlement_of_month"
        dateFormat="yy/mm/dd"
        :manualInput="false"
        :showIcon="true"
        :placeholder="i18n.$t('Salary').SalaryTable.SettlementOfMonth.Show"
        :showButtonBar="true"
      />
    </div>
    <div class="p-field">
      <label for="name">{{
        i18n.$t("Salary").SalaryTable.Description.Show
      }}</label>
      <Textarea
        id="description"
        v-model="data.give_up_holiday.description"
        required="true"
        rows="4"
        cols="43"
      />
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('AuditGiveUpHoliday').Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.addDialog = false"
      />
      <Button
        :label="i18n.$t('AuditGiveUpHoliday').Save"
        icon="pi pi-check"
        class="p-button-text"
        @click="saveAuditGiveUpHoliday"
      />
    </template>
  </Dialog>
  <div class="p-d-none">
    <div ref="PrintGiveUp">
      <PrintGiveUpHoliday />
    </div>
  </div>
  <DataTable
    class="p-d-none"
    :value="data.excel.data"
    ref="dt"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :header="
        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.CreatedAt.Show
      "
      :field="
        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.CreatedAt.Field
      "
    >
    </Column>
    <Column
      class="p-col-1"
      :field="
        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.ApplyEmployerName
          .Field
      "
      :header="
        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.ApplyEmployerName
          .Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.Description.Field
      "
      :header="
        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.Description.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :field="
        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.Reason.Field
      "
      :header="
        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.Reason.Show
      "
    ></Column>
    <Column
      class="p-col-2"
      :header="
        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.Status.Show
      "
      :field="
        i18n.$t('AuditGiveUpHoliday').AuditGiveUpHolidayTable.Status.Field
      "
    >
    </Column>
  </DataTable>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
