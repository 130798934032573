import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class EmployerDetail {
  getEmployerDetailContractExpire(organisation_id, status, page, page_num) {
    return axios.get(
      `/api/admin/employer-detail/contract-expire/list`,
      {
        params: {
          organisation_id: organisation_id,
          status: status,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getEmployerDetailInfo(employer_id) {
    return axios.get(
      `/api/admin/employer-detail/`,
      {
        params: {
          employer_id: employer_id,
        },
      },
      config
    );
  }
  getEmployerDetailReportList(employer_ids) {
    return axios.get(
      `/api/admin/employer-detail/report/list`,
      {
        params: {
          employer_ids: employer_ids,
          page: 1,
          page_num: 5000,
        },
      },
      config
    );
  }
  getEmployerDetailList(id, email, sex, identity_card_number, page, page_num) {
    return axios.get(
      `/api/admin/employer-detail/list`,
      {
        params: {
          id: id,
          email: email,
          sex: sex,
          identity_card_number: identity_card_number,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  addEmployerDetail(
    employer_id,
    new_nationality_ids,
    delete_nationality_ids,
    new_department_ids,
    delete_department_ids,
    sex,
    birthday,
    start_working,
    identity_card_number,
    phone,
    income_tax_number,
    local_bank,
    local_account_number,
    local_branch_code,
    foreign_bank,
    foreign_account_number,
    foreign_branch_code,
    work_card_effective_date,
    work_card_expire_date,
    work_card_image,
    passport_expire_date,
    passport_image,
    email,
    contract_image,
    passport_number,
    work_card_number,
    contract_effective_date,
    contract_expire_date
  ) {
    return axios.post(
      `/api/admin/employer-detail/`,
      {
        employer_id: employer_id,
        new_nationality_ids: new_nationality_ids,
        delete_nationality_ids: delete_nationality_ids,
        new_department_ids: new_department_ids,
        delete_department_ids: delete_department_ids,
        sex: sex,
        birthday: birthday,
        start_working: start_working,
        identity_card_number: identity_card_number,
        phone: phone,
        income_tax_number: income_tax_number,
        local_bank: local_bank,
        local_account_number: local_account_number,
        local_branch_code: local_branch_code,
        foreign_bank: foreign_bank,
        foreign_account_number: foreign_account_number,
        foreign_branch_code: foreign_branch_code,
        work_card_effective_date: work_card_effective_date,
        work_card_expire_date: work_card_expire_date,
        work_card_image: work_card_image,
        passport_expire_date: passport_expire_date,
        passport_image: passport_image,
        email: email,
        contract_image: contract_image,
        passport_number: passport_number,
        work_card_number: work_card_number,
        contract_effective_date: contract_effective_date,
        contract_expire_date: contract_expire_date,
      },
      config
    );
  }
  editEmployerDetail(
    employer_id,
    new_nationality_ids,
    delete_nationality_ids,
    new_department_ids,
    delete_department_ids,
    sex,
    birthday,
    start_working,
    identity_card_number,
    phone,
    income_tax_number,
    local_bank,
    local_account_number,
    local_branch_code,
    foreign_bank,
    foreign_account_number,
    foreign_branch_code,
    work_card_effective_date,
    work_card_expire_date,
    work_card_image,
    passport_expire_date,
    passport_image,
    email,
    contract_image,
    passport_number,
    work_card_number,
    contract_effective_date,
    contract_expire_date
  ) {
    return axios.put(
      `/api/admin/employer-detail/`,
      {
        employer_id: employer_id,
        new_nationality_ids: new_nationality_ids,
        delete_nationality_ids: delete_nationality_ids,
        new_department_ids: new_department_ids,
        delete_department_ids: delete_department_ids,
        sex: sex,
        birthday: birthday,
        start_working: start_working,
        identity_card_number: identity_card_number,
        phone: phone,
        income_tax_number: income_tax_number,
        local_bank: local_bank,
        local_account_number: local_account_number,
        local_branch_code: local_branch_code,
        foreign_bank: foreign_bank,
        foreign_account_number: foreign_account_number,
        foreign_branch_code: foreign_branch_code,
        work_card_effective_date: work_card_effective_date,
        work_card_expire_date: work_card_expire_date,
        work_card_image: work_card_image,
        passport_expire_date: passport_expire_date,
        passport_image: passport_image,
        email: email,
        contract_image: contract_image,
        passport_number: passport_number,
        work_card_number: work_card_number,
        contract_effective_date: contract_effective_date,
        contract_expire_date: contract_expire_date,
      },
      config
    );
  }
}
