<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import Calendar from "primevue/calendar";
import { usePDF, VuePDF } from "@tato30/vue-pdf";
import { dateFormate } from "../../tool/formate";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import SlefPayment from "../../api/salary/self/payment";
import SelfPaymentContent from "../../api/salary/self/payment_content";
import Company from "../../api/salary/self/company";
import CompanyAccount from "../../api/salary/admin/company_account";
import Factory from "../../api/salary/self/factory";
import PaymentItem from "../../api/salary/self/payment_item";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";
import AccountItem from "../../api/salary/self/account_item";
import Department from "../../api/salary/self/department";

let departmentAPI = new Department();
let account_itemAPI = new AccountItem();
let companyAPI = new Company();
let company_accountAPI = new CompanyAccount();
let factoryAPI = new Factory();
let payment_itemAPI = new PaymentItem();
let userApi = new User();
let self_paymentAPI = new SlefPayment();
let self_payment_contentAPI = new SelfPaymentContent();

const store = useStore();

const dt = ref();
const dt_content = ref();
function Excel() {
  if (store.state.user.role == 1) {
    self_paymentAPI
      .getPaymentSellList(
        data.paymentFilters.id.value,
        data.paymentFilters.delivery_method.value,
        data.paymentFilters.payment_method.value,
        data.paymentFilters.company_id.value,
        data.paymentFilters.factory_id.value,
        data.paymentFilters.status.value,
        data.paymentFilters.payment_type.value,
        data.paymentFilters.created_at_begin.value,
        data.paymentFilters.created_at_end.value,
        1,
        1000
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          let tmp = res.data.data.datas;
          for (let i = 0; i < tmp.length; i++) {
            tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");
            tmp[i].status =
              tmp[i].status == 11
                ? i18n.$t("Payment").Status.NewPaymentAuditStatus
                : tmp[i].status == 12
                ? i18n.$t("Payment").Status.PassPaymentAuditStatus
                : tmp[i].status == 13
                ? i18n.$t("Payment").Status.RejectPaymentAuditStatus
                : "UnKnow";
          }
          data.excel.data = tmp;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });

          data.totalRecords = 0;
          data.paymentLoading = false;
        }
      })
      .then(() => {
        dt.value.exportCSV();
      });
  } else if (store.state.user.role == 2) {
    manager_paymentAPI
      .getPaymentSellList(
        data.paymentFilters.id.value,
        data.paymentFilters.delivery_method.value,
        data.paymentFilters.payment_method.value,
        data.paymentFilters.company_id.value,
        data.paymentFilters.factory_id.value,
        data.paymentFilters.status.value,
        data.paymentFilters.payment_type.value,
        data.paymentFilters.created_at_begin.value,
        data.paymentFilters.created_at_end.value,
        1,
        1000
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          let tmp = res.data.data.datas;
          for (let i = 0; i < tmp.length; i++) {
            tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");
            tmp[i].status =
              tmp[i].status == 11
                ? i18n.$t("Payment").Status.NewPaymentAuditStatus
                : tmp[i].status == 12
                ? i18n.$t("Payment").Status.PassPaymentAuditStatus
                : tmp[i].status == 13
                ? i18n.$t("Payment").Status.RejectPaymentAuditStatus
                : "UnKnow";
          }
          data.excel.data = tmp;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });

          data.totalRecords = 0;
          data.paymentLoading = false;
        }
      })
      .then(() => {
        dt.value.exportCSV();
      });
  }
}
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  add_payment_content: {
    payment_id: 0,
    payment_item_id: 0,
    spec: "",
    dollar: 0,
    total_count: 0,
    calculation_unit: "",
    total_dollar: 0,
    invoice_number: "",
    remark: "",
    account_item_id: 0,
    department_id: 0,
  },
  excel_content: { data: null },
  excel: { data: null },
  selectDepartmentList: [],
  DepartmentSearch: "",
  expandedRows: [],
  StatusSearch: "",
  selectAccountItemList: [],
  AccountItemSearch: "",
  selectCompanyAccountList: [],
  selectStatusList: [
    {
      id: 11,
      name: i18n.$t("Payment").Status.NewPaymentAuditStatus,
    },
    {
      id: 12,
      name: i18n.$t("Payment").Status.PassPaymentAuditStatus,
    },
    {
      id: 13,
      name: i18n.$t("Payment").Status.RejectPaymentAuditStatus,
    },
  ],
  selectPaymentMethodList: [
    {
      id: 1,
      name: i18n.$t("Payment").PaymentMethod.Cash,
    },
    {
      id: 2,
      name: i18n.$t("Payment").PaymentMethod.Transfer,
    },
    {
      id: 3,
      name: i18n.$t("Payment").PaymentMethod.Card,
    },
  ],
  selectPaymentItemList: [],
  selectPaymentTypeList: [
    {
      id: 1,
      name: i18n.$t("Payment").PaymentType.Purchase,
    },
    {
      id: 2,
      name: i18n.$t("Payment").PaymentType.PettyCash,
    },
  ],
  selectDeliveryMethodList: [
    {
      id: 1,
      name: i18n.$t("Payment").DeliveryMethod.Air,
    },
    {
      id: 2,
      name: i18n.$t("Payment").DeliveryMethod.Sea,
    },
    {
      id: 3,
      name: i18n.$t("Payment").DeliveryMethod.Land,
    },
  ],
  selectCompanyList: [],
  selectFactoryList: [],
  PaymentItemSearch: "",
  CompanySearch: "",
  FactorySearch: "",
  PaymentTypeSearch: "",
  DeliveryMethodSearch: "",
  paymentLoading: false,
  totalRecords: 0,
  passDialog: false,
  failDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  payments: { data: null },
  payment: null,
  status: 0,
  page: 1,
  page_num: 10,
  selectedPayments: null,
  paymentFilters: {
    id: { value: "", matchMode: "contains" },
    delivery_method: { value: "", matchMode: "contains" },
    payment_method: { value: "", matchMode: "contains" },
    company_id: { value: "", matchMode: "contains" },
    factory_id: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    payment_type: { value: "", matchMode: "contains" },
    created_at_begin: { value: "", matchMode: "contains" },
    created_at_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getPaymentList();
    // getCompanySelectList();
    getFactorySelectList();
    getPaymentItemSelectList();
  }
});
function getPaymentItemSelectList() {
  payment_itemAPI.getPaymentItemSelectList().then((res) => {
    if (res.data.error_code == 0) {
      data.selectPaymentItemList = res.data.data.datas;
      //   console.log(data.selectPaymentItemList);
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getCompanySelectList() {
  companyAPI.getCompanySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCompanyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getFactorySelectList() {
  factoryAPI.getFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectFactoryList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

// let admin_payment_contentAPI = new AdminPaymentContent();
// let manager_payment_contentAPI = new ManagerPaymentContent();
function getPaymentList() {
  CheckUser();
  self_paymentAPI
    .getPaymentSellList(
      data.paymentFilters.id.value,
      data.paymentFilters.delivery_method.value,
      data.paymentFilters.payment_method.value,
      data.paymentFilters.company_id.value,
      data.paymentFilters.factory_id.value,
      data.paymentFilters.status.value,
      data.paymentFilters.payment_type.value,
      data.paymentFilters.created_at_begin.value,
      data.paymentFilters.created_at_end.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.payments.data = res.data.data.datas;
        data.page = res.data.data.page;

        data.paymentLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.paymentLoading = false;
      }
    });
}

// function addPayment() {
//   CheckUser();
//   data.addDialog = true;
// }

function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(payment) {
  data.payment = payment;
  data.deleteDialog = true;
}
function deletePayment() {
  admin_paymentAPI.deletePayment([data.payment.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Payment").DeleteSuccess,
        life: 3000,
      });

      data.paymentLoading = true;
      getPaymentList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedPayment() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedPayments.length; i++) {
    selectDelete.push(data.selectedPayments[i].id);
  }
  admin_paymentAPI.deletePayment(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Payment").DeleteSuccess,
        life: 3000,
      });

      data.paymentLoading = true;
      data.selectedPayments = [];
      getPaymentList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.paymentFilters.id.value,
  () => {
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.CompanySearch,
  () => {
    data.paymentFilters.company_id.value =
      data.CompanySearch == null ? "" : data.CompanySearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.paymentFilters.status.value =
      data.StatusSearch == null ? "" : data.StatusSearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.FactorySearch,
  () => {
    data.paymentFilters.factory_id.value =
      data.FactorySearch == null ? "" : data.FactorySearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.PaymentTypeSearch,
  () => {
    data.paymentFilters.payment_type.value =
      data.PaymentTypeSearch == null ? "" : data.PaymentTypeSearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.DeliveryMethodSearch,
  () => {
    data.paymentFilters.delivery_method.value =
      data.DeliveryMethodSearch == null ? "" : data.DeliveryMethodSearch.name;
    data.paymentLoading = true;
    getPaymentList();
  }
);
function onRowExpand(e) {
  //   console.log(e.data.id);
  getPaymentContentList(e.data.id);
}
function getPaymentContentList(id) {
  self_payment_contentAPI
    .getPaymentContentList(0, id, 0, "", 1, 999)
    .then((res) => {
      if (res.data.error_code == 0) {
        for (let i = 0; i < data.payments.data.length; i++) {
          if (data.payments.data[i].id == id) {
            data.payments.data[i].contents = res.data.data.datas;
            data.payment.contents = res.data.data.datas;
            if (data.invoiceDialog == true) {
              for (let j = 0; j < data.payment.contents.length; j++) {
                data.payment.contents[j].account_item_id = {
                  account_type_id: 0,
                  account_type_name: "",
                  company_id: 0,
                  company_name: "",
                  description: "",
                  id: data.payment.contents[j].account_item_id,
                  name: data.payment.contents[j].account_item_name,
                };
                data.payment.contents[j].department_id = {
                  description: data.payment.contents[j].department_description,
                  id: data.payment.contents[j].department_id,
                  name: data.payment.contents[j].department_name,
                };
              }
            }
          }
        }
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function editStatus(payment, status) {
  data.payment = payment;
  data.status = status;
  if (status == 2) {
    data.failDialog = true;
  } else if (status == 3) {
    data.passDialog = true;
  }
}
function SaveEditFinish() {
  if (store.state.user.role == 1) {
    admin_paymentAPI
      .editPaymentFinish(
        data.payment.id,
        12,
        data.payment.company_account_id,
        data.payment.payment_method
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Payment").Success,
            life: 3000,
          });
          data.passDialog = false;
          data.paymentLoading = true;
          getPaymentList();
          if (data.status == 2) {
            data.failDialog = false;
          } else if (data.status == 3) {
            data.passDialog = false;
          }
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  } else if (store.state.user.role == 2) {
    manager_paymentAPI
      .editPaymentFinish(
        data.payment.id,
        12,
        data.payment.company_account_id,
        data.payment.payment_method
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Payment").Success,
            life: 3000,
          });
          data.passDialog = false;
          data.paymentLoading = true;
          getPaymentList();
          if (data.status == 2) {
            data.failDialog = false;
          } else if (data.status == 3) {
            data.passDialog = false;
          }
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  }
}

function getCompanyAccountSelectList() {
  company_accountAPI
    .getCompanyAccountSelectList(data.payment.company_id)
    .then((res) => {
      if (res.data.error_code == 0) {
        data.selectCompanyAccountList = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function editFinish(payment) {
  data.payment = payment;
  data.passDialog = true;
  getCompanyAccountSelectList();
  data.CompanyAccountSearch = "";
  for (let i = 0; i < data.selectPaymentMethodList.length; i++) {
    if (payment.payment_method == data.selectPaymentMethodList[i].name) {
      data.PaymentMethodSearch = data.selectPaymentMethodList[i];
    }
  }
}
watch(
  () => data.CompanyAccountSearch,
  () => {
    data.payment.company_account_id =
      data.CompanyAccountSearch == null ? "" : data.CompanyAccountSearch.id;
  }
);
watch(
  () => data.PaymentMethodSearch,
  () => {
    data.payment.payment_method =
      data.PaymentMethodSearch == null ? "" : data.PaymentMethodSearch.name;
  }
);
async function editInvoice(item) {
  data.payment = item;
  await getAccountItemSelectList();
  await getPaymentContentList(item.id);
  await getDepartmentSelectList();
  data.invoiceDialog = true;
}

function getDepartmentSelectList() {
  departmentAPI.getDepartmentSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectDepartmentList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getAccountItemSelectList() {
  account_itemAPI
    .getAccountItemSelectList("", data.payment.company_id)
    .then((res) => {
      if (res.data.error_code == 0) {
        data.selectAccountItemList = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function SaveEditInvoice(item) {
  self_payment_contentAPI
    .editPaymentContentInvoice(
      item.id,
      String(item.dollar),
      String(item.total_count),
      item.invoice_number,
      item.account_item_id.id,
      item.spec,
      item.department_id.id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Payment").Success,
          life: 3000,
        });
        data.paymentLoading = true;
        getPaymentList();
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function showPDF(url) {
  data.PDFShowDialog = true;
  data.pdf = usePDF(url);
  data.pdf.page = 1;
  // console.log(data.pdf);
}
function onPDFPage(event) {
  data.pdf.page = event.page + 1;
}
function ExcelContent(payment) {
  if (store.state.user.role == 1) {
    admin_payment_contentAPI
      .getPaymentContentList(0, payment.id, 0, "", 1, 999)
      .then((res) => {
        if (res.data.error_code == 0) {
          let tmp = res.data.data.datas;
          for (let i = 0; i < tmp.length; i++) {
            tmp[i].payment_item =
              tmp[i].payment_item_chinese_name +
              "(" +
              tmp[i].payment_item_english_name +
              ")";
            tmp[i].account_item_id =
              tmp[i].account_item_name +
              "(" +
              (tmp[i].payment_item_taxed == 1
                ? i18n.$t("PaymentItem").Taxed.IsTaxed
                : tmp[i].payment_item_taxed == 2
                ? i18n.$t("PaymentItem").Taxed.NotTaxed
                : i18n.$t("PaymentItem").Taxed.Unknow) +
              ")";
          }
          data.excel_content.data = tmp;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      })
      .then(() => {
        dt_content.value.exportCSV();
      });
  } else if (store.state.user.role == 2) {
    manager_payment_contentAPI
      .getPaymentContentList(0, payment.id, 0, "", 1, 999)
      .then((res) => {
        if (res.data.error_code == 0) {
          let tmp = res.data.data.datas;
          for (let i = 0; i < tmp.length; i++) {
            tmp[i].payment_item =
              tmp[i].payment_item_chinese_name +
              "(" +
              tmp[i].payment_item_english_name +
              ")";
            tmp[i].account_item_id =
              tmp[i].account_item_name +
              "(" +
              (tmp[i].payment_item_taxed == 1
                ? i18n.$t("PaymentItem").Taxed.IsTaxed
                : tmp[i].payment_item_taxed == 2
                ? i18n.$t("PaymentItem").Taxed.NotTaxed
                : i18n.$t("PaymentItem").Taxed.Unknow) +
              ")";
          }
          data.excel_content.data = tmp;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      })
      .then(() => {
        dt_content.value.exportCSV();
      });
  }
}
watch(
  () => data.date_search,
  async () => {
    data.paymentLoading = true;
    if (data.date_search != null) {
      if (data.date_search[1] != null) {
        const GMT_8_START_DATE =
          data.date_search[0].getHours() -
          (8 + data.date_search[0].getTimezoneOffset() / 60);

        const GMT_8_END_DATE =
          data.date_search[1].getHours() -
          (8 + data.date_search[1].getTimezoneOffset() / 60);
        let begin_time = data.date_search[0];
        begin_time.setHours(GMT_8_START_DATE);
        let end_time = data.date_search[1];
        end_time.setHours(GMT_8_END_DATE);

        data.paymentFilters.created_at_begin.value = begin_time.toISOString();
        data.paymentFilters.created_at_end.value = end_time.toISOString();
      } else {
        data.paymentFilters.created_at_begin.value = "";
        data.paymentFilters.created_at_end.value = "";
      }
    } else {
      data.paymentFilters.created_at_begin.value = "";
      data.paymentFilters.created_at_end.value = "";
    }
    getPaymentList();
  }
);
function SavePaymentContent(check) {
  if (check) {
    for (let i = 0; i < data.selectPaymentItemList.length; i++) {
      if (
        data.selectPaymentItemList[i].id ==
        data.add_payment_content.payment_item_id
      ) {
        if (data.selectPaymentItemList[i].taxed == 1) {
          data.add_payment_content.dollar =
            data.content_total / 1.15 / data.add_payment_content.total_count;
        } else {
          data.add_payment_content.dollar =
            data.content_total / data.add_payment_content.total_count;
        }
      }
    }
  }
  self_payment_contentAPI
    .addPaymentContent(
      data.payment.id,
      data.add_payment_content.payment_item_id,
      data.add_payment_content.spec,
      String(data.add_payment_content.dollar),
      String(data.add_payment_content.total_count),
      data.add_payment_content.calculation_unit,
      data.add_payment_content.invoice_number,
      data.add_payment_content.remark,
      data.add_payment_content.account_item_id,
      data.add_payment_content.department_id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        //   data.payment_contents=res.data.
        data.contentDialog = false;
        data.PaymentItemSearch = "";
        data.AccountItemSearch = "";
        data.DepartmentSearch = "";
        data.add_payment_content = {
          payment_id: 0,
          payment_item_id: 0,
          spec: "",
          dollar: 0,
          total_count: 0,
          calculation_unit: "",
          total_dollar: 0,
          invoice_number: "",
          remark: "",
          account_item_id: 0,
          department_id: 0,
        };
        getPaymentContentList(data.payment.id);
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Payment").Success,
          life: 3000,
        });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
watch(
  () => data.PaymentItemSearch,
  () => {
    data.add_payment_content.payment_item_id =
      data.PaymentItemSearch == null ? "" : data.PaymentItemSearch.id;
  }
);
watch(
  () => data.AccountItemSearch,
  () => {
    data.add_payment_content.account_item_id =
      data.AccountItemSearch == null ? "" : data.AccountItemSearch.id;
  }
);
watch(
  () => data.DepartmentSearch,
  () => {
    data.add_payment_content.department_id =
      data.DepartmentSearch == null ? "" : data.DepartmentSearch.id;
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.payments.data"
      :loading="data.paymentLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.paymentFilters"
      v-model:selection="data.selectedPayments"
      :row-hover="true"
      v-model:expandedRows="data.expandedRows"
      @rowExpand="onRowExpand"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Payment").PaymentTable.Search }}</span>
              </template>
              <p style="text-align: left" class="p-mb-2">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.date_search"
                    class="p-mr-1"
                    selectionMode="range"
                    dateFormat="yy/mm/dd"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('Payment').PaymentTable.CreatedAt.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.paymentFilters[
                        i18n.$t('Payment').PaymentTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Payment').PaymentTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.CompanySearch"
                    :options="data.selectCompanyList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Payment').PaymentTable.Company.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.FactorySearch"
                    :options="data.selectFactoryList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Payment').PaymentTable.Factory.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <!-- <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.PaymentTypeSearch"
                    :options="data.selectPaymentTypeList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Payment').PaymentTable.PaymentType.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span> -->
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Payment').PaymentTable.Status.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
              <p style="text-align: left" class="p-mb-2 p-d-none">
                <span class="p-input-icon-left p-mr-1 p-d-none">
                  <Dropdown
                    v-model="data.DeliveryMethodSearch"
                    :options="data.selectDeliveryMethodList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Payment').PaymentTable.DeliveryMethod.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
            </AccordionTab>
          </Accordion>
          <div class="p-col-12 p-d-flex p-jc-end">
            <!-- <Button
              icon="pi pi-print"
              class="p-button-rounded p-button-info p-mr-2"
              @click="Excel"
            /> -->
          </div>
        </div>
      </template>
      <Column :expander="true" headerStyle="width: 1rem" />
      <!-- <Column
        v-if="store.state.user.role == 1"
        class="p-col-1"
        selectionMode="multiple"
      ></Column> -->
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.ID.Field"
        :header="i18n.$t('Payment').PaymentTable.ID.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.CompanyName.Field"
        :header="i18n.$t('Payment').PaymentTable.CompanyName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.PaymentTypeName.Field"
        :header="i18n.$t('Payment').PaymentTable.PaymentTypeName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.FactoryName.Field"
        :header="i18n.$t('Payment').PaymentTable.FactoryName.Show"
      ></Column>
      <!-- <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.DeliveryMethod.Field"
        :header="i18n.$t('Payment').PaymentTable.DeliveryMethod.Show"
      ></Column> -->
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.Remark.Field"
        :header="i18n.$t('Payment').PaymentTable.Remark.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.SellDescription.Field"
        :header="i18n.$t('Payment').PaymentTable.SellDescription.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.SellmentMethod.Field"
        :header="i18n.$t('Payment').PaymentTable.SellmentMethod.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.TotalCount.Field"
        :header="i18n.$t('Payment').PaymentTable.TotalCount.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.TotalDollar.Field"
        :header="i18n.$t('Payment').PaymentTable.TotalDollar.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.TotalTaxed.Field"
        :header="i18n.$t('Payment').PaymentTable.TotalTaxed.Show"
      ></Column>
      <Column
        class="p-col-1"
        :header="i18n.$t('Payment').PaymentTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 11
              ? i18n.$t("Payment").Status.NewPaymentAuditStatus
              : slotProps.data.status == 12
              ? i18n.$t("Payment").Status.PassPaymentAuditStatus
              : slotProps.data.status == 13
              ? i18n.$t("Payment").Status.RejectPaymentAuditStatus
              : "UnKnow"
          }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        :header="i18n.$t('Payment').PaymentTable.CreatedAt.Show"
      >
        <template #body="slotProps"
          >{{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      >
        <template #body="slotProps">
          <Button
            v-if="slotProps.data.pdf_file_url != ''"
            icon="pi pi-file-pdf"
            class="p-button-rounded p-button-danger p-mr-2 p-button-sm"
            @click="showPDF(slotProps.data.pdf_file_url)"
          />
          <a
            class="p-button p-component p-button-icon-only p-button-rounded p-button-success p-button-sm"
            v-if="slotProps.data.pdf_file_url != ''"
            :href="slotProps.data.pdf_file_url"
            download
          >
            <i class="pi pi-download"></i>
          </a>
          <Button
            v-if="slotProps.data.status == 11"
            icon="pi pi-book"
            class="p-button-rounded p-button-help p-mr-2"
            @click="editInvoice(slotProps.data)"
          />
          <!-- <Button
            icon="pi pi-print"
            class="p-button-rounded p-button-info p-ml-2 p-button-sm"
            @click="ExcelContent(slotProps.data)"
          /> -->
          <!-- <Button
            v-if="slotProps.data.pdf_file_url != ''"
            icon="pi pi-download"
            class="p-button-rounded p-button-info p-mr-2 p-button-sm"
            @click="showPDF(slotProps.data.pdf_file_url)"
          /> -->
          <!-- <Button
            icon="pi pi-check"
            class="p-button-rounded p-button-info p-mr-2"
            @click="editFinish(slotProps.data)"
          />
          
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          /> -->
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="orders-subtable" style="width: 100%">
          <div>
            <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.PaymentItem.Show }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.DepartmentId.Show }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.AccountItemId.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Spec.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.TotalCount.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Dollar.Show }}
              </div>
              <div class="p-col-1">
                {{
                  i18n.$t("Payment").PaymentContentTable.CalculationUnit.Show
                }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.InvoiceNumber.Show }}
              </div>
            </div>
          </div>
          <div
            style="
              height: calc(100vh - 470px);
              overflow-y: scroll;
              overflow-x: hidden;
            "
          >
            <div
              :key="index"
              v-for="(item, index) in slotProps.data.contents"
              class="p-grid p-mt-1"
              style="border-bottom: 1px solid"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{
                  item.payment_item_chinese_name +
                  "(" +
                  item.payment_item_english_name +
                  ")"
                }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{ item.department_name }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{ item.account_item_name }}
                ({{
                  item.payment_item_taxed == 1
                    ? i18n.$t("PaymentItem").Taxed.IsTaxed
                    : item.payment_item_taxed == 2
                    ? i18n.$t("PaymentItem").Taxed.NotTaxed
                    : i18n.$t("PaymentItem").Taxed.Unknow
                }})
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.spec }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.total_count }}
                <!-- <InputNumber
              v-model="item.total_count"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            /> -->
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.dollar }}
                <!-- <InputNumber
              v-model="item.dollar"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            /> -->
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.calculation_unit }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{ item.invoice_number }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
    <!--PDF視窗 -->
    <Dialog
      v-model:visible="data.PDFShowDialog"
      :style="{ width: '95vw' }"
      :header="i18n.$t('Payment').Confirm.PDF_Title"
      :modal="true"
    >
      <div class="p-text-center">
        <VuePDF class="width:100%" :pdf="data.pdf.pdf" :page="data.pdf.page" />
      </div>
      <template #footer>
        <Paginator
          @page="onPDFPage($event)"
          :rows="1"
          :totalRecords="data.pdf.pages"
        ></Paginator>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.PDFShowDialog = false"
        />
      </template>
    </Dialog>
    <!-- 發票視窗 -->
    <Dialog
      v-model:visible="data.invoiceDialog"
      :style="{ width: '80%', minWidth: '350px' }"
      :header="i18n.$t('Payment').AuditPayment.Invoice"
      :modal="true"
      class="p-fluid"
    >
      <div class="confirmation-content">
        <div class="orders-subtable" style="width: 100%">
          <div>
            <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.PaymentItem.Show }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.DepartmentId.Show }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.AccountItemId.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Spec.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.TotalCount.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Dollar.Show }}
              </div>
              <div class="p-col-1">
                {{
                  i18n.$t("Payment").PaymentContentTable.CalculationUnit.Show
                }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.InvoiceNumber.Show }}
              </div>
            </div>
          </div>
          <div
            style="
              height: calc(100vh - 470px);
              overflow-y: scroll;
              overflow-x: hidden;
            "
          >
            <div
              :key="index"
              v-for="(item, index) in data.payment.contents"
              class="p-grid p-mt-1"
              style="border-bottom: 1px solid"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{
                  item.payment_item_chinese_name +
                  "(" +
                  item.payment_item_english_name +
                  ")"
                }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                <Dropdown
                  v-model="item.department_id"
                  :options="data.selectDepartmentList"
                  :filter="true"
                  optionLabel="name"
                  :placeholder="
                    i18n.$t('Payment').PaymentContentTable.DepartmentId.Show
                  "
                  style="width: 100%"
                  :showClear="true"
                >
                  <template #value="slotProps">
                    <div
                      class="country-item country-item-value"
                      v-if="slotProps.value"
                    >
                      <div>
                        {{ slotProps.value.name }}
                        <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                      </div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="country-item">
                      <div>
                        {{ slotProps.option.name }}
                        <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                      </div>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                <Dropdown
                  v-model="item.account_item_id"
                  :options="data.selectAccountItemList"
                  :filter="true"
                  optionLabel="name"
                  :placeholder="
                    i18n.$t('Payment').PaymentContentTable.AccountItemId.Show
                  "
                  style="width: 100%"
                  :showClear="true"
                >
                  <template #value="slotProps">
                    <div
                      class="country-item country-item-value"
                      v-if="slotProps.value"
                    >
                      <div>
                        {{ slotProps.value.name }}
                        <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                      </div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="country-item">
                      <div>
                        {{ slotProps.option.name }}
                        <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                      </div>
                    </div>
                  </template>
                </Dropdown>
                <!-- {{ item.account_item_name }} -->
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.spec }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                <!-- {{ item.total_count }} -->
                <InputNumber
                  v-model="item.total_count"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="5"
                  required="true"
                />
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                <!-- {{ item.dollar }} -->
                <InputNumber
                  v-model="item.dollar"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="5"
                  required="true"
                />
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.calculation_unit }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                <InputText
                  style="width: 90%"
                  v-model="item.invoice_number"
                  required="true"
                  class="p-mr-1"
                />
                <Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-info p-button-sm"
                  @click="SaveEditInvoice(item)"
                />
                <!-- {{ item.invoice_number }} -->
              </div>
            </div>
          </div>
          <div class="p-grid p-mt-1" style="border-top: 1px solid">
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-2"
            >
              <Dropdown
                v-model="data.PaymentItemSearch"
                :options="data.selectPaymentItemList"
                :filter="true"
                :optionLabel="
                  store.state.lang == 'cn' ? 'chinese_name' : 'english_name'
                "
                :placeholder="
                  i18n.$t('Payment').PaymentContentTable.PaymentItem.Show
                "
                style="width: 100%"
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div v-if="store.state.lang == 'cn'">
                      {{ slotProps.value.chinese_name }}
                      <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                    </div>
                    <div v-else>
                      {{ slotProps.value.english_name }}
                      <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div v-if="store.state.lang == 'cn'">
                      {{ slotProps.option.chinese_name }}
                      <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                    </div>
                    <div v-else>
                      {{ slotProps.option.english_name }}
                      <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                    </div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-2"
            >
              <Dropdown
                v-model="data.DepartmentSearch"
                :options="data.selectDepartmentList"
                :filter="true"
                optionLabel="name"
                :placeholder="
                  i18n.$t('Payment').PaymentContentTable.DepartmentId.Show
                "
                style="width: 100%"
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div>
                      {{ slotProps.value.name }}
                      <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div>
                      {{ slotProps.option.name }}
                      <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                    </div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-2"
            >
              <Dropdown
                v-model="data.AccountItemSearch"
                :options="data.selectAccountItemList"
                :filter="true"
                optionLabel="name"
                :placeholder="
                  i18n.$t('Payment').PaymentContentTable.AccountItemId.Show
                "
                style="width: 100%"
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div>
                      {{ slotProps.value.name }}
                      <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div>
                      {{ slotProps.option.name }}
                      <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                    </div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-1"
            >
              <InputText
                style="width: 100%"
                v-model="data.add_payment_content.spec"
                required="true"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-1"
            >
              <InputNumber
                style="width: 100%"
                v-model="data.add_payment_content.total_count"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="5"
                required="true"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-1"
            >
              <InputNumber
                style="width: 100%"
                v-model="data.add_payment_content.dollar"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="5"
                required="true"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-1"
            >
              <InputText
                style="width: 100%"
                v-model="data.add_payment_content.calculation_unit"
                required="true"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: auto;
              "
              class="p-col-2"
            >
              <InputText
                style="width: 90%"
                v-model="data.add_payment_content.invoice_number"
                required="true"
                class="p-mr-1"
              />
              <Button
                icon="pi pi-plus-circle"
                class="p-button-rounded p-button-info p-button-sm p-mr-1"
                @click="SavePaymentContent(false)"
              />
              <!-- <Button
                icon="pi pi-plus-circle"
                class="p-button-rounded p-button-success p-button-sm"
                @click="CountPaymentContent"
              /> -->
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.invoiceDialog = false"
        />
        <!-- <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveEditStatus"
        /> -->
      </template>
    </Dialog>
    <!-- 通過視窗 -->
    <Dialog
      v-model:visible="data.passDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Payment').AuditPayment.Pass"
      :modal="true"
      class="p-fluid"
    >
      <div class="confirmation-content">
        <span style="margin-top: 0px; width: 100%">
          <Dropdown
            v-model="data.PaymentMethodSearch"
            :options="data.selectPaymentMethodList"
            optionLabel="name"
            :filter="true"
            :placeholder="i18n.$t('Payment').PaymentTable.SellmentMethod.Show"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </span>
        <span style="margin-top: 0px; width: 100%">
          <Dropdown
            v-model="data.CompanyAccountSearch"
            :options="data.selectCompanyAccountList"
            optionLabel="number"
            :filter="true"
            :placeholder="i18n.$t('Payment').PaymentTable.CompanyAccount.Show"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.number }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.number }}</div>
              </div>
            </template>
          </Dropdown>
        </span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.passDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveEditFinish"
        />
      </template>
    </Dialog>
    <!-- 駁回視窗 -->
    <Dialog
      v-model:visible="data.failDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Payment').AuditPayment.Fail"
      :modal="true"
      class="p-fluid"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.payment"
          >{{ i18n.$t("Payment").Confirm.CotentFail }}
          <b>{{ data.payment.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.failDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveEditStatus"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Payment').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.payment"
          >{{ i18n.$t("Payment").Confirm.Content }}
          <b>{{ data.payment.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deletePayment"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Payment').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Payment").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedPayment"
        />
      </template>
    </Dialog>
  </div>
  <DataTable
    class="p-d-none"
    :value="data.excel.data"
    ref="dt"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentTable.ID.Field"
      :header="i18n.$t('Payment').PaymentTable.ID.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentTable.CompanyName.Field"
      :header="i18n.$t('Payment').PaymentTable.CompanyName.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentTable.PaymentTypeName.Field"
      :header="i18n.$t('Payment').PaymentTable.PaymentTypeName.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentTable.FactoryName.Field"
      :header="i18n.$t('Payment').PaymentTable.FactoryName.Show"
    ></Column>
    <!-- <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.DeliveryMethod.Field"
        :header="i18n.$t('Payment').PaymentTable.DeliveryMethod.Show"
      ></Column> -->
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentTable.Remark.Field"
      :header="i18n.$t('Payment').PaymentTable.Remark.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentTable.SellDescription.Field"
      :header="i18n.$t('Payment').PaymentTable.SellDescription.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentTable.SellmentMethod.Field"
      :header="i18n.$t('Payment').PaymentTable.SellmentMethod.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentTable.TotalCount.Field"
      :header="i18n.$t('Payment').PaymentTable.TotalCount.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentTable.TotalDollar.Field"
      :header="i18n.$t('Payment').PaymentTable.TotalDollar.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentTable.TotalTaxed.Field"
      :header="i18n.$t('Payment').PaymentTable.TotalTaxed.Show"
    ></Column>
    <Column
      class="p-col-1"
      :header="i18n.$t('Payment').PaymentTable.Status.Show"
      :field="i18n.$t('Payment').PaymentTable.Status.Field"
    >
      <!-- <template #body="slotProps">
        {{
          slotProps.data.status == 11
            ? i18n.$t("Payment").Status.NewPaymentAuditStatus
            : slotProps.data.status == 12
            ? i18n.$t("Payment").Status.PassPaymentAuditStatus
            : slotProps.data.status == 13
            ? i18n.$t("Payment").Status.RejectPaymentAuditStatus
            : "UnKnow"
        }}
      </template> -->
    </Column>
    <Column
      class="p-col-1"
      :header="i18n.$t('Payment').PaymentTable.CreatedAt.Show"
      :field="i18n.$t('Payment').PaymentTable.CreatedAt.Field"
    >
      <!-- <template #body="slotProps"
        >{{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
      </template> -->
    </Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_content.data"
    ref="dt_content"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentContentTable.PaymentItem.Field"
      :header="i18n.$t('Payment').PaymentContentTable.PaymentItem.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentContentTable.DepartmentId.Field"
      :header="i18n.$t('Payment').PaymentContentTable.DepartmentId.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentContentTable.AccountItemId.Field"
      :header="i18n.$t('Payment').PaymentContentTable.AccountItemId.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentContentTable.Spec.Field"
      :header="i18n.$t('Payment').PaymentContentTable.Spec.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentContentTable.TotalCount.Field"
      :header="i18n.$t('Payment').PaymentContentTable.TotalCount.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentContentTable.Dollar.Field"
      :header="i18n.$t('Payment').PaymentContentTable.Dollar.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentContentTable.CalculationUnit.Field"
      :header="i18n.$t('Payment').PaymentContentTable.CalculationUnit.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Payment').PaymentContentTable.InvoiceNumber.Field"
      :header="i18n.$t('Payment').PaymentContentTable.InvoiceNumber.Show"
    ></Column>
  </DataTable>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
