import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Payment {
  getPaymentInfo(id) {
    return axios.get(
      `/api/manager/payment/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPaymentSellList(
    id,
    delivery_method,
    payment_method,
    company_id,
    factory_id,
    status,
    payment_type_id,
    created_at_begin,
    created_at_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/payment/receipt/list`,
      {
        params: {
          id: id,
          delivery_method: delivery_method,
          payment_method: payment_method,
          company_id: company_id,
          factory_id: factory_id,
          status: status,
          payment_type_id: payment_type_id,
          created_at_begin: created_at_begin,
          created_at_end: created_at_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPaymentList(
    id,
    delivery_method,
    payment_method,
    company_id,
    factory_id,
    status,
    payment_type_id,
    created_at_begin,
    created_at_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/payment/list`,
      {
        params: {
          id: id,
          delivery_method: delivery_method,
          payment_method: payment_method,
          company_id: company_id,
          factory_id: factory_id,
          status: status,
          payment_type_id: payment_type_id,
          created_at_begin: created_at_begin,
          created_at_end: created_at_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  editPaymentStatus(id, status) {
    return axios.put(
      `/api/manager/payment/`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
  editPaymentFinish(id, status, company_account_id, payment_method) {
    return axios.put(
      `/api/manager/payment/`,
      {
        id: id,
        status: status,
        company_account_id: company_account_id,
        payment_method: payment_method,
      },
      config
    );
  }
  editPayment(
    id,
    company_id,
    delivery_method,
    payment_method,
    factory_id,
    status,
    payment_type_id,
    pdf_file,
    remark,
    description
  ) {
    return axios.put(
      `/api/manager/payment/`,
      {
        id: id,
        company_id: company_id,
        delivery_method: delivery_method,
        payment_method: payment_method,
        factory_id: factory_id,
        status: status,
        payment_type_id: payment_type_id,
        pdf_file: pdf_file,
        remark: remark,
        description: description,
      },
      config
    );
  }
  EditdPaymentPDF(id, pdf_file) {
    return axios.put(
      `/api/manager/payment/`,
      {
        id: id,
        pdf_file: pdf_file,
      },
      config
    );
  }
}
