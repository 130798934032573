import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class MakeUpAttendance {
  getMakeUpAttendanceInfo(id) {
    return axios.get(
      `/api/admin/make-up-attendance/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getMakeUpAttendanceList(
    id,
    punch_date_begin,
    punch_date_end,
    shift_id,
    apply_employer_name,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/make-up-attendance/list`,
      {
        params: {
          id: id,
          punch_date_begin: punch_date_begin,
          punch_date_end: punch_date_end,
          shift_id: shift_id,
          apply_employer_name: apply_employer_name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchMakeUpAttendance(datas) {
    return axios.post(
      `/api/admin/make-up-attendance/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addMakeUpAttendance(
    shift_id,
    punch_date,
    punch_time,
    after_punch_time,
    time_diff,
    apply_employer_id
  ) {
    return axios.post(
      `/api/admin/make-up-attendance/`,
      {
        shift_id: shift_id,
        punch_date: punch_date,
        punch_time: punch_time,
        after_punch_time: after_punch_time,
        apply_employer_id: apply_employer_id,
        time_diff: time_diff,
      },
      config
    );
  }
  editMakeUpAttendance(
    id,
    shift_id,
    punch_date,
    punch_time,
    after_punch_time,
    time_diff
  ) {
    return axios.put(
      `/api/admin/make-up-attendance/`,
      {
        id: id,
        shift_id: shift_id,
        punch_date: punch_date,
        punch_time: punch_time,
        after_punch_time: after_punch_time,
        time_diff: time_diff,
      },
      config
    );
  }
  editMakeUpAttendanceStatus(id, status) {
    return axios.put(
      `/api/admin/make-up-attendance/`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
  deleteMakeUpAttendance(id) {
    return axios.delete(
      `/api/admin/make-up-attendance/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
