<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import Dialog from "primevue/dialog";
import router from "../../router";
import Toast from "primevue/toast";
import InputNumber from "primevue/inputNumber";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputText";
import Button from "primevue/button";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import Employer from "../../api/salary/self/employer";
import { dateFormate } from "../../tool/formate";
import Organisation from "../../api/salary/admin/organisation";
import ManagerSalaryRaise from "../../api/salary/manager/salary_raise";
import AdminSalaryRaise from "../../api/salary/admin/salary_raise";
import PrintSalary from "../../components/PrintSalary.vue";
import { printHTML } from "../../tool/print";
import ManagerEmployer from "../../api/salary/manager/employer";
import AdminEmployer from "../../api/salary/admin/employer";
import ManagerEmployerDetail from "../../api/salary/manager/employer_detail";
import AdminEmployerDetail from "../../api/salary/admin/employer_detail";
const i18n = useI18n();
const toast = useToast();
const store = useStore();
let userApi = new User();
let employerApi = new Employer();
let organisationAPI = new Organisation();
let manager_salary_raiseAPI = new ManagerSalaryRaise();
let admin_salary_raiseAPI = new AdminSalaryRaise();

let manager_employerAPI = new ManagerEmployer();
let admin_employerAPI = new AdminEmployer();
let manager_employer_detailAPI = new ManagerEmployerDetail();
let admin_employer_detailAPI = new AdminEmployerDetail();

const printDom = ref(null);
const dt = ref();
const data = reactive({
  salaryDialog: false,
  add_salary_raise: {
    before_salary: 0,
    new_salary: 0,
    status: 0,
    reason: "",
  },
  OrganisationSearch: "",
  selectOrganisationList: [],
  StatusSearch: "",
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("ApplyBorrow").Reject,
    },
    {
      id: 2,
      name: i18n.$t("ApplyBorrow").Pass,
    },
    {
      id: 3,
      name: i18n.$t("ApplyBorrow").Doing,
    },
  ],
  salary_raises: { data: null },
  salary_raise: null,
  salary_raiseLoading: false,
  page: 1,
  page_num: 10,
  totalRecords: 0,
  date_search: "",
  employer: {
    organisation_name: "",
    name: "",
    english_name: "",
    position_name: "",
  },
  audit_salary_raiseFilters: {
    apply_employer_id: { value: "", matchMode: "contains" },
    apply_employer_name: { value: "", matchMode: "contains" },
    apply_date_begin: { value: "", matchMode: "contains" },
    apply_date_end: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    organisation_id: { value: "", matchMode: "contains" },
  },
  excel: { data: null },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  CheckUser();
  getEmployerInfo();
  getSalaryRaiseList();
  if (store.state.user.role == 1) {
    getOrganisationSelectList();
  }
});
async function printApplySalary(salary, dom) {
  await store.commit("set_salary", salary);
  switch (store.state.user.role) {
    case 1:
      admin_employerAPI
        .getEmployerInfo(salary.apply_employer_id)
        .then(async (res) => {
          if (res.data.error_code == 0) {
            await store.commit("set_employer", res.data.data);
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          admin_employer_detailAPI
            .getEmployerDetailInfo(salary.apply_employer_id)
            .then(async (res) => {
              if (res.data.error_code == 0) {
                await store.commit("set_employer_detail", res.data.data);
              } else {
                toast.add({
                  severity: "warn",
                  summary: "Warn Message",
                  detail: res.data.error_text,
                  life: 3000,
                });
              }
            })
            .then(() => {
              printHTML(dom);
            });
        });
      break;
    case 2:
      manager_employerAPI
        .getEmployerInfo(salary.apply_employer_id)
        .then(async (res) => {
          if (res.data.error_code == 0) {
            await store.commit("set_employer", res.data.data);
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          manager_employer_detailAPI
            .getEmployerDetailInfo(salary.apply_employer_id)
            .then(async (res) => {
              if (res.data.error_code == 0) {
                await store.commit("set_employer_detail", res.data.data);
              } else {
                toast.add({
                  severity: "warn",
                  summary: "Warn Message",
                  detail: res.data.error_text,
                  life: 3000,
                });
              }
            })
            .then(() => {
              printHTML(dom);
            });
        });

      break;
  }
}
function Excel() {
  switch (store.state.user.role) {
    case 1:
      admin_salary_raiseAPI
        .getSalaryRaiseList(
          data.audit_salary_raiseFilters.organisation_id.value,
          data.audit_salary_raiseFilters.apply_date_begin.value,
          data.audit_salary_raiseFilters.apply_date_end.value,
          data.audit_salary_raiseFilters.apply_employer_id.value,
          data.audit_salary_raiseFilters.apply_employer_name.value,
          data.audit_salary_raiseFilters.status.value,
          1,
          500
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            let tmp = res.data.data.datas;
            for (let i = 0; i < tmp.length; i++) {
              tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");
              tmp[i].effective_date = dateFormate(
                tmp[i].effective_date,
                "YYYY-MM-DD"
              );
              tmp[i].status =
                tmp[i].status == 1
                  ? i18n.$t("ApplyBorrow").Reject
                  : tmp[i].status == 2
                  ? i18n.$t("ApplyBorrow").Pass
                  : tmp[i].status == 3
                  ? i18n.$t("ApplyBorrow").Doing
                  : i18n.$t("ApplyBorrow").UnKnow;
            }
            data.excel.data = tmp;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          dt.value.exportCSV();
        });
      break;
    case 2:
      manager_salary_raiseAPI
        .getSalaryRaiseList(
          data.audit_salary_raiseFilters.organisation_id.value,
          data.audit_salary_raiseFilters.apply_date_begin.value,
          data.audit_salary_raiseFilters.apply_date_end.value,
          data.audit_salary_raiseFilters.apply_employer_id.value,
          data.audit_salary_raiseFilters.apply_employer_name.value,
          data.audit_salary_raiseFilters.status.value,
          1,
          500
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            let tmp = res.data.data.datas;
            for (let i = 0; i < tmp.length; i++) {
              tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");
              tmp[i].effective_date = dateFormate(
                tmp[i].effective_date,
                "YYYY-MM-DD"
              );
              tmp[i].status =
                tmp[i].status == 1
                  ? i18n.$t("ApplyBorrow").Reject
                  : tmp[i].status == 2
                  ? i18n.$t("ApplyBorrow").Pass
                  : tmp[i].status == 3
                  ? i18n.$t("ApplyBorrow").Doing
                  : i18n.$t("ApplyBorrow").UnKnow;
            }
            data.excel.data = tmp;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          dt.value.exportCSV();
        });
      break;
  }
}
function DeleteSalaryRaise(id) {
  admin_salary_raiseAPI.deleteSalaryRaise([id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("AuditSalaryRaise").DeleteSuccess,
        life: 3000,
      });

      data.salary_raiseLoading = true;
      getSalaryRaiseList();
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function SetSalaryRaiseSuccess(salary_raise) {
  data.salaryDialog = true;
  data.add_salary_raise = salary_raise;

  console.log(new Date(salary_raise.effective_date).getTime());
  console.log(new Date().getTime());
  console.log(
    new Date(salary_raise.effective_date).getTime() <= new Date().getTime()
  );
  // new Date(slotProps.data.effective_date).getTime() <=
  //                     new Date()
}
function SetSalaryRaise(id, status, reason, new_salary) {
  admin_salary_raiseAPI
    .editSalaryStatus(id, status, reason, new_salary)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("AuditSalaryRaise").Success,
          life: 3000,
        });
        data.salary_raiseLoading = true;
        getSalaryRaiseList();
        data.salaryDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function getSalaryRaiseList() {
  switch (store.state.user.role) {
    case 1:
      admin_salary_raiseAPI
        .getSalaryRaiseList(
          data.audit_salary_raiseFilters.organisation_id.value,
          data.audit_salary_raiseFilters.apply_date_begin.value,
          data.audit_salary_raiseFilters.apply_date_end.value,
          data.audit_salary_raiseFilters.apply_employer_id.value,
          data.audit_salary_raiseFilters.apply_employer_name.value,
          data.audit_salary_raiseFilters.status.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.salary_raises.data = res.data.data.datas;
            data.page = res.data.data.page;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            if (res.data.error_code == 8106) {
              data.totalRecords = 0;
              data.salary_raises.data = [];
              // data.page = 0;
            }
          }
          data.salary_raiseLoading = false;
        });
      break;
    case 2:
      manager_salary_raiseAPI
        .getSalaryRaiseList(
          data.audit_salary_raiseFilters.organisation_id.value,
          data.audit_salary_raiseFilters.apply_date_begin.value,
          data.audit_salary_raiseFilters.apply_date_end.value,
          data.audit_salary_raiseFilters.apply_employer_id.value,
          data.audit_salary_raiseFilters.apply_employer_name.value,
          data.audit_salary_raiseFilters.status.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.salary_raises.data = res.data.data.datas;
            data.page = res.data.data.page;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            if (res.data.error_code == 8106) {
              data.totalRecords = 0;
              data.salary_raises.data = [];
              // data.page = 0;
            }
          }
          data.salary_raiseLoading = false;
        });
      break;
  }
}

function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getEmployerInfo() {
  employerApi.getEmployerInfo().then((res) => {
    if (res.data.error_code == 0) {
      data.employer = res.data.data;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.salary_raiseLoading = true;
    getSalaryRaiseList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.salary_raiseLoading = true;
    if (data.StatusSearch != null) {
      data.audit_salary_raiseFilters.status.value =
        data.StatusSearch == null ? "" : data.StatusSearch.id;
    } else {
      data.audit_salary_raiseFilters.status.value = "";
    }
    getSalaryRaiseList();
  }
);
watch(
  () => data.audit_salary_raiseFilters.apply_employer_id.value,
  () => {
    data.salary_raiseLoading = true;
    getSalaryRaiseList();
  }
);
watch(
  () => data.audit_salary_raiseFilters.apply_employer_name.value,
  () => {
    data.salary_raiseLoading = true;
    getSalaryRaiseList();
  }
);
watch(
  () => data.date_search,
  () => {
    data.salary_raiseLoading = true;
    if (data.date_search != null) {
      data.audit_salary_raiseFilters.apply_date_begin.value = new Date(
        data.date_search.getFullYear(),
        data.date_search.getMonth(),
        1
      ).toISOString();
      data.audit_salary_raiseFilters.apply_date_end.value = new Date(
        data.date_search.getFullYear(),
        data.date_search.getMonth() + 1,
        1
      ).toISOString();
    } else {
      data.audit_salary_raiseFilters.apply_date_begin.value = "";
      data.audit_salary_raiseFilters.apply_date_end.value = "";
    }
    getSalaryRaiseList();
  }
);
watch(
  () => data.OrganisationSearch,
  () => {
    data.salary_raiseLoading = true;
    data.audit_salary_raiseFilters.organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    getSalaryRaiseList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 90px" class="p-text-left">
      <div class="p-grid">
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("AuditHoliday").Employer }}
        </div>
        <div class="p-col-12">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.salary_raises.data"
            :loading="data.salary_raiseLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 255px)"
            style="min-width: 375px; margin-top: -5px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.audit_salary_raiseFilters"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    class="p-mr-1"
                    v-model="data.date_search"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('SpotExchangeRate').SpotExchangeRateTable
                        .RateOfMonth.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.audit_salary_raiseFilters[
                        i18n.$t('AuditSalaryRaise').AuditSalaryRaiseTable
                          .ApplyEmployerID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('AuditSalaryRaise').AuditSalaryRaiseTable
                        .ApplyEmployerID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.audit_salary_raiseFilters[
                        i18n.$t('AuditSalaryRaise').AuditSalaryRaiseTable
                          .ApplyEmployerName.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('AuditSalaryRaise').AuditSalaryRaiseTable
                        .ApplyEmployerName.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('AuditSalaryRaise').AuditSalaryRaiseTable.Status
                        .Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span
                  class="p-input-icon-left p-ml-1"
                  v-if="store.state.user.role == 1"
                >
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.OrganisationSearch"
                    :options="data.selectOrganisationList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Employer').EmployerTable.Organisation.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </div>
              <div class="p-w-100 p-text-right">
                <Button
                  icon="pi pi-book"
                  class="p-button-info"
                  label="Excel"
                  @click="Excel"
                />
              </div>
            </template>
            <Column
              class="p-col-1"
              :header="i18n.$t('ApplySalary').ApplySalaryTable.ApplyDate.Show"
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplySalary').ApplySalaryTable.ApplyEmployerName.Show
              "
              :field="
                i18n.$t('ApplySalary').ApplySalaryTable.ApplyEmployerName.Field
              "
            >
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplySalary').ApplySalaryTable.BeforeSalary.Show
              "
              :field="
                i18n.$t('ApplySalary').ApplySalaryTable.BeforeSalary.Field
              "
            >
            </Column>
            <Column
              class="p-col-2"
              :header="i18n.$t('ApplySalary').ApplySalaryTable.NewSalary.Show"
              :field="i18n.$t('ApplySalary').ApplySalaryTable.NewSalary.Field"
            >
            </Column>
            <Column
              class="p-col-2"
              :header="i18n.$t('ApplySalary').ApplySalaryTable.Reason.Show"
              :field="i18n.$t('ApplySalary').ApplySalaryTable.Reason.Field"
            >
            </Column>
            <Column
              class="p-col-1"
              :header="
                i18n.$t('ApplySalary').ApplySalaryTable.EffectiveDate.Show
              "
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.effective_date, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplySalary').ApplySalaryTable.AuditEmployerName.Show
              "
              :field="
                i18n.$t('ApplySalary').ApplySalaryTable.AuditEmployerName.Field
              "
            >
            </Column>
            <Column
              class="p-col-2"
              :header="i18n.$t('ApplySalary').ApplySalaryTable.Status.Show"
            >
              <template #body="slotProps">
                <span v-if="slotProps.data.status != 3">
                  {{
                    slotProps.data.status == 1
                      ? i18n.$t("ApplyBorrow").Reject
                      : slotProps.data.status == 2
                      ? i18n.$t("ApplyBorrow").Pass
                      : slotProps.data.status == 3
                      ? i18n.$t("ApplyBorrow").Doing
                      : i18n.$t("ApplyBorrow").UnKnow
                  }}
                </span>
                <div v-else>
                  <Button
                    v-if="
                      new Date(slotProps.data.effective_date).getTime() >
                        new Date().getTime() ||
                      (new Date(slotProps.data.effective_date).getMonth() ==
                        new Date().getMonth() &&
                        new Date(slotProps.data.effective_date).getFullYear() ==
                          new Date().getFullYear())
                    "
                    icon="pi pi-check"
                    class="p-button-rounded p-button-success p-mr-2"
                    @click="SetSalaryRaiseSuccess(slotProps.data)"
                  />
                  <Button
                    v-if="
                      new Date(slotProps.data.effective_date).getTime() >
                        new Date().getTime() ||
                      (new Date(slotProps.data.effective_date).getMonth() ==
                        new Date().getMonth() &&
                        new Date(slotProps.data.effective_date).getFullYear() ==
                          new Date().getFullYear())
                    "
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger"
                    @click="
                      SetSalaryRaise(
                        slotProps.data.id,
                        1,
                        slotProps.data.reason,
                        slotProps.data.new_salary
                      )
                    "
                  />
                  <Button
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-danger p-ml-2"
                    @click="DeleteSalaryRaise(slotProps.data.id)"
                  />
                </div>
              </template>
            </Column>
            <!-- <Column>
              <template #body="slotProps">
                <Button
                  :label="i18n.$t('ApplyBorrow').Print"
                  icon="pi pi-print"
                  class="p-button-success p-mr-2"
                  @click="printApplySalary(slotProps.data, printDom)"
                />
              </template>
            </Column> -->
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <!-- 確認視窗 -->
  <Dialog
    v-model:visible="data.salaryDialog"
    :style="{ width: '450px', minWidth: '350px' }"
    :header="i18n.$t('AuditSalaryRaise').SalaryInfo"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-field">
      <label for="name">{{
        i18n.$t("AuditSalaryRaise").AuditSalaryRaiseTable.BeforeSalary.Show
      }}</label>
      <InputNumber
        id="name"
        v-model="data.add_salary_raise.before_salary"
        mode="currency"
        currency="USD"
        locale="en-US"
        :minFractionDigits="2"
        :maxFractionDigits="5"
        required="true"
        readonly
      />
    </div>
    <div class="p-field">
      <label for="name">{{
        i18n.$t("AuditSalaryRaise").AuditSalaryRaiseTable.NewSalary.Show
      }}</label>
      <InputNumber
        id="name"
        v-model="data.add_salary_raise.new_salary"
        mode="currency"
        currency="USD"
        locale="en-US"
        :minFractionDigits="2"
        :maxFractionDigits="5"
        required="true"
      />
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('AuditSalaryRaise').Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.salaryDialog = false"
      />
      <Button
        :label="i18n.$t('AuditSalaryRaise').Save"
        icon="pi pi-check"
        class="p-button-text"
        @click="
          SetSalaryRaise(
            data.add_salary_raise.id,
            2,
            data.add_salary_raise.reason,
            String(data.add_salary_raise.new_salary)
          )
        "
      />
    </template>
  </Dialog>
  <div class="p-d-none">
    <div ref="printDom">
      <PrintSalary />
    </div>
  </div>
  <DataTable
    class="p-d-none"
    :value="data.excel.data"
    ref="dt"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :header="i18n.$t('ApplySalary').ApplySalaryTable.ApplyDate.Show"
      :field="i18n.$t('ApplySalary').ApplySalaryTable.CreatedAt.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('ApplySalary').ApplySalaryTable.ApplyEmployerName.Show"
      :field="i18n.$t('ApplySalary').ApplySalaryTable.ApplyEmployerName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('ApplySalary').ApplySalaryTable.BeforeSalary.Show"
      :field="i18n.$t('ApplySalary').ApplySalaryTable.BeforeSalary.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('ApplySalary').ApplySalaryTable.NewSalary.Show"
      :field="i18n.$t('ApplySalary').ApplySalaryTable.NewSalary.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('ApplySalary').ApplySalaryTable.Reason.Show"
      :field="i18n.$t('ApplySalary').ApplySalaryTable.Reason.Field"
    >
    </Column>
    <Column
      class="p-col-1"
      :header="i18n.$t('ApplySalary').ApplySalaryTable.EffectiveDate.Show"
      :field="i18n.$t('ApplySalary').ApplySalaryTable.EffectiveDate.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('ApplySalary').ApplySalaryTable.Status.Show"
      :field="i18n.$t('ApplySalary').ApplySalaryTable.Status.Field"
    >
    </Column>
  </DataTable>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
