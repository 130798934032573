<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Info from "../../components/Info.vue";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import SelfEmployer from "../../api/salary/self/employer";
import { useStore } from "vuex";
let self_employerAPI = new SelfEmployer();
const toast = useToast();
const store = useStore();
const data = reactive({});
const beforemounted = onBeforeMount(async () => {
  store.commit("set_employer", null);
});

const mounted = onMounted(async () => {
  GetSelfInfo();
  await store.commit("set_shift_search_type", "Info");
});
function GetSelfInfo() {
  self_employerAPI.getEmployerInfo().then((res) => {
    if (res.data.error_code == 0) {
      store.commit("set_employer", res.data.data);
      store.state.bouns_employer = [];
      store.state.bouns_employer.push(res.data.data);
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div class="p-text-left" style="margin-top: 100px">
      <Info v-if="store.state.employer != null" />
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
