<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";

import * as XLSX from "xlsx/xlsx.mjs";
import Dialog from "primevue/dialog";
import User from "../../api/salary/auth/user";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import Toast from "primevue/toast";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Organisation from "../../api/salary/admin/organisation";
import Employer from "../../api/salary/admin/employer";
import AttendanceRecords from "../../api/salary/admin/attendance_records";
import ManagerAttendanceRecords from "../../api/salary/manager/attendance_records";
import InputText from "primevue/inputText";
import { useToast } from "primevue/usetoast";
import { dateFormate } from "../../tool/formate";
import { useI18n } from "../../i18nPlugin";
import { useStore } from "vuex";

const toast = useToast();
const i18n = useI18n();
const store = useStore();

const dt = ref();
let userApi = new User();
let attendance_recordsAPI = new AttendanceRecords();
let manager_attendance_recordsAPI = new ManagerAttendanceRecords();

let organisationAPI = new Organisation();
let employerAPI = new Employer();

const data = reactive({
  excel: { data: null },
  EditStatusDialog: false,
  selectEmployerList: [],
  selectedAttendanceRecords: [],
  time_diff: 0,
  shift: null,
  OrganisationSearch: null,
  selectShiftList: [],
  selectOrganisationList: [],
  today: new Date(),
  attendance_records: null,
  attendance_recordsLoading: false,
  attendance_recordss: { data: null },
  totalRecords: 0,
  page: 1,
  page_num: 10,
  attendance_recordsFilters: {
    employer_id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    employer_organisation_id: { value: "", matchMode: "contains" },
    shift_id: { value: "", matchMode: "contains" },
    punch_date_begin: { value: "", matchMode: "contains" },
    punch_date_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  if (store.state.user.role == 1) {
    getOrganisationSelectList();
    getEmployerSelectList();
  }
  getAttendanceRecordsList();
  data.time_diff = 8 * 60 + new Date().getTimezoneOffset();
});

function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getEmployerSelectList() {
  employerAPI.getEmployerSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectEmployerList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getAttendanceRecordsList() {
  if (store.state.user.role == 1) {
    attendance_recordsAPI
      .getAttendanceRecordsReport(
        data.attendance_recordsFilters.employer_id.value,
        data.attendance_recordsFilters.employer_organisation_id.value,
        data.attendance_recordsFilters.punch_date_begin.value,
        data.attendance_recordsFilters.punch_date_end.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.totalRecords = res.data.data.total_count;
          data.attendance_recordss.data = res.data.data.datas;
          data.attendance_recordsLoading = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
          data.attendance_recordsLoading = false;
        }
      });
  } else if (store.state.user.role == 2) {
    manager_attendance_recordsAPI
      .getAttendanceRecordsReport(
        data.attendance_recordsFilters.employer_id.value,
        1,
        data.attendance_recordsFilters.punch_date_begin.value,
        data.attendance_recordsFilters.punch_date_end.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.totalRecords = res.data.data.total_count;
          data.attendance_recordss.data = res.data.data.datas;
          data.attendance_recordsLoading = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
          data.attendance_recordsLoading = false;
        }
      });
  }
}

function onPage(event) {
  data.page = event.page + 1;
}

watch(
  () => data.page,
  () => {
    data.attendance_recordsLoading = true;
    getAttendanceRecordsList();
  }
);

watch(
  () => data.EmployerSearch,
  () => {
    data.attendance_recordsLoading = true;
    data.attendance_recordsFilters.employer_id.value =
      data.EmployerSearch == null ? "" : data.EmployerSearch.id;
    getAttendanceRecordsList();
  }
);

watch(
  () => data.OrganisationSearch,
  () => {
    data.attendance_recordsLoading = true;
    data.attendance_recordsFilters.employer_organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    getAttendanceRecordsList();
  }
);

watch(
  () => data.date_search,
  async () => {
    data.attendance_recordsLoading = true;
    if (data.date_search != null) {
      if (data.date_search[1] != null) {
        //     dateFormate(data.today, "YYYY-MM-DD") + "T00:00:00.000+08:00",
        //   dateFormate(data.today, "YYYY-MM-DD") + "T15:59:59.999+08:00",
        data.attendance_recordsFilters.punch_date_begin.value =
          dateFormate(data.date_search[0], "YYYY-MM-DD") +
          "T00:00:00.000+08:00";
        data.attendance_recordsFilters.punch_date_end.value =
          dateFormate(data.date_search[1], "YYYY-MM-DD") +
          "T15:59:59.999+08:00";
      } else {
        data.attendance_recordsFilters.punch_date_begin.value = "";
        data.attendance_recordsFilters.punch_date_end.value = "";
      }
    } else {
      data.attendance_recordsFilters.punch_date_begin.value = "";
      data.attendance_recordsFilters.punch_date_end.value = "";
    }
    getAttendanceRecordsList();
  }
);

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
function Excel() {
  if (store.state.user.role == 1) {
    attendance_recordsAPI
      .getAttendanceRecordsReport(
        data.attendance_recordsFilters.employer_id.value,
        data.attendance_recordsFilters.employer_organisation_id.value,
        data.attendance_recordsFilters.punch_date_begin.value,
        data.attendance_recordsFilters.punch_date_end.value,
        1,
        10000
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          let tmp = res.data.data.datas;
          for (let i = 0; i < tmp.length; i++) {
            tmp[i].shift_date = tmp[i].shift_date.split("T")[0];
            tmp[i].shift_start_time = tmp[i].shift_start_time
              .split("T")[1]
              .replace(":00Z", "");
            tmp[i].shift_end_time = tmp[i].shift_end_time
              .split("T")[1]
              .replace(":00Z", "");

            tmp[i].attendance_records_punch_time =
              tmp[i].attendance_records_punch_time.split("T")[0] +
              " " +
              tmp[i].attendance_records_punch_time
                .split("T")[1]
                .replace("+08:00", "");

            tmp[i].attendance_records_after_punch_time =
              tmp[i].attendance_records_after_punch_time.split("T")[0] +
              " " +
              tmp[i].attendance_records_after_punch_time
                .split("T")[1]
                .replace("+08:00", "");

            tmp[i].check_in_status =
              tmp[i].check_in_status == 1
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckInType.NoClockAtWorkCheckInStatus
                : tmp[i].check_in_status == 2
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckInType.DelayCheckInStatus
                : tmp[i].check_in_status == 3
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckInType.NormalCheckInStatus
                : i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckInType.Unknow;

            tmp[i].check_out_status =
              tmp[i].check_out_status == 1
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckOutType.NoClockAtWorkCheckOutStatus
                : tmp[i].check_out_status == 2
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckOutType.DelayCheckOutStatus
                : tmp[i].check_out_status == 3
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckOutType.NormalCheckOutStatus
                : i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckOutType.Unknow;

            tmp[i].determination_of_normal_working_hours_status =
              tmp[i].determination_of_normal_working_hours_status == 1
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .AbnormalCheckInDeterminationOfNormalWorkingHoursStatus
                : tmp[i].determination_of_normal_working_hours_status == 2
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .LateAndLeaveEarlyDeterminationOfNormalWorkingHoursStatus
                : tmp[i].determination_of_normal_working_hours_status == 3
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .LeaveEarlyAndWorkNormallyDeterminationOfNormalWorkingHoursStatus
                : tmp[i].determination_of_normal_working_hours_status == 4
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .LateForGetOffWorkDeterminationOfNormalWorkingHoursStatus
                : tmp[i].determination_of_normal_working_hours_status == 5
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .OvertimeTimeDeterminationOfNormalWorkingHoursStatus
                : tmp[i].determination_of_normal_working_hours_status == 6
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .NormalDeterminationOfNormalWorkingHoursStatus
                : i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType.Unknow;
          }
          data.excel.data = tmp;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      })
      .then(() => {
        dt.value.exportCSV();
      });
  } else if (store.state.user.role == 2) {
    manager_attendance_recordsAPI
      .getAttendanceRecordsReport(
        data.attendance_recordsFilters.employer_id.value,
        0,
        data.attendance_recordsFilters.punch_date_begin.value,
        data.attendance_recordsFilters.punch_date_end.value,
        1,
        10000
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          let tmp = res.data.data.datas;
          for (let i = 0; i < tmp.length; i++) {
            tmp[i].shift_date = tmp[i].shift_date.split("T")[0];
            tmp[i].shift_start_time = tmp[i].shift_start_time
              .split("T")[1]
              .replace(":00Z", "");
            tmp[i].shift_end_time = tmp[i].shift_end_time
              .split("T")[1]
              .replace(":00Z", "");

            tmp[i].attendance_records_punch_time =
              tmp[i].attendance_records_punch_time.split("T")[0] +
              " " +
              tmp[i].attendance_records_punch_time
                .split("T")[1]
                .replace("+08:00", "");

            tmp[i].attendance_records_after_punch_time =
              tmp[i].attendance_records_after_punch_time.split("T")[0] +
              " " +
              tmp[i].attendance_records_after_punch_time
                .split("T")[1]
                .replace("+08:00", "");

            tmp[i].check_in_status =
              tmp[i].check_in_status == 1
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckInType.NoClockAtWorkCheckInStatus
                : tmp[i].check_in_status == 2
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckInType.DelayCheckInStatus
                : tmp[i].check_in_status == 3
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckInType.NormalCheckInStatus
                : i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckInType.Unknow;

            tmp[i].check_out_status =
              tmp[i].check_out_status == 1
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckOutType.NoClockAtWorkCheckOutStatus
                : tmp[i].check_out_status == 2
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckOutType.DelayCheckOutStatus
                : tmp[i].check_out_status == 3
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckOutType.NormalCheckOutStatus
                : i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .CheckOutType.Unknow;

            tmp[i].determination_of_normal_working_hours_status =
              tmp[i].determination_of_normal_working_hours_status == 1
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .AbnormalCheckInDeterminationOfNormalWorkingHoursStatus
                : tmp[i].determination_of_normal_working_hours_status == 2
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .LateAndLeaveEarlyDeterminationOfNormalWorkingHoursStatus
                : tmp[i].determination_of_normal_working_hours_status == 3
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .LeaveEarlyAndWorkNormallyDeterminationOfNormalWorkingHoursStatus
                : tmp[i].determination_of_normal_working_hours_status == 4
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .LateForGetOffWorkDeterminationOfNormalWorkingHoursStatus
                : tmp[i].determination_of_normal_working_hours_status == 5
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .OvertimeTimeDeterminationOfNormalWorkingHoursStatus
                : tmp[i].determination_of_normal_working_hours_status == 6
                ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType
                    .NormalDeterminationOfNormalWorkingHoursStatus
                : i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                    .NormalWorkHoursCheckType.Unknow;
          }
          data.excel.data = tmp;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      })
      .then(() => {
        dt.value.exportCSV();
      });
  }
}
</script>

<template>
  <Toast />
  <div>
    <div style="margin-top: 85px">
      <div class="p-grid">
        <div class="p-col-12" style="height: calc(100vh - 84px)">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.attendance_recordss.data"
            :loading="data.attendance_recordsLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 120px)"
            style="min-width: 375px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.attendance_recordsFilters"
            v-model:selection="data.selectedAttendanceRecords"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <Accordion class="accordion-custom p-col-12" :activeIndex="0">
                  <AccordionTab>
                    <template #header>
                      <i class="pi pi-search p-mr-1"></i>
                      <span>{{ i18n.$t("Shift").ShiftTable.Search }}</span>
                    </template>
                    <p
                      v-if="store.state.user.role == 1"
                      style="text-align: left"
                    >
                      <Dropdown
                        v-model="data.EmployerSearch"
                        :options="data.selectEmployerList"
                        optionLabel="name"
                        :filter="true"
                        :placeholder="
                          i18n.$t('Employer').EmployerTable.Name.Show
                        "
                        :showClear="true"
                      >
                        <template #value="slotProps">
                          <div
                            class="country-item country-item-value"
                            v-if="slotProps.value"
                          >
                            <div>{{ slotProps.value.name }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="country-item">
                            <div>{{ slotProps.option.name }}</div>
                          </div>
                        </template>
                      </Dropdown>

                      <Dropdown
                        v-model="data.OrganisationSearch"
                        :options="data.selectOrganisationList"
                        optionLabel="name"
                        :filter="true"
                        :placeholder="
                          i18n.$t('Employer').EmployerTable.Organisation.Show
                        "
                        :showClear="true"
                      >
                        <template #value="slotProps">
                          <div
                            class="country-item country-item-value"
                            v-if="slotProps.value"
                          >
                            <div>{{ slotProps.value.name }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="country-item">
                            <div>{{ slotProps.option.name }}</div>
                          </div>
                        </template>
                      </Dropdown>
                    </p>
                    <p class="p-mt-1" style="text-align: left">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <Calendar
                          id="range"
                          v-model="data.date_search"
                          class="p-mr-1"
                          selectionMode="range"
                          dateFormat="yy/mm/dd"
                          :manualInput="false"
                          :showIcon="true"
                          :placeholder="
                            i18n.$t('AttendanceRecords').AttendanceRecordsTable
                              .PunchDate.Show
                          "
                          :showButtonBar="true"
                        />
                      </span>
                    </p>
                  </AccordionTab>
                </Accordion>

                <div class="p-col-12 p-d-flex p-jc-end">
                  <Button
                    icon="pi pi-book"
                    class="p-button-info"
                    label="Excel"
                    @click="Excel"
                  />
                </div>
              </div>
            </template>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .EmployerID.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .EmployerID.Show
              "
            ></Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .EmployerEnglishName.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .EmployerEnglishName.Show
              "
            ></Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .ShiftDate.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .ShiftDate.Show
              "
            >
              <template #body="slotProps"
                >{{
                  // dateFormate(slotProps.data.punch_date, "YYYY-MM-DD")
                  slotProps.data.shift_date.split("T")[0]
                }}
              </template>
            </Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .ShiftStartTime.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .ShiftStartTime.Show
              "
            >
              <template #body="slotProps"
                >{{
                  // dateFormate(slotProps.data.punch_date, "YYYY-MM-DD")
                  slotProps.data.shift_start_time
                    .split("T")[1]
                    .replace(":00Z", "")
                }}
              </template>
            </Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .ShiftEndTime.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .ShiftEndTime.Show
              "
            >
              <template #body="slotProps"
                >{{
                  // dateFormate(slotProps.data.punch_date, "YYYY-MM-DD")
                  slotProps.data.shift_end_time
                    .split("T")[1]
                    .replace(":00Z", "")
                }}
              </template>
            </Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .AttendanceRecordsPunchTime.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .AttendanceRecordsPunchTime.Show
              "
            >
              <template #body="slotProps">
                {{
                  // dateFormate(slotProps.data.punch_date, "YYYY-MM-DD")
                  slotProps.data.attendance_records_punch_time.split("T")[0] +
                  " " +
                  slotProps.data.attendance_records_punch_time
                    .split("T")[1]
                    .replace("+08:00", "")
                }}
              </template>
            </Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .AttendanceRecordsAfterPunchTime.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .AttendanceRecordsAfterPunchTime.Show
              "
            >
              <template #body="slotProps">
                {{
                  // dateFormate(slotProps.data.punch_date, "YYYY-MM-DD")
                  slotProps.data.attendance_records_after_punch_time.split(
                    "T"
                  )[0] +
                  " " +
                  slotProps.data.attendance_records_after_punch_time
                    .split("T")[1]
                    .replace("+08:00", "")
                }}
              </template>
            </Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .CheckInStatus.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .CheckInStatus.Show
              "
            >
              <template #body="slotProps"
                >{{
                  // dateFormate(slotProps.data.punch_date, "YYYY-MM-DD")
                  slotProps.data.check_in_status == 1
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .CheckInType.NoClockAtWorkCheckInStatus
                    : slotProps.data.check_in_status == 2
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .CheckInType.DelayCheckInStatus
                    : slotProps.data.check_in_status == 3
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .CheckInType.NormalCheckInStatus
                    : i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .CheckInType.Unknow
                }}
              </template>
            </Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .CheckOutStatus.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .CheckOutStatus.Show
              "
            >
              <template #body="slotProps"
                >{{
                  // dateFormate(slotProps.data.punch_date, "YYYY-MM-DD")
                  slotProps.data.check_out_status == 1
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .CheckOutType.NoClockAtWorkCheckOutStatus
                    : slotProps.data.check_out_status == 2
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .CheckOutType.DelayCheckOutStatus
                    : slotProps.data.check_out_status == 3
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .CheckOutType.NormalCheckOutStatus
                    : i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .CheckOutType.Unknow
                }}
              </template>
            </Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .NormalWorkHours.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .NormalWorkHours.Show
              "
            ></Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .NormalWorkHoursCheck.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .NormalWorkHoursCheck.Show
              "
            >
              <template #body="slotProps"
                >{{
                  // dateFormate(slotProps.data.punch_date, "YYYY-MM-DD")
                  slotProps.data.determination_of_normal_working_hours_status ==
                  1
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .NormalWorkHoursCheckType
                        .AbnormalCheckInDeterminationOfNormalWorkingHoursStatus
                    : slotProps.data
                        .determination_of_normal_working_hours_status == 2
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .NormalWorkHoursCheckType
                        .LateAndLeaveEarlyDeterminationOfNormalWorkingHoursStatus
                    : slotProps.data
                        .determination_of_normal_working_hours_status == 3
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .NormalWorkHoursCheckType
                        .LeaveEarlyAndWorkNormallyDeterminationOfNormalWorkingHoursStatus
                    : slotProps.data
                        .determination_of_normal_working_hours_status == 4
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .NormalWorkHoursCheckType
                        .LateForGetOffWorkDeterminationOfNormalWorkingHoursStatus
                    : slotProps.data
                        .determination_of_normal_working_hours_status == 5
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .NormalWorkHoursCheckType
                        .OvertimeTimeDeterminationOfNormalWorkingHoursStatus
                    : slotProps.data
                        .determination_of_normal_working_hours_status == 6
                    ? i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .NormalWorkHoursCheckType
                        .NormalDeterminationOfNormalWorkingHoursStatus
                    : i18n.$t("AttendanceRecords").AttendanceRecordsReportTable
                        .NormalWorkHoursCheckType.Unknow
                }}
              </template>
            </Column>
            <Column
              style="width: 120px; overflow: auto"
              class="p-col-2"
              :field="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .OvertimeWorkHours.Field
              "
              :header="
                i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
                  .OvertimeWorkHours.Show
              "
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>

  <DataTable
    class="p-d-none"
    :value="data.excel.data"
    ref="dt"
    responsiveLayout="scroll"
  >
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.EmployerID
          .Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.EmployerID
          .Show
      "
    ></Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .EmployerEnglishName.Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .EmployerEnglishName.Show
      "
    ></Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.ShiftDate
          .Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.ShiftDate.Show
      "
    >
    </Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.ShiftStartTime
          .Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.ShiftStartTime
          .Show
      "
    >
    </Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.ShiftEndTime
          .Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.ShiftEndTime
          .Show
      "
    >
    </Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .AttendanceRecordsPunchTime.Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .AttendanceRecordsPunchTime.Show
      "
    >
    </Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .AttendanceRecordsAfterPunchTime.Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .AttendanceRecordsAfterPunchTime.Show
      "
    >
    </Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.CheckInStatus
          .Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.CheckInStatus
          .Show
      "
    >
    </Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.CheckOutStatus
          .Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable.CheckOutStatus
          .Show
      "
    >
    </Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .NormalWorkHours.Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .NormalWorkHours.Show
      "
    ></Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .NormalWorkHoursCheck.Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .NormalWorkHoursCheck.Show
      "
    >
    </Column>
    <Column
      style="width: 120px; overflow: auto"
      class="p-col-2"
      :field="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .OvertimeWorkHours.Field
      "
      :header="
        i18n.$t('AttendanceRecords').AttendanceRecordsReportTable
          .OvertimeWorkHours.Show
      "
    ></Column>
  </DataTable>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
