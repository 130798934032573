<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import { useStore } from "vuex";
import { dateFormate } from "../tool/formate";

const store = useStore();
// const i18n = useI18n();
// const toast = useToast();
const data = reactive({
  date: new Date(),
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
</script>

<template>
  <div
    :key="index"
    v-for="(water, index) in store.state.water"
    style="
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      width: 910px;
      height: 90vh;
      page-break-after: always;
    "
  >
    <div
      style="
        margin-top: 10px;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        height: 145px;
      "
    >
      <div
        style="
          width: 40%;
          text-align: center;
          border: 1px solid black;
          height: 30px;
          padding-top: 5px;
          padding-bottom: 5px;
          align-self: stretch;
        "
      >
        INKAMPANE(COMPANY)
      </div>
      <div
        style="
          width: 19%;
          text-align: center;
          border: 1px solid black;
          height: 30px;
          padding-top: 5px;
          padding-bottom: 5px;
          align-self: stretch;
        "
      >
        LUSUKU(PERIOD)
      </div>
      <div
        style="
          width: 40%;
          text-align: center;
          border: 1px solid black;
          height: 30px;
          padding-top: 5px;
          padding-bottom: 5px;
          align-self: stretch;
        "
      >
        {{ water.recipient_employer_english_name }}
      </div>
      <!-- <div style="width: 100%; text-align: center"> -->
      <div
        style="
          width: 40%;
          text-align: center;
          border: 1px solid black;
          height: 30px;
          padding-top: 5px;
          padding-bottom: 5px;
          align-self: stretch;
        "
      >
        {{ water.organisation_name }}
      </div>
      <div
        style="
          width: 19%;
          text-align: center;
          border: 1px solid black;
          height: 30px;
          padding-top: 5px;
          padding-bottom: 5px;
          align-self: stretch;
        "
      >
        {{ water.show_date }}
      </div>
      <div
        style="
          width: 40%;
          text-align: center;
          border: 1px solid black;
          height: 30px;
          padding-top: 5px;
          padding-bottom: 5px;
          align-self: stretch;
        "
      >
        ADDITIONAL DETAILS
      </div>
      <div
        style="
          font-size: 10px;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 50px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        INTSENGO<br />(COST)
      </div>
      <div
        style="
          font-size: 10px;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 50px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        INOMBOLO<br />(EMP.NO)
      </div>
      <div
        style="
          font-size: 10px;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 50px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        UMSEBENTI<br />(DESIGNATION)
      </div>
      <div
        style="
          font-size: 10px;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 50px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        LIHOLO<br />NGELIHORA<br />(HRLY RATE)
      </div>
      <div
        style="
          font-size: 10px;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 50px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        USEBENTELA<br />KUPHI<br />(PAY POINT)
      </div>
      <div
        style="
          font-size: 10px;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 50px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        BANTFWAN<br />(CHILDREN)
      </div>
      <div
        style="
          font-size: 10px;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 50px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        BULILI<br />(SEX)
      </div>
      <div
        style="
          font-size: 10px;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 50px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        SIGABA<br />(STATUS)
      </div>
      <div
        style="
          font-size: 14px !important;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 35px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      ></div>
      <div
        style="
          font-size: 14px !important;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 35px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        {{ water.recipient_employer_name }}
      </div>
      <div
        style="
          font-size: 14px !important;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 35px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        <span v-if="store.state.show_position">
          <span
            :key="position_index"
            v-for="(position, position_index) in water.positions"
          >
            {{ position.name }}
          </span>
        </span>
      </div>
      <div
        style="
          font-size: 14px !important;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 35px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        {{ water.recipient_employer_salary }}
      </div>
      <div
        style="
          font-size: 14px !important;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 35px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        <span
          :key="department_index"
          v-for="(
            department, department_index
          ) in water.recipient_employer_departments"
        >
          {{ department.name }}
        </span>
      </div>
      <div
        style="
          font-size: 14px !important;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 35px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      ></div>
      <div
        style="
          font-size: 14px !important;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 35px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      >
        {{ water.recipient_employer_sex }}
      </div>
      <div
        style="
          font-size: 14px !important;
          width: 12.2%;
          text-align: center;
          border: 1px solid black;
          height: 35px;
          padding-top: 2px;
          padding-bottom: 2px;
          align-self: stretch;
        "
      ></div>
      <div
        style="
          font-size: 15px;
          width: 49.5%;
          text-align: center;
          border: 1px solid black;
          height: 16px;
          padding-top: 4px;
          padding-bottom: 6px;
          align-self: stretch;
        "
      >
        LOKUTFOLAKO(EARNINGS)
      </div>
      <div
        style="
          font-size: 15px;
          width: 49.5%;
          text-align: center;
          border: 1px solid black;
          height: 16px;
          padding-top: 4px;
          padding-bottom: 6px;
          align-self: stretch;
        "
      >
        LOKWEPHULIWE(DEDUCTIONS)
      </div>

      <div
        style="
          font-size: 14px;
          width: 16.5%;
          text-align: center;
          border-bottom: 1px solid black;
          border-left: 1px solid black;
          height: 16px;
          padding-top: 6px;
          padding-bottom: 8px;
          align-self: stretch;
        "
      >
        INCHAZELO(DESCR)
      </div>
      <div
        style="
          font-size: 14px;
          width: 16.5%;
          text-align: center;
          border-bottom: 1px solid black;
          height: 16px;
          padding-top: 6px;
          padding-bottom: 8px;
          align-self: stretch;
        "
      >
        LINAI(QTY)
      </div>
      <div
        style="
          font-size: 14px;
          width: 16.5%;
          text-align: center;
          border-bottom: 1px solid black;
          border-right: 1px solid black;
          height: 16px;
          padding-top: 6px;
          padding-bottom: 8px;
          align-self: stretch;
        "
      >
        IMALI(AMT)
      </div>

      <div
        style="
          font-size: 14px;
          width: 16.5%;
          text-align: center;
          border-bottom: 1px solid black;
          border-left: 1px solid black;
          height: 16px;
          padding-top: 6px;
          padding-bottom: 8px;
          align-self: stretch;
        "
      >
        INCHAZELO(DESCR)
      </div>
      <div
        style="
          font-size: 14px;
          width: 16.5%;
          text-align: center;
          border-bottom: 1px solid black;
          height: 16px;
          padding-top: 6px;
          padding-bottom: 8px;
          align-self: stretch;
        "
      >
        IMALI(AMT)
      </div>
      <div
        style="
          font-size: 14px;
          width: 16.5%;
          text-align: center;
          border-bottom: 1px solid black;
          border-right: 1px solid black;
          height: 16px;
          padding-top: 6px;
          padding-bottom: 8px;
          align-self: stretch;
        "
      >
        LESELE(BAL)
      </div>

      <div
        style="
          font-size: 10px;
          width: 49.5%;
          display: flex;
          flex-wrap: wrap;
          text-align: center;
          border-left: 1px solid black;
          border-right: 1px solid black;
          border-bottom: 1px solid black;
          padding-bottom: 10px;
        "
      >
        <div
          :key="salary_positive_item_index"
          v-for="(
            salary_positive_item, salary_positive_item_index
          ) in water.salary_positive"
          style="width: 100%; display: flex; flex-wrap: wrap"
        >
          <div
            style="
              font-size: 14px !important;
              width: 33.3%;
              text-align: center;
              padding-top: 1px;
              padding-bottom: 1px;
              align-self: stretch;
            "
          >
            {{ salary_positive_item.salary_item_name }}
          </div>
          <div
            style="
              font-size: 14px !important;
              width: 33.3%;
              text-align: center;
              padding-top: 1px;
              padding-bottom: 1px;
              align-self: stretch;
            "
          >
            {{ salary_positive_item.hours }}
            <!-- {{
              salary_positive_item.salary_item_name == "Overtime 2.0" ||
              salary_positive_item.salary_item_name == "Allowance" ||
              salary_positive_item.salary_item_name ==
                "Sick Leave (Imali Yekugula)" ||
              salary_positive_item.salary_item_name == "Maternity Leave payment"
                ? salary_positive_item.hours
                : ""
            }} -->
          </div>
          <div
            style="
              font-size: 14px !important;
              width: 33.3%;
              text-align: center;
              padding-top: 1px;
              padding-bottom: 1px;
              align-self: stretch;
            "
          >
            {{ salary_positive_item.dollar }}
          </div>
        </div>
      </div>
      <div
        style="
          font-size: 10px;
          width: 49.5%;
          display: flex;
          flex-wrap: wrap;
          text-align: center;
          border-left: 1px solid black;
          border-right: 1px solid black;
          border-bottom: 1px solid black;
          padding-bottom: 10px;
        "
      >
        <div
          :key="salary_negative_item_index"
          v-for="(
            salary_negative_item, salary_negative_item_index
          ) in water.salary_negative"
          style="width: 100%; display: flex; flex-wrap: wrap"
        >
          <div
            style="
              font-size: 14px !important;
              width: 33.3%;
              text-align: center;
              padding-top: 1px;
              padding-bottom: 1px;
              align-self: stretch;
            "
          >
            {{ salary_negative_item.salary_item_name }}
          </div>
          <div
            style="
              font-size: 14px !important;
              width: 33.3%;
              text-align: center;
              padding-top: 1px;
              padding-bottom: 1px;
              align-self: stretch;
            "
          >
            {{ salary_negative_item.dollar }}
            <!-- {{ salary_negative_item.hours }} -->
            <!-- {{
              salary_negative_item.salary_item_name == "Overtime 2.0" ||
              salary_negative_item.salary_item_name == "Allowance" ||
              salary_negative_item.salary_item_name ==
                "Sick Leave (Imali Yekugula)" ||
              salary_negative_item.salary_item_name == "Maternity Leave payment"
                ? salary_negative_item.hours
                : ""
            }} -->
          </div>
          <div
            style="
              font-size: 14px !important;
              width: 33.3%;
              text-align: center;
              padding-top: 1px;
              padding-bottom: 1px;
              align-self: stretch;
            "
          >
            0.00
            <!-- {{ salary_negative_item.dollar }} -->
          </div>
        </div>
      </div>

      <div
        style="
          font-size: 14px;
          width: 48.9%;
          display: flex;
          flex-wrap: wrap;
          text-align: left;
          padding-left: 5px;
          border-left: 1px solid black;
          border-right: 1px solid black;
          border-bottom: 1px solid black;
          padding-bottom: 10px;
        "
      >
        LETFOLAKELE SEYIYONKHE(GROSS PAY)
        {{ (Math.round(water.total_positive * 100) / 100 - 0).toFixed(2) }}
      </div>
      <div
        style="
          font-size: 14px;
          width: 49%;
          display: flex;
          flex-wrap: wrap;
          text-align: left;
          padding-left: 5px;
          border-left: 1px solid black;
          border-right: 1px solid black;
          border-bottom: 1px solid black;
          padding-bottom: 10px;
        "
      >
        LEPHULIWE SEYIYONKHE(DEDUCTIONS) {{ water.total_negative.toFixed(2) }}
      </div>
      <div
        style="
          font-size: 14px;
          width: 48.9%;
          display: flex;
          flex-wrap: wrap;
          text-align: left;
          padding-left: 5px;
          border-left: 1px solid black;
          border-right: 1px solid black;
          border-bottom: 1px solid black;
          padding-bottom: 10px;
        "
      >
        EMALANGA EKUPHUMULA LABHEKEKILE(LEAVE DAYS) 0.00
      </div>
      <div
        style="
          font-size: 14px;
          width: 49%;
          display: flex;
          flex-wrap: wrap;
          text-align: left;
          padding-left: 5px;
          border-left: 1px solid black;
          border-right: 1px solid black;
          border-bottom: 1px solid black;
          padding-bottom: 10px;
        "
      >
        LETFOLAKELE MBAMBA(NET PAY)
        {{
          (
            Math.round((water.total_positive - water.total_negative) * 100) /
            100
          ).toFixed(2)
        }}
      </div>
      <div style="width: 100%; text-align: center">
        <h4>This is system generated payslip</h4>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
