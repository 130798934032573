<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import Toast from "primevue/toast";
import InputNumber from "primevue/inputNumber";
import InputText from "primevue/inputText";
import Textarea from "primevue/textarea";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Company from "../../api/salary/self/company";
import Factory from "../../api/salary/self/factory";
import AccountItem from "../../api/salary/self/account_item";
import Department from "../../api/salary/self/department";
import PaymentItem from "../../api/salary/self/payment_item";
import Payment from "../../api/salary/self/payment";
import PaymentContent from "../../api/salary/self/payment_content";
import { printHTML } from "../../tool/print";
import PrintSellment from "../../components/PrintSellment.vue";
import africa_logo from "../../assets/africa_logo.jpg";
const Application = ref(null);
const store = useStore();
const i18n = useI18n();
const toast = useToast();

let payment_itemAPI = new PaymentItem();
let paymentAPI = new Payment();
let payment_contentAPI = new PaymentContent();

let companyAPI = new Company();
let factoryAPI = new Factory();
let account_itemAPI = new AccountItem();
let departmentAPI = new Department();
// const i18n = useI18n();
// const toast = useToast();
const data = reactive({
  selectDepartmentList: [],
  DepartmentSearch: "",
  selectPaymentItemList: [],
  selectPaymentTypeList: [
    {
      id: 1,
      name: i18n.$t("Payment").PaymentType.Purchase,
    },
    {
      id: 2,
      name: i18n.$t("Payment").PaymentType.PettyCash,
    },
  ],
  selectDeliveryMethodList: [
    {
      id: 1,
      name: i18n.$t("Payment").DeliveryMethod.Air,
    },
    {
      id: 2,
      name: i18n.$t("Payment").DeliveryMethod.Sea,
    },
    {
      id: 3,
      name: i18n.$t("Payment").DeliveryMethod.Land,
    },
  ],
  selectPaymentMethodList: [
    {
      id: 1,
      name: i18n.$t("Payment").PaymentMethod.Cash,
    },
    {
      id: 2,
      name: i18n.$t("Payment").PaymentMethod.Transfer,
    },
    {
      id: 3,
      name: i18n.$t("Payment").PaymentMethod.Card,
    },
  ],
  selectAccountItemList: [],
  selectCompanyList: [],
  selectFactoryList: [],
  PaymentItemSearch: "",
  CompanySearch: "",
  FactorySearch: "",
  AccountItemSearch: "",
  PaymentTypeSearch: "",
  DeliveryMethodSearch: "",
  payment_contents: [],
  PDF_Name: "",
  add_payment: {
    id: 0,
    company_id: 0,
    delivery_method: "",
    payment_type: 0,
    factory_id: 0,
    payment_method: "",
    pdf_file: "",
    remark: "",
    description: "",
  },
  add_payment_content: {
    payment_id: 0,
    payment_item_id: 0,
    spec: "",
    dollar: 0,
    total_count: 0,
    calculation_unit: "",
    total_dollar: 0,
    invoice_number: "",
    remark: "",
    account_item_id: 0,
    department_id: 0,
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  getCompanySelectList();
  getFactorySelectList();
  getPaymentItemSelectList();
  getAccountItemSelectList();
  getDepartmentSelectList();
});
function SavePayment() {
  if (data.add_payment.id == 0)
    paymentAPI
      .addPayment(
        data.add_payment.company_id,
        data.add_payment.delivery_method,
        data.add_payment.payment_method,
        data.add_payment.factory_id,
        3,
        11,
        data.add_payment.pdf_file,
        data.add_payment.remark,
        data.add_payment.description
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.add_payment.id = res.data.data.id;
          getAccountItemSelectList();
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Payment").Success,
            life: 3000,
          });
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
}
function getPaymentContentList() {
  payment_contentAPI
    .getPaymentContentList(0, data.add_payment.id, 0, "", 1, 999)
    .then((res) => {
      if (res.data.error_code == 0) {
        data.payment_contents = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function SavePaymentContent() {
  payment_contentAPI
    .addPaymentContent(
      data.add_payment.id,
      data.add_payment_content.payment_item_id,
      data.add_payment_content.spec,
      String(data.add_payment_content.dollar),
      String(data.add_payment_content.total_count),
      data.add_payment_content.calculation_unit,
      data.add_payment_content.invoice_number,
      data.add_payment_content.remark,
      data.add_payment_content.account_item_id,
      data.add_payment_content.department_id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        //   data.payment_contents=res.data.
        data.PaymentItemSearch = "";
        data.AccountItemSearch = "";
        data.DepartmentSearch = "";
        data.add_payment_content = {
          payment_id: 0,
          payment_item_id: 0,
          spec: "",
          dollar: 0,
          total_count: 0,
          calculation_unit: "",
          total_dollar: 0,
          invoice_number: "",
          remark: "",
          account_item_id: 0,
          department_id: 0,
        };
        getPaymentContentList();
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Payment").Success,
          life: 3000,
        });
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function Clear() {
  data.PDF_Name = "";
  data.payment_contents = [];
  data.PaymentItemSearch = "";
  data.CompanySearch = "";
  data.FactorySearch = "";
  data.PaymentTypeSearch = "";
  data.DeliveryMethodSearch = "";
  data.PaymentMethodSearch = "";
  data.selectAccountItemList = [];
  data.add_payment = {
    id: 0,
    company_id: 0,
    delivery_method: "",
    payment_type: 0,
    factory_id: 0,
    payment_method: "",
    pdf_file: "",
    remark: "",
    description: "",
  };
}
function getPaymentItemSelectList() {
  payment_itemAPI.getPaymentItemSelectList().then((res) => {
    if (res.data.error_code == 0) {
      data.selectPaymentItemList = res.data.data.datas;
      //   console.log(data.selectPaymentItemList);
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getCompanySelectList() {
  companyAPI.getCompanySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCompanyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getFactorySelectList() {
  factoryAPI.getFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectFactoryList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getAccountItemSelectList() {
  account_itemAPI
    .getAccountItemSelectList("", data.add_payment.company_id)
    .then((res) => {
      if (res.data.error_code == 0) {
        data.selectAccountItemList = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function getDepartmentSelectList() {
  departmentAPI.getDepartmentSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectDepartmentList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function OpenPdf(e) {
  console.log(e.target.files[0]);
  //上传文件后读取excel文件内容
  let file = e.target.files[0];
  const types = ["pdf"];
  const arr = file.name.split(".");
  data.PDF_Name = file.name;
  //判断文件是否为excel文件
  if (!types.find((item) => item === arr[arr.length - 1])) {
    alert("choose (pdf)");
    return;
  }
  let reader = new FileReader();
  //启动函数
  reader.readAsDataURL(file);
  reader.onload = async function () {
    // console.log(reader.result);
    data.add_payment.pdf_file = await reader.result.replace(
      "data:application/pdf;base64,",
      ""
    );
    if (data.add_payment.id != 0) {
      paymentAPI
        .EditdPaymentPDF(data.add_payment.id, data.add_payment.pdf_file)
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("Payment").Success,
              life: 3000,
            });
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
    }
  };
}
function SaveDelete(payment_content) {
  payment_contentAPI.deletePaymentContent([payment_content.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Payment").DeleteSuccess,
        life: 3000,
      });
      getPaymentContentList();
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
watch(
  () => data.CompanySearch,
  () => {
    data.add_payment.company_id =
      data.CompanySearch == null ? "" : data.CompanySearch.id;
  }
);
watch(
  () => data.FactorySearch,
  () => {
    data.add_payment.factory_id =
      data.FactorySearch == null ? "" : data.FactorySearch.id;
  }
);
watch(
  () => data.PaymentTypeSearch,
  () => {
    data.add_payment.payment_type =
      data.PaymentTypeSearch == null ? "" : data.PaymentTypeSearch.id;
  }
);
watch(
  () => data.PaymentMethodSearch,
  () => {
    data.add_payment.payment_method =
      data.PaymentMethodSearch == null ? "" : data.PaymentMethodSearch.name;
  }
);
watch(
  () => data.DeliveryMethodSearch,
  () => {
    data.add_payment.delivery_method =
      data.DeliveryMethodSearch == null ? "" : data.DeliveryMethodSearch.name;
  }
);
watch(
  () => data.PaymentItemSearch,
  () => {
    data.add_payment_content.payment_item_id =
      data.PaymentItemSearch == null ? "" : data.PaymentItemSearch.id;
  }
);
watch(
  () => data.AccountItemSearch,
  () => {
    data.add_payment_content.account_item_id =
      data.AccountItemSearch == null ? "" : data.AccountItemSearch.id;
  }
);
watch(
  () => data.DepartmentSearch,
  () => {
    data.add_payment_content.department_id =
      data.DepartmentSearch == null ? "" : data.DepartmentSearch.id;
  }
);
async function PrintPayment(print_application) {
  for (let i = 0; i < data.selectCompanyList.length; i++) {
    if (data.selectCompanyList[i].id == data.add_payment.company_id) {
      data.add_payment.company_name = data.selectCompanyList[i].name;
      break;
    }
  }
  for (let i = 0; i < data.selectFactoryList.length; i++) {
    if (data.selectFactoryList[i].id == data.add_payment.factory_id) {
      data.add_payment.factory_name = data.selectFactoryList[i].name;
      break;
    }
  }

  data.add_payment.department = [];
  data.add_payment.created_at = new Date();
  data.add_payment.total_dollar = 0;
  for (let i = 0; i < data.payment_contents.length; i++) {
    if (
      data.add_payment.department.indexOf(
        data.payment_contents[i].department_name
      ) == -1
    ) {
      data.add_payment.department.push(
        data.payment_contents[i].department_name
      );
    }
    data.add_payment.total_dollar =
      data.payment_contents[i].payment_item_taxed == 1
        ? parseFloat(data.add_payment.total_dollar) +
          parseFloat(data.payment_contents[i].total_dollar) +
          parseFloat(data.payment_contents[i].total_dollar) * 0.15
        : parseFloat(data.add_payment.total_dollar) +
          parseFloat(data.payment_contents[i].total_dollar);
  }

  await store.commit("set_payment", data.add_payment);
  await store.commit("set_payment_content", data.payment_contents);
  printHTML(print_application);
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 100px">
      <div style="border-bottom: 0.1rem solid">
        <p style="text-align: left">
          <span class="p-input-icon-left p-mr-1">
            <Dropdown
              :disabled="data.add_payment.id != 0"
              v-model="data.CompanySearch"
              :options="data.selectCompanyList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Payment').PaymentTable.Company.Show"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </span>
          <span class="p-input-icon-left p-mr-1">
            <Dropdown
              :disabled="data.add_payment.id != 0"
              v-model="data.FactorySearch"
              :options="data.selectFactoryList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Payment').PaymentTable.Factory.Show"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </span>
          <span style="float: right; margin-top: 10px">
            {{ i18n.$t("Payment").PaymentTable.ID.Show }}:
            {{
              data.add_payment.id == 0
                ? i18n.$t("Payment").Unkonw
                : data.add_payment.id
            }}
          </span>
        </p>
        <p style="text-align: left; height: 37.2px" class="p-mb-2">
          <span style="float: right; margin-top: 0px">
            <Dropdown
              :disabled="data.add_payment.id != 0"
              v-model="data.PaymentMethodSearch"
              :options="data.selectPaymentMethodList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Payment').PaymentTable.SellmentMethod.Show"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </span>
        </p>
      </div>
    </div>
    <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
      <div class="p-col-8">
        {{ i18n.$t("Payment").PaymentTable.SellDescription.Show }}
      </div>
      <div class="p-col-4">
        {{ i18n.$t("Payment").PaymentTable.Remark.Show }}
      </div>
    </div>
    <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
      <div class="p-col-8">
        <Textarea
          class="p-ml-1"
          id="description"
          v-model="data.add_payment.description"
          required="true"
          rows="2"
          style="width: 95%"
          :disabled="data.add_payment.id != 0"
        />
      </div>
      <div class="p-col-4">
        <Textarea
          class="p-mr-1"
          id="description"
          v-model="data.add_payment.remark"
          required="true"
          rows="2"
          style="width: 95%"
          :disabled="data.add_payment.id != 0"
        />
      </div>
    </div>
    <div>
      <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
        <div
          class="p-col-2"
          style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
        >
          {{ i18n.$t("Payment").PaymentContentTable.PaymentItem.Show }}
        </div>
        <div
          class="p-col-2"
          style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
        >
          {{ i18n.$t("Payment").PaymentContentTable.DepartmentId.Show }}
        </div>
        <div
          class="p-col-2"
          style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
        >
          {{ i18n.$t("Payment").PaymentContentTable.AccountItemId.Show }}
        </div>
        <div
          class="p-col-1"
          style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
        >
          {{ i18n.$t("Payment").PaymentContentTable.Spec.Show }}
        </div>
        <div
          class="p-col-1"
          style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
        >
          {{ i18n.$t("Payment").PaymentContentTable.TotalCount.Show }}
        </div>
        <div
          class="p-col-1"
          style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
        >
          {{ i18n.$t("Payment").PaymentContentTable.Dollar.Show }}
        </div>
        <div
          class="p-col-1"
          style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
        >
          {{ i18n.$t("Payment").PaymentContentTable.InvoiceNumber.Show }}
        </div>
        <div
          class="p-col-1"
          style="overflow-y: scroll; overflow-x: hidden; white-space: nowrap"
        >
          {{ i18n.$t("Payment").PaymentContentTable.CalculationUnit.Show }}
        </div>
        <div class="p-col-1"></div>
      </div>
      <div
        style="
          height: calc(100vh - 470px);
          overflow-y: scroll;
          overflow-x: hidden;
        "
      >
        <div
          :key="index"
          v-for="(item, index) in data.payment_contents"
          class="p-grid p-mt-1"
          style="border-bottom: 1px solid"
        >
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
              justify-content: center;
            "
            class="p-col-2"
          >
            {{
              item.payment_item_chinese_name +
              "(" +
              item.payment_item_english_name +
              ")"
            }}
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
              justify-content: center;
            "
            class="p-col-2"
          >
            {{ item.department_name }}
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
              justify-content: center;
            "
            class="p-col-2"
          >
            {{ item.account_item_name }}
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
              justify-content: center;
            "
            class="p-col-1"
          >
            {{ item.spec }}
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
              justify-content: center;
            "
            class="p-col-1"
          >
            {{ item.total_count }}
            <!-- <InputNumber
              v-model="item.total_count"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            /> -->
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
              justify-content: center;
            "
            class="p-col-1"
          >
            {{ item.dollar }}
            <!-- <InputNumber
              v-model="item.dollar"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            /> -->
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
              justify-content: center;
            "
            class="p-col-1"
          >
            {{ item.invoice_number }}
            <!-- <InputNumber
              v-model="item.dollar"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            /> -->
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
              justify-content: center;
            "
            class="p-col-1"
          >
            {{ item.calculation_unit }}
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              overflow: auto;
              justify-content: center;
            "
            class="p-col-1"
          >
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-sm"
              @click="SaveDelete(item)"
            />
          </div>
        </div>
      </div>
      <div class="p-grid p-mt-1" style="border-top: 1px solid">
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-2"
        >
          <Dropdown
            :disabled="data.add_payment.id == 0"
            v-model="data.PaymentItemSearch"
            :options="data.selectPaymentItemList"
            :filter="true"
            :optionLabel="
              store.state.lang == 'cn' ? 'chinese_name' : 'english_name'
            "
            :placeholder="
              i18n.$t('Payment').PaymentContentTable.PaymentItem.Show
            "
            style="width: 100%"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div v-if="store.state.lang == 'cn'">
                  {{ slotProps.value.chinese_name }}
                  <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                </div>
                <div v-else>
                  {{ slotProps.value.english_name }}
                  <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                </div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div v-if="store.state.lang == 'cn'">
                  {{ slotProps.option.chinese_name }}
                  <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                </div>
                <div v-else>
                  {{ slotProps.option.english_name }}
                  <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-2"
        >
          <Dropdown
            :disabled="data.add_payment.id == 0"
            v-model="data.DepartmentSearch"
            :options="data.selectDepartmentList"
            :filter="true"
            optionLabel="name"
            :placeholder="
              i18n.$t('Payment').PaymentContentTable.DepartmentId.Show
            "
            style="width: 100%"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>
                  {{ slotProps.value.name }}
                  <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                </div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>
                  {{ slotProps.option.name }}
                  <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-2"
        >
          <Dropdown
            :disabled="data.add_payment.id == 0"
            v-model="data.AccountItemSearch"
            :options="data.selectAccountItemList"
            :filter="true"
            optionLabel="name"
            :placeholder="
              i18n.$t('Payment').PaymentContentTable.AccountItemId.Show
            "
            style="width: 100%"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>
                  {{ slotProps.value.name }}
                  <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                </div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>
                  {{ slotProps.option.name }}
                  <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-1"
        >
          <InputText
            :disabled="data.add_payment.id == 0"
            style="width: 100%"
            v-model="data.add_payment_content.spec"
            required="true"
          />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-1"
        >
          <InputNumber
            :disabled="data.add_payment.id == 0"
            style="width: 100%"
            v-model="data.add_payment_content.total_count"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="5"
            required="true"
          />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-1"
        >
          <InputNumber
            :disabled="data.add_payment.id == 0"
            style="width: 100%"
            v-model="data.add_payment_content.dollar"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="5"
            required="true"
          />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-1"
        >
          <InputText
            :disabled="data.add_payment.id == 0"
            style="width: 100%"
            v-model="data.add_payment_content.invoice_number"
            required="true"
          />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-1"
        >
          <InputText
            :disabled="data.add_payment.id == 0"
            style="width: 100%"
            v-model="data.add_payment_content.calculation_unit"
            required="true"
          />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: auto;
          "
          class="p-col-1"
        >
          <Button
            icon="pi pi-plus-circle"
            class="p-button-rounded p-button-info p-button-sm"
            :disabled="data.add_payment.id == 0"
            @click="SavePaymentContent"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <label
      style="float: left"
      class="p-button p-component p-button-danger p-ml-2 p-mt-2"
    >
      <input
        id="upload_img"
        style="display: none"
        type="file"
        @change="OpenPdf"
      />
      <i class="pi pi-save p-mr-1"></i>
      {{ i18n.$t("Payment").File }}
    </label>
    <label
      style="
        float: left;
        align-items: center;
        white-space: nowrap;
        overflow: auto;
        width: 100px;
      "
      class="p-component p-ml-2 p-mt-3"
    >
      {{ data.PDF_Name == "" ? "" : "File:" + data.PDF_Name }}
    </label>
    <!-- <Button
      style="float: left"
      class="p-button-danger p-ml-2 p-mt-2"
      :label="i18n.$t('Payment').File"
      :disabled="data.add_payment.id != 0"
      icon="pi pi-save"
    /> -->
    <Button
      v-if="data.add_payment.id == 0"
      style="float: right"
      class="p-button-info p-mr-2 p-mt-2"
      :label="i18n.$t('Payment').Save"
      @click="SavePayment"
      icon="pi pi-check"
    />
    <Button
      v-if="data.add_payment.id != 0"
      style="float: right; color: white"
      class="p-button-success p-mr-2 p-mt-2"
      :label="i18n.$t('Payment').Clear"
      @click="Clear"
      icon="pi pi-check"
    />
    <Button
      v-if="data.add_payment.id != 0"
      style="float: right"
      class="p-button-help p-mr-2 p-mt-2"
      :label="i18n.$t('Payment').Print"
      @click="PrintPayment(Application)"
      icon="pi pi-print"
    />
  </div>
  <div class="p-d-none">
    <div ref="Application">
      <PrintSellment />
    </div>
  </div>
</template>

<style scoped>
.footer {
  border-top: 0.1rem solid;
  position: fixed;
  height: 55px;
  left: 0;
  bottom: 0;
  width: 100%;
  /* background-color: red;
  color: white; */
  text-align: center;
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
