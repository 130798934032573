import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class PaymentContent {
  getPaymentContentInfo(id) {
    return axios.get(
      `/api/admin/payment-content/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPaymentContentList(
    id,
    payment_id,
    payment_item_id,
    calculation_unit,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/payment-content/list`,
      {
        params: {
          id: id,
          payment_id: payment_id,
          payment_item_id: payment_item_id,
          calculation_unit: calculation_unit,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchPaymentContent(datas) {
    return axios.post(
      `/api/admin/payment-content/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addPaymentContent(
    payment_id,
    payment_item_id,
    spec,
    dollar,
    total_count,
    calculation_unit,
    invoice_number,
    remark,
    account_item_id,
    department_id
  ) {
    return axios.post(
      `/api/admin/payment-content/`,
      {
        payment_id: payment_id,
        payment_item_id: payment_item_id,
        spec: spec,
        dollar: dollar,
        total_count: total_count,
        calculation_unit: calculation_unit,
        invoice_number: invoice_number,
        remark: remark,
        account_item_id: account_item_id,
        department_id: department_id,
      },
      config
    );
  }
  editPaymentContent(
    id,
    payment_id,
    payment_item_id,
    spec,
    dollar,
    total_count,
    calculation_unit,
    invoice_number,
    remark,
    account_item_id
  ) {
    return axios.put(
      `/api/admin/payment-content/`,
      {
        id: id,
        payment_id: payment_id,
        payment_item_id: payment_item_id,
        spec: spec,
        dollar: dollar,
        total_count: total_count,
        calculation_unit: calculation_unit,
        invoice_number: invoice_number,
        remark: remark,
        account_item_id: account_item_id,
      },
      config
    );
  }
  editPaymentContentInvoice(
    id,
    dollar,
    total_count,
    invoice_number,
    account_item_id,
    spec,
    department_id
  ) {
    return axios.put(
      `/api/admin/payment-content/`,
      {
        id: id,
        dollar: dollar,
        total_count: total_count,
        invoice_number: invoice_number,
        account_item_id: account_item_id,
        spec: spec,
        department_id: department_id,
      },
      config
    );
  }
  deletePaymentContent(id) {
    return axios.delete(
      `/api/admin/payment-content/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
