<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import Organisation from "../../api/salary/admin/organisation";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import AdminEmployerDetail from "../../api/salary/admin/employer_detail";
import ManagerEmployerDetail from "../../api/salary/manager/employer_detail";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let organisationAPI = new Organisation();
let userApi = new User();
let admin_employer_detailAPI = new AdminEmployerDetail();
let manager_employer_detailAPI = new ManagerEmployerDetail();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  employer_detailLoading: false,
  totalRecords: 0,
  employer_details: { data: null },
  employer_detail: null,
  OrganisationSearch: "",
  selectOrganisationList: [],
  page: 1,
  page_num: 50,
  selectedemployer_details: null,
  employer_detailFilters: {
    organisation_id: { value: "", matchMode: "contains" },
    status: { value: 2, matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getEmployerDetailList();
  }
  if (store.state.user.role == 1) {
    getOrganisationSelectList();
  }
});

function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getEmployerDetailList() {
  CheckUser();
  if (store.state.user.role == 1) {
    admin_employer_detailAPI
      .getEmployerDetailContractExpire(
        data.employer_detailFilters.organisation_id.value,
        data.employer_detailFilters.status.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.totalRecords = res.data.data.total_count;
          data.employer_details.data = res.data.data.datas;
          data.page = res.data.data.page;
          data.employer_detailLoading = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });

          data.totalRecords = 0;
          data.employer_detailLoading = false;
        }
      });
  } else if (store.state.user.role == 2) {
    manager_employer_detailAPI
      .getEmployerDetailContractExpire(
        data.employer_detailFilters.organisation_id.value,
        data.employer_detailFilters.status.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.totalRecords = res.data.data.total_count;
          data.employer_details.data = res.data.data.datas;
          data.page = res.data.data.page;
          data.employer_detailLoading = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });

          data.totalRecords = 0;
          data.employer_detailLoading = false;
        }
      });
  }
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.employer_detailLoading = true;
    getEmployerDetailList();
  }
);
watch(
  () => data.OrganisationSearch,
  () => {
    data.employer_detailLoading = true;
    data.employer_detailFilters.organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    getEmployerDetailList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.employer_details.data"
      :loading="data.employer_detailLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.employer_detailFilters"
      v-model:selection="data.selectedemployer_details"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Employer").EmployerTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span
                  class="p-input-icon-left p-ml-1"
                  v-if="store.state.user.role == 1"
                >
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.OrganisationSearch"
                    :options="data.selectOrganisationList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Employer').EmployerTable.Organisation.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <!-- <Button
              :label="i18n.$t('employer_detail').employer_detailTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addemployer_detail"
            /> -->
          </div>
        </div>
      </template>
      <Column
        class="p-col-1"
        :field="i18n.$t('Employer').EmployerTable.EmployerID.Field"
        :header="i18n.$t('Employer').EmployerTable.EmployerID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Employer').EmployerTable.Name.Field"
        :header="i18n.$t('Employer').EmployerTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Employer').EmployerTable.EnglishName.Field"
        :header="i18n.$t('Employer').EmployerTable.EnglishName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Employer').EmployerTable.ContractExpireStatus.Field"
        :header="i18n.$t('Employer').EmployerTable.ContractExpireStatus.Show"
      >
        <template #body="slotProps">
          <Button
            disabled
            icon="pi"
            :class="
              slotProps.data.contract_expire_status == 'red'
                ? 'p-button-danger'
                : slotProps.data.contract_expire_status == 'yellow'
                ? 'p-button-warning'
                : 'p-button-success'
            "
          />
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Employer').EmployerTable.ContractImageStatus.Field"
        :header="i18n.$t('Employer').EmployerTable.ContractImageStatus.Show"
      >
        <template #body="slotProps">
          <Button
            disabled
            icon="pi"
            :class="
              slotProps.data.contract_image_status == 'red'
                ? 'p-button-danger'
                : slotProps.data.contract_image_status == 'yellow'
                ? 'p-button-warning'
                : 'p-button-success'
            "
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
