<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import AccountItem from "../../api/salary/admin/account_item";
import Company from "../../api/salary/self/company";
import Dropdown from "primevue/dropdown";
import AccountType from "../../api/salary/self/account_type";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let companyAPI = new Company();
let account_typeAPI = new AccountType();
let userApi = new User();
let account_itemAPI = new AccountItem();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  AccountTypeEditSearch: "",
  CompanyEditSearch: "",
  AccountTypeAddSearch: "",
  CompanyAddSearch: "",
  AccountTypeSearch: "",
  CompanySearch: "",
  selectAccountTypeList: [],
  selectCompanyList: [],
  add_account_item: {
    id: "",
    name: "",
    description: "",
    account_type_id: 0,
    company_id: 0,
  },
  account_itemLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  account_items: { data: null },
  account_item: null,
  page: 1,
  page_num: 10,
  selectedAccountItems: null,
  account_itemFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    account_type_id: { value: "", matchMode: "contains" },
    company_id: { value: 0, matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getAccountItemList();
    getCompanySelectList();
    getAccountTypeSelectList();
  }
});
function getAccountTypeSelectList() {
  account_typeAPI.getAccountTypeSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectAccountTypeList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getCompanySelectList() {
  companyAPI.getCompanySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCompanyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getAccountItemList() {
  CheckUser();
  account_itemAPI
    .getAccountItemList(
      data.account_itemFilters.id.value,
      data.account_itemFilters.name.value,
      data.account_itemFilters.account_type_id.value,
      data.account_itemFilters.company_id.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.account_items.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.account_itemLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.account_itemLoading = false;
      }
    });
}

function saveEditAccountItem() {
  account_itemAPI
    .editAccountItem(
      data.account_item.id,
      data.account_item.name,
      data.account_item.description,
      data.account_item.account_type_id,
      data.account_item.company_id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("AccountItem").EditSuccess,
          life: 3000,
        });

        data.account_itemLoading = true;
        getAccountItemList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddAccountItem() {
  account_itemAPI
    .addAccountItem(
      data.add_account_item.name,
      data.add_account_item.description,
      data.add_account_item.account_type_id,
      data.add_account_item.company_id
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("AccountItem").AddSuccess,
          life: 3000,
        });

        data.account_itemLoading = true;
        getAccountItemList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addAccountItem() {
  data.add_account_item = {
    id: "",
    name: "",
    description: "",
    account_type_id: 0,
    company_id: 0,
  };
  data.AccountTypeAddSearch = "";
  data.CompanyAddSearch = "";
  data.addDialog = true;
}
function editAccountItem(account_item) {
  data.account_item = { ...account_item };
  data.editDialog = true;
  for (let i = 0; i < data.selectCompanyList.length; i++) {
    if (account_item.company_id == data.selectCompanyList[i].id) {
      data.CompanyEditSearch = data.selectCompanyList[i];
    }
  }
  for (let i = 0; i < data.selectAccountTypeList.length; i++) {
    if (account_item.account_type_id == data.selectAccountTypeList[i].id) {
      data.AccountTypeEditSearch = data.selectAccountTypeList[i];
    }
  }
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(account_item) {
  data.account_item = account_item;
  data.deleteDialog = true;
}
function deleteAccountItem() {
  account_itemAPI.deleteAccountItem([data.account_item.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("AccountItem").DeleteSuccess,
        life: 3000,
      });

      data.account_itemLoading = true;
      getAccountItemList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedAccountItem() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedAccountItems.length; i++) {
    selectDelete.push(data.selectedAccountItems[i].id);
  }
  account_itemAPI.deleteAccountItem(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("AccountItem").DeleteSuccess,
        life: 3000,
      });

      data.account_itemLoading = true;
      data.selectedAccountItems = [];
      getAccountItemList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.account_itemLoading = true;
    getAccountItemList();
  }
);
watch(
  () => data.account_itemFilters.name.value,
  () => {
    data.account_itemLoading = true;
    getAccountItemList();
  }
);
watch(
  () => data.account_itemFilters.id.value,
  () => {
    data.account_itemLoading = true;
    getAccountItemList();
  }
);
watch(
  () => data.CompanySearch,
  () => {
    data.account_itemFilters.company_id.value =
      data.CompanySearch == null ? "" : data.CompanySearch.id;
    data.account_itemLoading = true;
    getAccountItemList();
  }
);
watch(
  () => data.AccountTypeSearch,
  () => {
    data.account_itemFilters.account_type_id.value =
      data.AccountTypeSearch == null ? "" : data.AccountTypeSearch.id;
    data.account_itemLoading = true;
    getAccountItemList();
  }
);

watch(
  () => data.CompanyAddSearch,
  () => {
    data.add_account_item.company_id =
      data.CompanyAddSearch == null ? "" : data.CompanyAddSearch.id;
  }
);
watch(
  () => data.AccountTypeAddSearch,
  () => {
    data.add_account_item.account_type_id =
      data.AccountTypeAddSearch == null ? "" : data.AccountTypeAddSearch.id;
  }
);
watch(
  () => data.CompanyEditSearch,
  () => {
    data.account_item.company_id =
      data.CompanyEditSearch == null ? "" : data.CompanyEditSearch.id;
  }
);
watch(
  () => data.AccountTypeEditSearch,
  () => {
    data.account_item.account_type_id =
      data.AccountTypeEditSearch == null ? "" : data.AccountTypeEditSearch.id;
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.account_items.data"
      :loading="data.account_itemLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.account_itemFilters"
      v-model:selection="data.selectedAccountItems"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("AccountItem").AccountItemTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.account_itemFilters[
                        i18n.$t('AccountItem').AccountItemTable.ID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('AccountItem').AccountItemTable.ID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.account_itemFilters[
                        i18n.$t('AccountItem').AccountItemTable.Name.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('AccountItem').AccountItemTable.Name.Show
                    "
                  />
                </span>
              </p>
              <p style="text-align: left">
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.CompanySearch"
                    :options="data.selectCompanyList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Payment').PaymentTable.Company.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.AccountTypeSearch"
                    :options="data.selectAccountTypeList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('AccountItem').AccountItemTable.AccountType.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('AccountItem').AccountItemTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addAccountItem"
            />
            <Button
              :label="i18n.$t('AccountItem').AccountItemTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedAccountItems || !data.selectedAccountItems.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('AccountItem').AccountItemTable.ID.Field"
        :header="i18n.$t('AccountItem').AccountItemTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('AccountItem').AccountItemTable.Name.Field"
        :header="i18n.$t('AccountItem').AccountItemTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('AccountItem').AccountItemTable.Company.Field"
        :header="i18n.$t('AccountItem').AccountItemTable.Company.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('AccountItem').AccountItemTable.AccountType.Field"
        :header="i18n.$t('AccountItem').AccountItemTable.AccountType.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('AccountItem').AccountItemTable.Description.Field"
        :header="i18n.$t('AccountItem').AccountItemTable.Description.Show"
      ></Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editAccountItem(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('AccountItem').AddAccountItem.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("AccountItem").AddAccountItem.Name
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_account_item.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("AccountItem").AccountItemTable.Company.Show
        }}</label>
        <Dropdown
          v-model="data.CompanyAddSearch"
          :options="data.selectCompanyList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Payment').PaymentTable.Company.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("AccountItem").AccountItemTable.AccountType.Show
        }}</label>
        <Dropdown
          v-model="data.AccountTypeAddSearch"
          :options="data.selectAccountTypeList"
          optionLabel="name"
          :filter="true"
          :placeholder="
            i18n.$t('AccountItem').AccountItemTable.AccountType.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("AccountItem").AddAccountItem.Description
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_account_item.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('AccountItem').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('AccountItem').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddAccountItem"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('AccountItem').EditAccountItem.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("AccountItem").EditAccountItem.Name
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.account_item.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("AccountItem").AccountItemTable.Company.Show
        }}</label>
        <Dropdown
          v-model="data.CompanyEditSearch"
          :options="data.selectCompanyList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Payment').PaymentTable.Company.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("AccountItem").AccountItemTable.AccountType.Show
        }}</label>
        <Dropdown
          v-model="data.AccountTypeEditSearch"
          :options="data.selectAccountTypeList"
          optionLabel="name"
          :filter="true"
          :placeholder="
            i18n.$t('AccountItem').AccountItemTable.AccountType.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("AccountItem").EditAccountItem.Description
        }}</label>
        <Textarea
          id="description"
          v-model="data.account_item.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('AccountItem').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('AccountItem').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditAccountItem"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('AccountItem').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.account_item"
          >{{ i18n.$t("AccountItem").Confirm.Content }}
          <b>{{ data.account_item.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('AccountItem').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('AccountItem').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteAccountItem"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('AccountItem').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("AccountItem").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('AccountItem').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('AccountItem').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedAccountItem"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
