import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class EmployerShiftRelation {
  getEmployerShiftRelationList(
    start_date,
    end_date,
    employer_ids,
    employer_name,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/employer-shift-relations`,
      {
        params: {
          start_date: start_date,
          end_date: end_date,
          employer_ids: employer_ids,
          employer_name: employer_name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
}
