import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Shift {
  getShiftInfo(id) {
    return axios.get(
      `/api/admin/shift/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getShiftList(id, name, type_status, page, page_num) {
    return axios.get(
      `/api/admin/shift/list`,
      {
        params: {
          id: id,
          name: name,
          type_status: type_status,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getShiftSelectList(name) {
    return axios.get(
      `/api/admin/shift/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchShift(datas) {
    return axios.post(
      `/api/admin/shift/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addShift(
    name,
    salary_multiplier,
    classify_status,
    type_status,
    start_time,
    end_time,
    break_time_begin,
    break_time_end,
    remark
  ) {
    return axios.post(
      `/api/admin/shift/`,
      {
        name: name,
        salary_multiplier: salary_multiplier,
        classify_status: classify_status,
        type_status: type_status,
        start_time: start_time,
        end_time: end_time,
        break_time_begin: break_time_begin,
        break_time_end: break_time_end,
        remark: remark,
      },
      config
    );
  }
  editShift(
    id,
    name,
    salary_multiplier,
    classify_status,
    type_status,
    start_time,
    end_time,
    break_time_begin,
    break_time_end,
    remark
  ) {
    return axios.put(
      `/api/admin/shift/`,
      {
        id: id,
        name: name,
        salary_multiplier: salary_multiplier,
        classify_status: classify_status,
        type_status: type_status,
        start_time: start_time,
        end_time: end_time,
        break_time_begin: break_time_begin,
        break_time_end: break_time_end,
        remark: remark,
      },
      config
    );
  }
  deleteShift(id) {
    return axios.delete(
      `/api/admin/shift/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
