<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
// import Loading from "../components/Loading.vue";
import QRCodeVue3 from "qrcode-vue3";
import router from "../router";
import * as XLSX from "xlsx/xlsx.mjs";
import Avatar from "primevue/avatar";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Chip from "primevue/chip";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Button from "primevue/button";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "../i18nPlugin";
import { useStore } from "vuex";
import AdminEmployer from "../api/salary/admin/employer";
import ManagerEmployer from "../api/salary/manager/employer";
import Organisation from "../api/salary/admin/organisation";
import Position from "../api/salary/admin/position";
import Department from "../api/salary/admin/department";
import Nationality from "../api/salary/admin/nationality";
import WorkPermit from "../api/salary/admin/work_permit";
import Currency from "../api/salary/admin/currency";
import Calendar from "primevue/calendar";
import AdminSalaryItem from "../api/salary/admin/salary_item";
import AdminBorrow from "../api/salary/admin/borrow";
import AdminSalary from "../api/salary/admin/salary";
import ManagerSalary from "../api/salary/manager/salary";
import User from "../api/salary/auth/user";
import Info from "./Info.vue";
import Salary from "./Salary.vue";
import { dateFormate } from "../tool/formate";
import PrintWater from "./PrintWater.vue";
import PrintWaterLocal from "./PrintWaterLocal.vue";
import { printHTML } from "../tool/print";
import africa_logo from "../assets/africa_logo.jpg";
import AdminBouns from "../api/salary/admin/bouns";
import AdminEmployerDetail from "../api/salary/admin/employer_detail";
import ManagerEmployerDetail from "../api/salary/manager/employer_detail";

let admin_employer_detailAPI = new AdminEmployerDetail();
let manager_employer_detailAPI = new ManagerEmployerDetail();

let admin_salary_itemAPI = new AdminSalaryItem();
let admin_borrowAPI = new AdminBorrow();
let admin_salaryAPI = new AdminSalary();
let admin_bounsAPI = new AdminBouns();
let userApi = new User();
let admin_employerAPI = new AdminEmployer();
let manager_employerAPI = new ManagerEmployer();
let manager_salaryAPI = new ManagerSalary();

let departmentsAPI = new Department();
let nationalitysAPI = new Nationality();

let organisationAPI = new Organisation();
let positionAPI = new Position();
let work_permitAPI = new WorkPermit();
let currencyAPI = new Currency();

const store = useStore();
const i18n = useI18n();
const toast = useToast();

const printDom = ref(null);
const printDomLocal = ref(null);
const printDomQR = ref(null);

const dt_employer = ref();
const dt_salary = ref();
const dt_salary_year = ref();

const data = reactive({
  export_excel_year_tmp: [],
  check_input_salary: true,
  og_limit: 5,
  salary_limit: 40,
  date_range: 90,
  want_mul_exchage_salary: true,
  salary_item_ids: [],
  want_mul_exchage: false,
  selectCurrencyForWaterList: [
    {
      id: 1,
      name: "系統幣別",
    },
    {
      id: 2,
      name: "轉換幣別",
    },
  ],
  salary_year: { data: null },
  excel_salary: { data: null },
  excel_employer: { data: null },
  ExportExcelWaterOrganisationSearch: null,
  ExportExcelSalaryOrganisationSearch: null,
  export_excel_water_settlement_of_month: "",
  export_excel_salary_settlement_of_month: "",
  export_excel_water: false,
  export_excel_salary: false,
  excel_pay_date: "",
  excel_settlement_of_month: "",
  ExcelOrganisationSearch: null,
  ExcelCurrencySearch: null,
  ExcelSalaryItemSearch: null,
  import_excel_salary: false,
  settlement_of_month: "",
  pay_date: "",
  salary_item_id: "",
  SalaryItemSearch: "",
  selectSalaryItemList: [],
  selectDepartmentsList: [],
  selectNationalitysList: [],
  minDate: getLastMonth(),
  maxDate: new Date(),
  AddSettlement: false,
  salary_manager: false,
  selectRoleList: [
    {
      id: 1,
      name: "Admin",
    },
    {
      id: 2,
      name: "Manager",
    },
    {
      id: 3,
      name: "Employee",
    },
  ],
  add_employer: {
    username: "",
    password: "",
    organisation_id: 0,
    new_position_ids: [],
    new_work_permit_ids: [],
    new_currency_id: [],
    name: "",
    english_name: "",
    salary: 0,
    role: 0,
  },
  OrganisationSearch: "",
  AddEmpolyerPositionsSearch: [],
  AddEmpolyerWorkPermitsSearch: [],
  AddEmpolyerCurrencySearch: [],
  selectOrganisationList: [],
  selectPositionsList: [],
  selectWorkPermitsList: [],
  selectCurrencyList: [],
  loading: false,
  employers: [],
  employer: null,
  detailDialog: false,
  addDialog: false,
  page: 1,
  page_num: 8,
  employerFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    organisation_id: { value: "", matchMode: "contains" },
    departments: { value: "", matchMode: "contains" },
    nationalitys: { value: "", matchMode: "contains" },
    work_permit: { value: "", matchMode: "contains" },
    identity_card_number: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  getEmployerList();
  if (store.state.user.role == 1) {
    getOrganisationSelectList();
    getPositionSelectList();
    getWorkPermitSelectList();
    getCurrencySelectList();
    getSalaryItemSelectList();
    getDepartmentSelectList();
    getNationalitySelectList();
  }
});

function ChooseBouns(employer) {
  if (store.state.bouns_employer.map((e) => e.id).indexOf(employer.id) == -1) {
    store.state.bouns_employer.push(employer);
  }
}
function RemoveChooseBouns(employer) {
  const indexToRemove = store.state.bouns_employer.findIndex(
    (e) => e.id === employer.id
  );
  if (indexToRemove !== -1) {
    store.state.bouns_employer.splice(indexToRemove, 1);
  }

  // if (store.state.bouns_employer.map((e) => e.id).indexOf(employer.id) != -1) {
  //   store.state.bouns_employer.map((e, index) =>
  //     store.state.bouns_employer.splice(index, 1)
  //   );
  // }
}

function ExportExcelEmployer() {
  admin_employerAPI
    .getEmployerExcelList(
      data.employerFilters.id.value,
      data.employerFilters.name.value,
      data.employerFilters.organisation_id.value,
      data.employerFilters.departments.value,
      data.employerFilters.nationalitys.value,
      data.employerFilters.work_permit.value,
      data.employerFilters.identity_card_number.value
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        // data.selectSalaryItemList = res.data.data.datas;
        let tmp = res.data.data.datas;
        for (let i = 0; i < tmp.length; i++) {
          tmp[i].currency = tmp[i].currency.name;
          if (tmp[i].work_permits != null) {
            let work_permits = "";
            for (let j = 0; j < tmp[i].work_permits.length; j++) {
              if (j == 0) {
                work_permits = work_permits + tmp[i].work_permits[j].name;
              } else {
                work_permits = work_permits + "," + tmp[i].work_permits[j].name;
              }
            }
            tmp[i].work_permits = work_permits;
          }
          if (tmp[i].positions != null) {
            let positions = "";
            for (let j = 0; j < tmp[i].positions.length; j++) {
              if (j == 0) {
                positions = positions + tmp[i].positions[j].name;
              } else {
                positions = positions + "," + tmp[i].positions[j].name;
              }
            }
            tmp[i].positions = positions;
          }
          if (
            tmp[i].employer_detail.departments != null &&
            tmp[i].employer_detail.departments != undefined
          ) {
            let departments = "";
            for (
              let j = 0;
              j < tmp[i].employer_detail.departments.length;
              j++
            ) {
              if (j == 0) {
                departments =
                  departments + tmp[i].employer_detail.departments[j].name;
              } else {
                departments =
                  departments +
                  "," +
                  tmp[i].employer_detail.departments[j].name;
              }
            }
            tmp[i].departments = departments;
          }
          if (
            tmp[i].employer_detail.nationalitys != null &&
            tmp[i].employer_detail.nationalitys != undefined
          ) {
            let nationalitys = "";
            for (
              let j = 0;
              j < tmp[i].employer_detail.nationalitys.length;
              j++
            ) {
              if (j == 0) {
                nationalitys =
                  nationalitys + tmp[i].employer_detail.nationalitys[j].name;
              } else {
                nationalitys =
                  nationalitys +
                  "," +
                  tmp[i].employer_detail.nationalitys[j].name;
              }
            }
            tmp[i].nationalitys = nationalitys;
          }

          tmp[i].birthday = dateFormate(
            tmp[i].employer_detail.birthday,
            "YYYY-MM-DD"
          );
          tmp[i].contract_effective_date = dateFormate(
            tmp[i].employer_detail.contract_effective_date,
            "YYYY-MM-DD"
          );
          tmp[i].contract_expire_date = dateFormate(
            tmp[i].employer_detail.contract_expire_date,
            "YYYY-MM-DD"
          );
          tmp[i].passport_expire_date = dateFormate(
            tmp[i].employer_detail.passport_expire_date,
            "YYYY-MM-DD"
          );
          tmp[i].start_working = dateFormate(
            tmp[i].employer_detail.start_working,
            "YYYY-MM-DD"
          );
          tmp[i].work_card_effective_date = dateFormate(
            tmp[i].employer_detail.work_card_effective_date,
            "YYYY-MM-DD"
          );
          tmp[i].work_card_expire_date = dateFormate(
            tmp[i].employer_detail.work_card_expire_date,
            "YYYY-MM-DD"
          );
          tmp[i].work_card_number = tmp[i].employer_detail.work_card_number;
          tmp[i].phone = tmp[i].employer_detail.phone;
          tmp[i].passport_number = tmp[i].employer_detail.passport_number;
          tmp[i].local_branch_code = tmp[i].employer_detail.local_branch_code;
          tmp[i].local_bank = tmp[i].employer_detail.local_bank;
          tmp[i].local_account_number =
            tmp[i].employer_detail.local_account_number;
          tmp[i].income_tax_number = tmp[i].employer_detail.income_tax_number;
          tmp[i].identity_card_number =
            tmp[i].employer_detail.identity_card_number;
          tmp[i].foreign_branch_code =
            tmp[i].employer_detail.foreign_branch_code;
          tmp[i].foreign_bank = tmp[i].employer_detail.foreign_bank;
          tmp[i].foreign_account_number =
            tmp[i].employer_detail.foreign_account_number;
          tmp[i].email = tmp[i].employer_detail.email;
          tmp[i].sex =
            tmp[i].employer_detail.sex == 1
              ? "Male"
              : tmp[i].employer_detail.sex == 2
              ? "Female"
              : "UnKnow";

          tmp[i].status =
            tmp[i].status == 1
              ? i18n.$t("Employer").EmployerTable.DisableAuth
              : tmp[i].status == 2
              ? i18n.$t("Employer").EmployerTable.EnableAuth
              : tmp[i].status == 3
              ? i18n.$t("Employer").EmployerTable.FreezeAuth
              : i18n.$t("Employer").EmployerTable.UnknowAuth;
          tmp[i].role =
            tmp[i].role == 1
              ? "Admin"
              : tmp[i].role == 2
              ? "Manager"
              : tmp[i].role == 3
              ? "Employer"
              : "UnKnow";
        }
        data.excel_employer.data = tmp;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    })
    .then(() => {
      dt_employer.value.exportCSV();
    });
}

async function SaveExportExcelWater(dom, show_position) {
  await store.commit("set_show_position", show_position);
  if (data.export_excel_water_settlement_of_month != null) {
    if (data.export_excel_water_settlement_of_month[1] != null) {
      let organisation = [];
      // if (data.ExportExcelWaterOrganisationSearch != null) {
      //   for (
      //     let i = 0;
      //     i < data.ExportExcelWaterOrganisationSearch.length;
      //     i++
      //   ) {
      //     organisation.push(data.ExportExcelWaterOrganisationSearch[i].id);
      //   }
      // }
      if (
        data.employerFilters.organisation_id.value != "" &&
        data.employerFilters.organisation_id.value != null
      ) {
        organisation.push(data.employerFilters.organisation_id.value);
      }
      let departments = [];
      if (
        data.employerFilters.departments.value != "" &&
        data.employerFilters.departments.value != null
      ) {
        departments = data.employerFilters.departments.value;
      }
      let nationalitys = [];
      if (
        data.employerFilters.nationalitys.value != "" &&
        data.employerFilters.nationalitys.value != null
      ) {
        nationalitys = data.employerFilters.nationalitys.value;
      }

      let work_permit = [];
      if (
        data.employerFilters.work_permit.value != "" &&
        data.employerFilters.work_permit.value != null
      ) {
        work_permit = data.employerFilters.work_permit.value;
      }

      let salary_items = [];
      if (data.SalaryItemForWater != null) {
        for (let i = 0; i < data.SalaryItemForWater.length; i++) {
          salary_items.push(data.SalaryItemForWater[i].id);
        }
      }
      let start = new Date(
        data.export_excel_water_settlement_of_month[0].toISOString()
      );
      const GMT_8_START_DATE =
        start.getHours() - (8 + start.getTimezoneOffset() / 60);

      start.setHours(GMT_8_START_DATE);

      let end = new Date(
        data.export_excel_water_settlement_of_month[1].toISOString()
      );
      const GMT_8_END_DATE =
        end.getHours() - (8 + end.getTimezoneOffset() / 60) + 24;

      end.setHours(GMT_8_END_DATE);

      if (organisation.length > data.og_limit) {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "Let organizations(組織) amount < " + data.og_limit,
          life: 3000,
        });
      } else if (salary_items.length > data.salary_limit) {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "Let salaryItems(薪資類型) amount < " + data.salary_limit,
          life: 3000,
        });
      } else if (
        Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) >
        data.date_range
      ) {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "Let date range <" + data.date_range,
          life: 3000,
        });
      } else {
        let water_tmp = [];
        admin_salaryAPI
          .getWaterExcel(
            start.toISOString(),
            end.toISOString(),
            // end_date.toISOString(),
            organisation,
            salary_items,
            data.want_mul_exchage,
            departments,
            nationalitys,
            work_permit
          )
          .then(async (res) => {
            if (res.data.error_code == 0) {
              let water = res.data.data.datas;
              for (let i = 0; i < water.length; i++) {
                let tmp_index = water_tmp.findIndex(
                  (x) =>
                    x.recipient_employer_id === water[i].recipient_employer_id
                );
                if (tmp_index != -1) {
                  if (water[i].salary_positive_or_negative == 2) {
                    water_tmp[tmp_index].total_positive =
                      water_tmp[tmp_index].total_positive +
                      parseFloat(water[i].dollar);

                    water_tmp[tmp_index].salary_positive.push({
                      salary_item_name: water[i].salary_item_name,
                      dollar: water[i].dollar,
                      settlement_of_month: water[i].settlement_of_month,
                      hours: water[i].hours,
                    });
                  } else {
                    water_tmp[tmp_index].total_negative =
                      water_tmp[tmp_index].total_negative +
                      parseFloat(water[i].dollar);

                    water_tmp[tmp_index].salary_negative.push({
                      salary_item_name: water[i].salary_item_name,
                      dollar: water[i].dollar,
                      settlement_of_month: water[i].settlement_of_month,
                      hours: water[i].hours,
                    });
                  }
                } else {
                  if (water[i].salary_positive_or_negative == 2) {
                    water_tmp.push({
                      show_date:
                        dateFormate(start, "DD-MM-YYYY") +
                        "~" +
                        dateFormate(
                          data.export_excel_water_settlement_of_month[1],
                          "DD-MM-YYYY"
                        ),
                      total_positive: parseFloat(water[i].dollar),
                      total_negative: 0,
                      pay_period: start,
                      recipient_employer_salary:
                        water[i].recipient_employer_salary,
                      recipient_employer_department_name:
                        water[i].departments != null
                          ? water[i].departments[0].name
                          : "",
                      recipient_employer_department_id:
                        water[i].departments != null
                          ? water[i].departments[0].id
                          : 0,
                      recipient_employer_departments: water[i].departments,
                      recipient_employer_id: water[i].recipient_employer_id,
                      recipient_employer_name: water[i].recipient_employer_name,
                      recipient_employer_english_name:
                        water[i].recipient_employer_english_name,
                      recipient_employer_sex:
                        water[i].recipient_employer_sex == 1
                          ? "Male"
                          : water[i].recipient_employer_sex == 2
                          ? "Female"
                          : "Unknow",
                      organisation_name: water[i].organisation_name,
                      positions: water[i].positions,
                      recipient_employer_start_working:
                        water[i].recipient_employer_start_working,
                      salary_positive: [
                        {
                          salary_item_name: water[i].salary_item_name,
                          dollar: water[i].dollar,
                          settlement_of_month: water[i].settlement_of_month,
                          hours: water[i].hours,
                        },
                      ],
                      salary_negative: [],
                    });
                  } else {
                    water_tmp.push({
                      show_date:
                        dateFormate(start, "DD-MM-YYYY") +
                        "~" +
                        dateFormate(
                          data.export_excel_water_settlement_of_month[1],
                          "DD-MM-YYYY"
                        ),
                      total_positive: 0,
                      total_negative: parseFloat(water[i].dollar),
                      pay_period: start,
                      recipient_employer_salary:
                        water[i].recipient_employer_salary,
                      recipient_employer_department_name:
                        water[i].departments != null
                          ? water[i].departments[0].name
                          : "",
                      recipient_employer_department_id:
                        water[i].departments != null
                          ? water[i].departments[0].id
                          : 0,
                      recipient_employer_departments: water[i].departments,
                      recipient_employer_id: water[i].recipient_employer_id,
                      recipient_employer_name: water[i].recipient_employer_name,
                      recipient_employer_english_name:
                        water[i].recipient_employer_english_name,
                      recipient_employer_sex:
                        water[i].recipient_employer_sex == 1
                          ? "Male"
                          : water[i].recipient_employer_sex == 2
                          ? "Female"
                          : "Unknow",
                      organisation_name: water[i].organisation_name,
                      positions: water[i].positions,
                      recipient_employer_start_working:
                        water[i].recipient_employer_start_working,
                      salary_positive: [],
                      salary_negative: [
                        {
                          salary_item_name: water[i].salary_item_name,
                          dollar: water[i].dollar,
                          settlement_of_month: water[i].settlement_of_month,
                          hours: water[i].hours,
                        },
                      ],
                    });
                  }
                }
              }
              // data.selectSalaryItemList = res.data.data.datas;
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          })
          .then(async () => {
            // await water_tmp.sort(function (a, b) {
            //   return a.recipient_employer_department_name.localeCompare(
            //     b.recipient_employer_department_name
            //   );
            // });
            await water_tmp.sort(
              (a, b) =>
                a.recipient_employer_department_id -
                b.recipient_employer_department_id
            );
            await store.commit("set_water", water_tmp);
            await store.commit("set_logo", africa_logo);
          })
          .then(() => {
            // console.log(store.state.logo);
            printHTML(dom);
          });
      }
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: "Please choose Date",
        life: 3000,
      });
    }
  } else {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail: "Please choose Date",
      life: 3000,
    });
  }
}
function SaveExportExcelSalary() {
  if (data.export_excel_salary_settlement_of_month != null) {
    if (data.export_excel_salary_settlement_of_month[1] != null) {
      let organisation = [];
      // if (data.ExportExcelSalaryOrganisationSearch != null) {
      //   for (
      //     let i = 0;
      //     i < data.ExportExcelSalaryOrganisationSearch.length;
      //     i++
      //   ) {
      //     organisation.push(data.ExportExcelSalaryOrganisationSearch[i].id);
      //   }
      // }
      if (
        data.employerFilters.organisation_id.value != "" &&
        data.employerFilters.organisation_id.value != null
      ) {
        organisation.push(data.employerFilters.organisation_id.value);
      }
      let departments = [];
      if (
        data.employerFilters.departments.value != "" &&
        data.employerFilters.departments.value != null
      ) {
        departments = data.employerFilters.departments.value;
      }
      let nationalitys = [];
      if (
        data.employerFilters.nationalitys.value != "" &&
        data.employerFilters.nationalitys.value != null
      ) {
        nationalitys = data.employerFilters.nationalitys.value;
      }

      let work_permit = [];
      if (
        data.employerFilters.work_permit.value != "" &&
        data.employerFilters.work_permit.value != null
      ) {
        work_permit = data.employerFilters.work_permit.value;
      }

      let salary_items = [];
      if (data.SalaryItemForExcelSalary != null) {
        for (let i = 0; i < data.SalaryItemForExcelSalary.length; i++) {
          salary_items.push(data.SalaryItemForExcelSalary[i].id);
        }
      }

      // const GMT_8_START_DATE =
      //   data.export_excel_salary_settlement_of_month[0].getHours() -
      //   (8 +
      //     data.export_excel_salary_settlement_of_month[0].getTimezoneOffset() /
      //       60);
      // data.export_excel_salary_settlement_of_month[0].setHours(
      //   GMT_8_START_DATE
      // );

      // const GMT_8_END_DATE =
      //   data.export_excel_salary_settlement_of_month[1].getHours() -
      //   (8 +
      //     data.export_excel_salary_settlement_of_month[1].getTimezoneOffset() /
      //       60) +
      //   24;
      // data.export_excel_salary_settlement_of_month[1].setHours(GMT_8_END_DATE);

      if (store.state.user.role == 1) {
        if (organisation.length > data.og_limit) {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: "Let organizations(組織) amount < " + data.og_limit,
            life: 3000,
          });
        } else if (salary_items.length > data.salary_limit) {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: "Let salaryItems(薪資類型) amount < " + data.salary_limit,
            life: 3000,
          });
        } else if (
          Math.round(
            (data.export_excel_salary_settlement_of_month[1].getTime() -
              data.export_excel_salary_settlement_of_month[0].getTime()) /
              (1000 * 60 * 60 * 24)
          ) > data.date_range
        ) {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: "Let date range <" + data.date_range,
            life: 3000,
          });
        } else {
          admin_salaryAPI
            .getSalaryExcel(
              dateFormate(
                data.export_excel_salary_settlement_of_month[0],
                "YYYY-MM-DD"
              ) + "T00:00:00.000+08:00",
              dateFormate(
                data.export_excel_salary_settlement_of_month[1],
                "YYYY-MM-DD"
              ) + "T15:59:59.999+08:00",

              // data.export_excel_salary_settlement_of_month[0].toISOString(),
              // data.export_excel_salary_settlement_of_month[1].toISOString(),
              // end_date.toISOString(),
              organisation,
              salary_items,
              data.want_mul_exchage_salary,
              departments,
              nationalitys,
              work_permit
            )
            .then((res) => {
              if (res.data.error_code == 0) {
                // data.selectSalaryItemList = res.data.data.datas;
                let tmp = res.data.data.datas;
                for (let i = 0; i < tmp.length; i++) {
                  tmp[i].settlement_of_month = dateFormate(
                    tmp[i].settlement_of_month,
                    "YYYY-MM-DD"
                  );
                  tmp[i].pay_date = dateFormate(tmp[i].pay_date, "YYYY-MM");
                  tmp[i].exchange_dollar =
                    tmp[i].salary_positive_or_negative == 1
                      ? tmp[i].dollar * -1
                      : tmp[i].dollar;
                  tmp[i].dollar =
                    tmp[i].salary_positive_or_negative == 1
                      ? (tmp[i].dollar / tmp[i].exchange_rate) * -1
                      : tmp[i].dollar / tmp[i].exchange_rate;
                  let tmp_recipient_employer_nationality = "";
                  if (tmp[i].recipient_employer_nationality != null) {
                    for (
                      let j = 0;
                      j < tmp[i].recipient_employer_nationality.length;
                      j++
                    ) {
                      if (j == 0) {
                        tmp_recipient_employer_nationality =
                          tmp_recipient_employer_nationality +
                          tmp[i].recipient_employer_nationality[j].name;
                      } else {
                        tmp_recipient_employer_nationality =
                          tmp_recipient_employer_nationality +
                          "｜" +
                          tmp[i].recipient_employer_nationality[j].name;
                      }
                    }
                  }
                  tmp[i].recipient_employer_nationality =
                    tmp_recipient_employer_nationality;
                  // tmp[i].exchange_dollar = tmp[i].dollar;
                  // tmp[i].dollar = tmp[i].dollar / tmp[i].exchange_rate;

                  // tmp[i].status =
                  //   tmp[i].status == 1
                  //     ? i18n.$t("ApplyBorrow").Reject
                  //     : tmp[i].status == 2
                  //     ? i18n.$t("ApplyBorrow").Pass
                  //     : tmp[i].status == 3
                  //     ? i18n.$t("ApplyBorrow").Doing
                  //     : i18n.$t("ApplyBorrow").UnKnow;
                }
                data.excel_salary.data = tmp;
              } else {
                toast.add({
                  severity: "warn",
                  summary: "Warn Message",
                  detail: res.data.error_text,
                  life: 3000,
                });
              }
            })
            .then(() => {
              dt_salary.value.exportCSV();
            });
        }
      } else if (store.state.user.role == 2) {
        if (organisation.length > data.og_limit) {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: "Let organizations(組織) amount < " + data.og_limit,
            life: 3000,
          });
        } else if (salary_items.length > data.salary_limit) {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: "Let salaryItems(薪資類型) amount < " + data.salary_limit,
            life: 3000,
          });
        } else if (
          Math.round(
            (data.export_excel_salary_settlement_of_month[1].getTime() -
              data.export_excel_salary_settlement_of_month[0].getTime()) /
              (1000 * 60 * 60 * 24)
          ) > data.date_range
        ) {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: "Let date range <" + data.date_range,
            life: 3000,
          });
        } else {
          manager_salaryAPI
            .getSalaryExcel(
              dateFormate(
                data.export_excel_salary_settlement_of_month[0],
                "YYYY-MM-DD"
              ) + "T00:00:00.000+08:00",
              dateFormate(
                data.export_excel_salary_settlement_of_month[1],
                "YYYY-MM-DD"
              ) + "T15:59:59.999+08:00",

              // data.export_excel_salary_settlement_of_month[0].toISOString(),
              // data.export_excel_salary_settlement_of_month[1].toISOString(),
              // end_date.toISOString(),
              store.state.user.organisation_id,
              salary_items,
              data.want_mul_exchage_salary
            )
            .then((res) => {
              if (res.data.error_code == 0) {
                // data.selectSalaryItemList = res.data.data.datas;
                let tmp = res.data.data.datas;
                for (let i = 0; i < tmp.length; i++) {
                  tmp[i].settlement_of_month = dateFormate(
                    tmp[i].settlement_of_month,
                    "YYYY-MM-DD"
                  );
                  tmp[i].pay_date = dateFormate(tmp[i].pay_date, "YYYY-MM");
                  tmp[i].exchange_dollar =
                    tmp[i].salary_positive_or_negative == 1
                      ? tmp[i].dollar * -1
                      : tmp[i].dollar;
                  tmp[i].dollar =
                    tmp[i].salary_positive_or_negative == 1
                      ? (tmp[i].dollar / tmp[i].exchange_rate) * -1
                      : tmp[i].dollar / tmp[i].exchange_rate;
                  // tmp[i].status =
                  //   tmp[i].status == 1
                  //     ? i18n.$t("ApplyBorrow").Reject
                  //     : tmp[i].status == 2
                  //     ? i18n.$t("ApplyBorrow").Pass
                  //     : tmp[i].status == 3
                  //     ? i18n.$t("ApplyBorrow").Doing
                  //     : i18n.$t("ApplyBorrow").UnKnow;
                }
                data.excel_salary.data = tmp;
              } else {
                toast.add({
                  severity: "warn",
                  summary: "Warn Message",
                  detail: res.data.error_text,
                  life: 3000,
                });
              }
            })
            .then(() => {
              dt_salary.value.exportCSV();
            });
        }
      }
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: "Please choose Date",
        life: 3000,
      });
    }
  } else {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail: "Please choose Date",
      life: 3000,
    });
  }
}

async function SaveExportExcelYear() {
  let tmp_YearOrganizationsSearch = [];
  let tmp_YearDepartmentsSearch = [];
  for (let i = 0; i < data.YearDepartmentsSearch.length; i++) {
    await tmp_YearDepartmentsSearch.push(data.YearDepartmentsSearch[i].id);
  }
  for (let i = 0; i < data.YearOrganisationsSearch.length; i++) {
    await tmp_YearOrganizationsSearch.push(data.YearOrganisationsSearch[i].id);
  }

  data.disalbe_year = true;
  if (data.export_excel_year_settlement_of_month != null) {
    if (data.export_excel_year_settlement_of_month[1] != null) {
      const timeDiff =
        data.export_excel_year_settlement_of_month[1] -
        data.export_excel_year_settlement_of_month[0];
      // 将毫秒数转换为天数
      const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
      if (tmp_YearOrganizationsSearch.length > data.og_limit) {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "Let organizations(組織) amount < " + data.og_limit,
          life: 3000,
        });
        data.disalbe_year = false;
      } else if (tmp_YearDepartmentsSearch.length > data.og_limit) {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "Let departments(部門) amount < " + data.og_limit,
          life: 3000,
        });
        data.disalbe_year = false;
      } else if (daysDiff > 370) {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "Please do not Over 1 Year",
          life: 3000,
        });
        data.disalbe_year = false;
      } else {
        const GMT_8_START_DATE =
          data.export_excel_year_settlement_of_month[0].getHours() -
          (8 +
            data.export_excel_year_settlement_of_month[0].getTimezoneOffset() /
              60);

        data.export_excel_year_settlement_of_month[0].setHours(
          GMT_8_START_DATE
        );

        const GMT_8_END_DATE =
          data.export_excel_year_settlement_of_month[1].getHours() -
          (8 +
            data.export_excel_year_settlement_of_month[1].getTimezoneOffset() /
              60);

        data.export_excel_year_settlement_of_month[1].setHours(GMT_8_END_DATE);
        admin_salaryAPI
          .getSalaryYear(
            data.export_excel_year_settlement_of_month[0].toISOString(),
            data.export_excel_year_settlement_of_month[1].toISOString(),
            tmp_YearOrganizationsSearch,
            tmp_YearDepartmentsSearch
          )
          .then(async (res) => {
            if (res.data.error_code == 0) {
              let tmp = [];
              for (let i = 0; i < res.data.data.datas.length; i++) {
                const hasMatch = await tmp.some(
                  (person) =>
                    person.recipient_employer_id ==
                    res.data.data.datas[i].recipient_employer_id
                );

                if (hasMatch) {
                  tmp.forEach((person) => {
                    if (
                      person.recipient_employer_id ==
                      res.data.data.datas[i].recipient_employer_id
                    ) {
                      for (let j = 0; j < person.salary_type.length; j++) {
                        if (
                          person.salary_type[j].id ==
                          res.data.data.datas[i].salary_item_id
                        ) {
                          person.salary_type[j].dollar +=
                            parseFloat(res.data.data.datas[i].dollar) *
                            (person.salary_type[j].positive_or_negative == 2
                              ? 1
                              : -1);
                        }
                      }
                      // person.salary_type += res.data.data.datas[i].dollar;
                    }
                  });
                } else {
                  let tmp_data = {
                    recipient_employer_id:
                      res.data.data.datas[i].recipient_employer_id,
                    recipient_employer_name:
                      res.data.data.datas[i].recipient_employer_name,
                    organisation_name: res.data.data.datas[i].organisation_name,
                    recipient_employer_english_name:
                      res.data.data.datas[i].recipient_employer_english_name,
                    salary_type: [],
                    salary: res.data.data.datas[i].employers.salary,
                    sex:
                      res.data.data.datas[i].employers.employer_detail.sex == 1
                        ? "Male"
                        : res.data.data.datas[i].employers.employer_detail
                            .sex == 2
                        ? "Female"
                        : "Unknow",
                    identity_card_number:
                      res.data.data.datas[i].employers.employer_detail
                        .identity_card_number,
                    income_tax_number:
                      res.data.data.datas[i].employers.employer_detail
                        .income_tax_number,

                    local_bank:
                      res.data.data.datas[i].employers.employer_detail
                        .local_bank,
                    local_branch_code:
                      res.data.data.datas[i].employers.employer_detail
                        .local_branch_code,
                    local_account_number:
                      res.data.data.datas[i].employers.employer_detail
                        .local_account_number,

                    foreign_bank:
                      res.data.data.datas[i].employers.employer_detail
                        .foreign_bank,
                    foreign_branch_code:
                      res.data.data.datas[i].employers.employer_detail
                        .foreign_branch_code,
                    foreign_account_number:
                      res.data.data.datas[i].employers.employer_detail
                        .foreign_account_number,

                    birthday: dateFormate(
                      res.data.data.datas[i].employers.employer_detail.birthday,
                      "YYYY-MM-DD"
                    ),

                    start_working: dateFormate(
                      res.data.data.datas[i].employers.employer_detail
                        .start_working,
                      "YYYY-MM-DD"
                    ),
                    position:
                      res.data.data.datas[i].positions != undefined
                        ? res.data.data.datas[i].positions[0].name
                        : "",
                    department:
                      res.data.data.datas[i].departments != undefined
                        ? res.data.data.datas[i].departments[0].name
                        : "",
                    nationality:
                      res.data.data.datas[i].nationalitys != undefined
                        ? res.data.data.datas[i].nationalitys[0].name
                        : "",
                  };
                  for (let j = 0; j < data.export_excel_year_tmp.length; j++) {
                    if (
                      data.export_excel_year_tmp[j].id ==
                      res.data.data.datas[i].salary_item_id
                    ) {
                      await tmp_data.salary_type.push({
                        id: data.export_excel_year_tmp[j].id,
                        name: data.export_excel_year_tmp[j].name,
                        dollar:
                          parseFloat(res.data.data.datas[i].dollar) *
                          (data.export_excel_year_tmp[j].positive_or_negative ==
                          2
                            ? 1
                            : -1),
                        positive_or_negative:
                          data.export_excel_year_tmp[j].positive_or_negative,
                      });
                    } else {
                      await tmp_data.salary_type.push({
                        id: data.export_excel_year_tmp[j].id,
                        name: data.export_excel_year_tmp[j].name,
                        dollar: 0,
                        positive_or_negative:
                          data.export_excel_year_tmp[j].positive_or_negative,
                      });
                    }
                  }
                  await tmp.push(tmp_data);
                }
              }
              let tmp_dollar_gross = 0;
              let tmp_dollar_net = 0;
              let check_gross = true;
              for (let i = 0; i < tmp.length; i++) {
                tmp_dollar_gross = 0;
                tmp_dollar_net = 0;
                check_gross = true;
                for (let j = 0; j < tmp[i].salary_type.length; j++) {
                  if (tmp[i].salary_type[j].name == "Gross Pay") {
                    tmp[i].salary_type[j].dollar = tmp_dollar_gross;
                    check_gross = false;
                  }
                  if (check_gross) {
                    tmp_dollar_gross += tmp[i].salary_type[j].dollar;
                  }
                  if (
                    !check_gross &&
                    tmp[i].salary_type[j].name != "Gross Pay"
                  ) {
                    tmp_dollar_net += tmp[i].salary_type[j].dollar;
                  }
                  if (tmp[i].salary_type[j].name == "Net Pay") {
                    tmp[i].salary_type[j].dollar =
                      tmp_dollar_gross + tmp_dollar_net;
                  }
                }
                for (let j = 0; j < tmp[i].salary_type.length; j++) {
                  tmp[i][tmp[i].salary_type[j].name] =
                    tmp[i].salary_type[j].dollar.toFixed(2);
                }
              }

              // console.log(tmp);
              // console.log(data.export_excel_year_tmp);
              // console.log(tmp[0]["Overtime 1.5"]);
              data.salary_year.data = tmp;
              data.disalbe_year = false;
              data.export_excel_year = false;
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
              data.disalbe_year = false;
            }
          })
          .then(() => {
            dt_salary_year.value.exportCSV();
          });
      }
    } else {
      data.disalbe_year = false;
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: "Please choose Date",
        life: 3000,
      });
    }
  } else {
    data.disalbe_year = false;
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail: "Please choose Date",
      life: 3000,
    });
  }
}

async function ExportExcelYear() {
  data.YearDepartmentsSearch = [];
  data.YearOrganisationsSearch = [];
  data.export_excel_year = true;
  data.export_excel_year_settlement_of_month = "";

  if (data.export_excel_year_tmp.length == 0) {
    let tmp = [];
    for (let i = 0; i < data.selectSalaryItemList.length; i++) {
      await tmp.push(data.selectSalaryItemList[i]);
    }
    await tmp.sort((a, b) => {
      if (a.taxed === b.taxed) {
        // 如果年龄相同，按姓名降序排序
        return b.id - a.id;
      } else {
        // 否则按年龄升序排序
        return a.taxed - b.taxed;
      }
    });
    data.export_excel_year_tmp = [];
    let check = true;
    for (let j = 0; j < tmp.length; j++) {
      if (tmp[j].taxed == 2 && check) {
        check = false;
        await data.export_excel_year_tmp.push({
          id: 0,
          name: "Gross Pay",
          taxed: 0,
          positive_or_negative: 2,
        });
      }
      await data.export_excel_year_tmp.push({
        id: tmp[j].id,
        name: tmp[j].name,
        dollar: 0,
        positive_or_negative: tmp[j].positive_or_negative,
      });
      if (j == tmp.length - 1) {
        await data.export_excel_year_tmp.push({
          id: 0,
          name: "Net Pay",
          taxed: 0,
          positive_or_negative: 2,
        });
      }
    }
  }
}

function ExportExcelWater() {
  data.export_excel_water = true;
  data.export_excel_water_settlement_of_month = "";
  data.ExportExcelWaterOrganisationSearch = null;
  data.CurrencyForWater = "";
  data.SalaryItemForWater = "";
  data.want_mul_exchage = false;
}

function ExportExcelSalary() {
  data.export_excel_salary = true;
  data.export_excel_salary_settlement_of_month = "";
  data.ExportExcelSalaryOrganisationSearch = null;
  data.want_mul_exchage_salary = true;
  data.CurrencyForExcelSalary = "";
  data.SalaryItemForExcelSalary = "";
}
function SaveExcelSalary(e) {
  // console.log(e.target.files[0]);
  //上传文件后读取excel文件内容
  let file = e.target.files[0];
  const types = ["xlsx", "xls"];
  const arr = file.name.split(".");
  //判断文件是否为excel文件
  if (!types.find((item) => item === arr[arr.length - 1])) {
    alert("choose (xlsx or xls)");
    return;
  }
  let reader = new FileReader();
  //启动函数
  reader.readAsBinaryString(file);
  reader.onload = (e) => {
    // console.log(e);
    //workbook存放excel的所有基本信息
    let workbook = XLSX.read(e.target.result, {
      type: "binary",
      cellDates: true,
    });
    //定义sheetList中存放excel表格的sheet表，就是最下方的tab
    let sheetList = workbook.SheetNames;
    //读取文件内容，（第一个sheet里的内容）
    // range：设置从第几行开始读取内容
    let json = XLSX.utils.sheet_to_json(workbook.Sheets[sheetList[0]], {
      range: 1,
    });
    let importExcel = [];
    for (let i = 0; i < json.length; i++) {
      importExcel.push({
        recipient_employer_id:
          json[i].recipient_employer_id != undefined
            ? json[i].recipient_employer_id
            : "",
        dollar: json[i].dollar != undefined ? String(json[i].dollar) : "",
        salary_item_id:
          json[i].salary_item_id != undefined ? json[i].salary_item_id : "",
        // data.ExcelSalaryItemSearch == null
        //   ? ""
        //   : data.ExcelSalaryItemSearch.id,
        description:
          json[i].description != undefined ? json[i].description : "",
        currency_id: 1,
        // data.ExcelCurrencySearch == null ? "" : data.ExcelCurrencySearch.id,
        pay_date:
          data.excel_pay_date != "" ? data.excel_pay_date.toISOString() : "",
        settlement_of_month:
          data.excel_settlement_of_month != ""
            ? data.excel_settlement_of_month.toISOString()
            : "",
        organisation_id:
          data.ExcelOrganisationSearch == null
            ? ""
            : data.ExcelOrganisationSearch.id,
        hours: json[i].hours != undefined ? String(json[i].hours) : "",
      });
    }

    admin_salaryAPI.batchSalary(importExcel).then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Salary").AddSuccess,
          life: 3000,
        });

        data.import_excel_salary = false;
      } else {
        data.import_excel_salary = false;
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  };
}
function ImportExcelSalary() {
  data.import_excel_salary = true;
  data.excel_pay_date = "";
  data.excel_settlement_of_month = "";
  data.ExcelOrganisationSearch = null;
  data.ExcelCurrencySearch = null;
  data.ExcelSalaryItemSearch = null;
  data.salary_item_ids = [];
  data.want_mul_exchage = false;
}
function getSalaryItemSelectList() {
  admin_salary_itemAPI.getSalaryItemSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectSalaryItemList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getLastMonth() {
  let nowDate = new Date();
  let year = nowDate.getFullYear();
  let month = nowDate.getMonth();
  if (month == 0) {
    month = 11;
    year = year - 1;
  } else {
    month = month - 1;
  }
  let lastMonth = new Date(year, month, 0);
  return lastMonth;
}
function getLastMonthNew() {
  let nowDate = new Date();
  let year = nowDate.getFullYear();
  let month = nowDate.getMonth();
  if (month == 0) {
    month = 11;
    year = year - 1;
  } else {
    month = month - 1;
  }
  let lastMonth = new Date(year, month + 1, 0);
  return lastMonth;
}
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      // console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      store.commit("set_user", null);
    }
  });
}
function Manager(employer) {
  store.commit("set_employer", employer);
  data.salary_manager = true;
}
function Choose(employer) {
  store.commit("set_employer", employer);
}
function AddIncomeTax() {
  data.AddIncomeTax = true;
  data.SalaryItemHealthySearch = "";
  data.SalaryItemTaxSearch = "";
  data.salary_item_id = "";
  data.pay_date = "";
  data.settlement_of_month = "";
}
function SaveAddIncomeTax() {
  admin_salaryAPI
    .settlementIcomeTax(
      data.salary_item_healthy_id,
      data.salary_item_tax_id,
      data.pay_date != "" ? data.pay_date.toISOString() : "",
      data.settlement_of_month != "" && data.settlement_of_month != null
        ? data.settlement_of_month[0].toISOString()
        : "",
      data.settlement_of_month != "" && data.settlement_of_month != null
        ? data.settlement_of_month[1].toISOString()
        : ""
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Employer").Success,
          life: 3000,
        });
        data.AddIncomeTax = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function DeleteSalary() {
  data.SalaryItemSearch = "";
  data.DeleteSalary = true;
  data.salary_item_id = "";
  data.settlement_of_month = "";
}

async function SaveDeleteSalary() {
  let lastDate = "";
  let startDate = "";
  if (data.settlement_of_month != "" && data.settlement_of_month != null) {
    if (data.settlement_of_month[1] != null) {
      let tmp_start = new Date(
        data.settlement_of_month[0].getFullYear(),
        data.settlement_of_month[0].getMonth(),
        data.settlement_of_month[0].getDate(),
        0,
        0,
        0
      );
      let tmp = new Date(
        data.settlement_of_month[1].getFullYear(),
        data.settlement_of_month[1].getMonth(),
        data.settlement_of_month[1].getDate(),
        0,
        0,
        0
      );
      await tmp.setHours(tmp.getHours() + 24);
      const GMT_8_TMP =
        (await tmp.getHours()) - (8 + tmp.getTimezoneOffset() / 60);
      const GMT_8_TMP_Start =
        (await tmp_start.getHours()) - (8 + tmp_start.getTimezoneOffset() / 60);
      // tmp.setHours(tmp.getHours() + 24);
      await tmp_start.setHours(GMT_8_TMP_Start);
      await tmp.setHours(GMT_8_TMP);
      lastDate = tmp.toISOString();
      startDate = tmp_start.toISOString();
    }
  }
  if (data.salary_item_id == null || data.salary_item_id == "") {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail: "Please Choose Salary Type!!",
      life: 3000,
    });
  } else if (lastDate == "") {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail: "Please Choose SettlementOfMonth!!",
      life: 3000,
    });
  } else {
    admin_salaryAPI
      .deleteSalaryBatch(
        data.employerFilters.organisation_id.value == "" ||
          data.employerFilters.organisation_id.value == null
          ? 0
          : data.employerFilters.organisation_id.value,
        data.salary_item_id,
        // data.settlement_of_month != "" && data.settlement_of_month != null
        //   ? data.settlement_of_month[0].toISOString()
        //   : "",
        startDate,
        lastDate
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Salary").DeleteSuccess,
            life: 3000,
          });
          data.DeleteSalary = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  }
}

function AddSettlement(check) {
  data.check_input_salary = check;
  data.AddSettlement = true;
  data.SalaryItemSearch = "";
  data.salary_item_id = "";
  data.pay_date = "";
  data.settlement_of_month = "";
}
function SaveAddSettlementBouns() {
  let lastDate = "";
  if (data.settlement_of_month != "" && data.settlement_of_month != null) {
    let tmp = new Date(data.settlement_of_month.toISOString());
    tmp.setHours(tmp.getHours() + 24);
    lastDate = tmp.toISOString();
  }
  admin_bounsAPI
    .settlementBouns(
      data.salary_item_id,
      data.pay_date != "" ? data.pay_date.toISOString() : "",
      data.settlement_of_month != "" && data.settlement_of_month != null
        ? data.settlement_of_month.toISOString()
        : "",
      lastDate
      // data.settlement_of_month != "" &&
      //   data.settlement_of_month != null &&
      //   data.settlement_of_month[1] != null
      //   ? data.settlement_of_month[1].toISOString()
      //   : ""
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Employer").Success,
          life: 3000,
        });
        data.AddSettlement = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function SaveAddSettlementBorrow() {
  let lastDate = "";
  if (data.settlement_of_month != "" && data.settlement_of_month != null) {
    let tmp = new Date(data.settlement_of_month.toISOString());
    tmp.setHours(tmp.getHours() + 24);
    lastDate = tmp.toISOString();
  }
  admin_borrowAPI
    .settlementBorrow(
      data.salary_item_id,
      data.pay_date != "" ? data.pay_date.toISOString() : "",
      data.settlement_of_month != "" && data.settlement_of_month != null
        ? data.settlement_of_month.toISOString()
        : "",
      lastDate
      // data.settlement_of_month != "" &&
      //   data.settlement_of_month != null &&
      //   data.settlement_of_month[1] != null
      //   ? data.settlement_of_month[1].toISOString()
      //   : ""
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Employer").Success,
          life: 3000,
        });
        data.AddSettlement = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function SaveAddSettlementSalary() {
  let lastDate = "";
  if (data.settlement_of_month != "" && data.settlement_of_month != null) {
    if (data.settlement_of_month[1] != null) {
      let tmp = new Date(data.settlement_of_month[1].toISOString());
      tmp.setHours(tmp.getHours() + 24);
      lastDate = tmp.toISOString();
    }
  }
  admin_salaryAPI
    .settlementSalary(
      data.salary_item_id,
      data.pay_date != "" ? data.pay_date.toISOString() : "",
      data.settlement_of_month != "" && data.settlement_of_month != null
        ? data.settlement_of_month[0].toISOString()
        : "",
      lastDate
      // data.settlement_of_month != "" && data.settlement_of_month != null
      //   ? data.settlement_of_month[1].toISOString()
      //   : ""
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Employer").Success,
          life: 3000,
        });
        data.AddSettlement = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function AddEmployer() {
  data.addDialog = true;
  data.AddEmpolyerRoleSearch = "";
  data.AddEmpolyerOrganisationSearch = "";
  data.AddEmpolyerPositionsSearch = [];
  data.AddEmpolyerWorkPermitsSearch = [];
  data.add_employer = {
    username: "",
    password: "",
    organisation_id: 0,
    new_position_ids: [],
    new_work_permit_ids: [],
    new_currency_id: [],
    name: "",
    english_name: "",
    salary: 0,
    role: 0,
  };
}

function saveAddEmployer() {
  if (data.add_employer.password == "") {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Employer").Fill +
        i18n.$t("Employer").EmployerTable.PassWord.Show,
      life: 3000,
    });
  } else if (
    data.add_employer.organisation_id == [] ||
    data.add_employer.organisation_id == 0
  ) {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Employer").Fill +
        i18n.$t("Employer").EmployerTable.Organisation.Show,
      life: 3000,
    });
  }
  // else if (
  //   data.add_employer.new_currency_id == [] ||
  //   data.add_employer.new_currency_id == 0
  // ) {
  //   toast.add({
  //     severity: "warn",
  //     summary: "Warn Message",
  //     detail:
  //       i18n.$t("Employer").Fill +
  //       i18n.$t("Employer").EmployerTable.Currency.Show,
  //     life: 3000,
  //   });
  // }
  else if (data.add_employer.name == "") {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Employer").Fill + i18n.$t("Employer").EmployerTable.Name.Show,
      life: 3000,
    });
  } else if (data.add_employer.english_name == "") {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Employer").Fill +
        i18n.$t("Employer").EmployerTable.EnglishName.Show,
      life: 3000,
    });
  } else if (data.add_employer.salary == 0) {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Employer").Fill +
        i18n.$t("Employer").EmployerTable.Salary.Show,
      life: 3000,
    });
  } else if (data.add_employer.role == 0 || data.add_employer.role == "") {
    toast.add({
      severity: "warn",
      summary: "Warn Message",
      detail:
        i18n.$t("Employer").Fill + i18n.$t("Employer").EmployerTable.Role.Show,
      life: 3000,
    });
  } else {
    admin_employerAPI
      .addEmployer(
        data.add_employer.password,
        data.add_employer.organisation_id,
        data.add_employer.new_position_ids,
        data.add_employer.new_work_permit_ids,
        // data.add_employer.new_currency_id,
        1,
        data.add_employer.name,
        data.add_employer.english_name,
        String(data.add_employer.salary),
        data.add_employer.role
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Employer").AddSuccess,
            life: 3000,
          });

          data.loading = true;
          getEmployerList();
          data.addDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  }
}
function FireDialog(employer) {
  data.fireDialog = true;
  data.employer = employer;
}
function ReFireDialog(employer) {
  data.refireDialog = true;
  data.employer = employer;
}
function Fire() {
  admin_employerAPI.deleteEmployer(data.employer.id, 3).then((res) => {
    if (res.data.error_code == 0) {
      data.loading = false;
      data.page = 1;
      data.employers = [];
      getEmployerList();
      data.fireDialog = false;
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Employer").FireSuccess,
        life: 3000,
      });
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function ReFire() {
  admin_employerAPI.deleteEmployer(data.employer.id, 2).then((res) => {
    if (res.data.error_code == 0) {
      data.loading = false;
      data.page = 1;
      data.employers = [];
      getEmployerList();
      data.refireDialog = false;
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Employer").ReFireSuccess,
        life: 3000,
      });
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function Detail(employer) {
  store.commit("set_employer", employer);
  store.state.bouns_employer = [];
  store.state.bouns_employer.push(employer);
  data.detailDialog = true;
}

function ChooseAllBouns() {
  switch (store.state.user.role) {
    case 1:
      admin_employerAPI
        .getEmployerList(
          data.employerFilters.id.value,
          data.employerFilters.name.value,
          data.employerFilters.organisation_id.value,
          data.employerFilters.departments.value,
          data.employerFilters.nationalitys.value,
          data.employerFilters.work_permit.value,
          data.employerFilters.identity_card_number.value,
          1,
          2000
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            let tmp = res.data.data.datas;

            const filteredUsers = tmp.filter(
              (user) => user.role != 1 && user.status == 2
            );
            store.state.bouns_employer = filteredUsers;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
    case 2:
      manager_employerAPI
        .getEmployerList(
          data.employerFilters.id.value,
          data.employerFilters.name.value,
          data.employerFilters.organisation_id.value,
          data.employerFilters.departments.value,
          data.employerFilters.nationalitys.value,
          data.employerFilters.work_permit.value,
          data.employerFilters.identity_card_number.value,
          1,
          2000
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            let tmp = res.data.data.datas;

            const filteredUsers = tmp.filter((user) => user.role != 1);
            store.state.bouns_employer = filteredUsers;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
  }
}
function CancelChooseAllBouns() {
  store.state.bouns_employer = [];
}

function getEmployerList() {
  CheckUser();
  switch (store.state.user.role) {
    case 1:
      admin_employerAPI
        .getEmployerList(
          data.employerFilters.id.value,
          data.employerFilters.name.value,
          data.employerFilters.organisation_id.value,
          data.employerFilters.departments.value,
          data.employerFilters.nationalitys.value,
          data.employerFilters.work_permit.value,
          data.employerFilters.identity_card_number.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            // data.employers = res.data.data.datas;
            for (let i = 0; i < res.data.data.datas.length; i++) {
              if (
                !data.employers.find((e) => e.id === res.data.data.datas[i].id)
              ) {
                data.employers.push(res.data.data.datas[i]);
              }
            }

            // data.employers = data.employers.concat(res.data.data.datas);
            data.page = res.data.data.page;
            data.loading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            data.totalRecords = 0;
            data.loading = false;
          }
        });
      break;
    case 2:
      manager_employerAPI
        .getEmployerList(
          data.employerFilters.id.value,
          data.employerFilters.name.value,
          data.employerFilters.organisation_id.value,
          data.employerFilters.departments.value,
          data.employerFilters.nationalitys.value,
          data.employerFilters.work_permit.value,
          data.employerFilters.identity_card_number.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            // data.employers = res.data.data.datas;
            data.employers = data.employers.concat(res.data.data.datas);
            data.page = res.data.data.page;
            data.loading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            data.totalRecords = 0;
            data.loading = false;
          }
        });
      break;
  }
}
function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getNationalitySelectList() {
  nationalitysAPI.getNationalitySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectNationalitysList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getDepartmentSelectList() {
  departmentsAPI.getDepartmentSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectDepartmentsList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getPositionSelectList() {
  positionAPI.getPositionSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectPositionsList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getWorkPermitSelectList() {
  work_permitAPI.getWorkPermitSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectWorkPermitsList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getCurrencySelectList() {
  currencyAPI.getCurrencySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCurrencyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function handleScroll(e) {
  if (
    e.target.clientHeight + Math.ceil(e.target.scrollTop) >=
      e.target.scrollHeight &&
    data.page < Math.ceil(data.totalRecords / data.page_num)
  ) {
    data.loading = true;
    data.page = data.page + 1;
    getEmployerList();
  }
}
watch(
  () => data.AddEmpolyerRoleSearch,
  () => {
    data.add_employer.role =
      data.AddEmpolyerRoleSearch == null ? "" : data.AddEmpolyerRoleSearch.id;
  }
);
watch(
  () => data.AddEmpolyerOrganisationSearch,
  () => {
    data.add_employer.organisation_id =
      data.AddEmpolyerOrganisationSearch == null
        ? ""
        : data.AddEmpolyerOrganisationSearch.id;
  }
);
watch(
  () => data.AddEmpolyerPositionsSearch,
  async () => {
    let tmpIDs = [];
    for (let i = 0; i < data.AddEmpolyerPositionsSearch.length; i++) {
      await tmpIDs.push(data.AddEmpolyerPositionsSearch[i].id);
    }
    data.add_employer.new_position_ids = tmpIDs;
  }
);
watch(
  () => data.AddEmpolyerWorkPermitsSearch,
  async () => {
    let tmpIDs = [];
    for (let i = 0; i < data.AddEmpolyerWorkPermitsSearch.length; i++) {
      await tmpIDs.push(data.AddEmpolyerWorkPermitsSearch[i].id);
    }
    data.add_employer.new_work_permit_ids = tmpIDs;
  }
);
watch(
  () => data.AddEmpolyerCurrencySearch,
  async () => {
    let tmpIDs = [];
    for (let i = 0; i < data.AddEmpolyerCurrencySearch.length; i++) {
      // await tmpIDs.push(data.AddEmpolyerCurrencySearch[i].id);
      data.add_employer.new_currency_id = data.AddEmpolyerCurrencySearch[i].id;
    }
  }
);
watch(
  () => data.OrganisationSearch,
  () => {
    // data.loading = true;
    // data.page = 1;
    // data.employers = [];
    data.employerFilters.organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    // getEmployerList();
  }
);

watch(
  () => data.DepartmentsSearch,
  () => {
    // data.loading = true;
    // data.page = 1;
    // data.employers = [];
    data.employerFilters.departments.value =
      data.DepartmentsSearch == null ? "" : [data.DepartmentsSearch.id];
    // getEmployerList();
  }
);

watch(
  () => data.NationalitysSearch,
  () => {
    // data.loading = true;
    // data.page = 1;
    // data.employers = [];
    data.employerFilters.nationalitys.value =
      data.NationalitysSearch == null ? "" : [data.NationalitysSearch.id];
    // getEmployerList();
  }
);

watch(
  () => data.WorkPermitSearch,
  () => {
    // data.loading = true;
    // data.page = 1;
    // data.employers = [];
    data.employerFilters.work_permit.value =
      data.WorkPermitSearch == null ? "" : [data.WorkPermitSearch.id];
    // getEmployerList();
  }
);

watch(
  () => data.employerFilters.id.value,
  () => {
    // data.loading = true;
    // data.page = 1;
    // data.employers = [];
    // getEmployerList();
  }
);
watch(
  () => store.state.employer_check,
  () => {
    data.loading = true;
    data.page = 1;
    data.employers = [];
    getEmployerList();
  }
);
watch(
  () => data.employerFilters.name.value,
  () => {
    // data.loading = true;
    // data.page = 1;
    // data.employers = [];
    // getEmployerList();
  }
);
watch(
  () => data.SalaryItemSearch,
  () => {
    data.salary_item_id =
      data.SalaryItemSearch == null ? "" : data.SalaryItemSearch.id;
  }
);
watch(
  () => data.SalaryItemHealthySearch,
  () => {
    data.salary_item_healthy_id =
      data.SalaryItemHealthySearch == null
        ? ""
        : data.SalaryItemHealthySearch.id;
  }
);
watch(
  () => data.SalaryItemTaxSearch,
  () => {
    data.salary_item_tax_id =
      data.SalaryItemTaxSearch == null ? "" : data.SalaryItemTaxSearch.id;
  }
);

watch(
  () => data.CurrencyForWater,
  () => {
    data.want_mul_exchage =
      data.CurrencyForWater == null
        ? false
        : data.CurrencyForWater.id == 1
        ? false
        : data.CurrencyForWater.id == 2
        ? true
        : false;
  }
);
watch(
  () => data.CurrencyForExcelSalary,
  () => {
    data.want_mul_exchage_salary =
      data.CurrencyForExcelSalary == null
        ? true
        : data.CurrencyForExcelSalary.id == 1
        ? false
        : data.CurrencyForExcelSalary.id == 2
        ? true
        : true;
  }
);

// watch(
//   () => data.SalaryItemForWater,
//   () => {
//     data.salary_item_id =
//       data.SalaryItemSearch == null ? "" : data.SalaryItemSearch.id;
//   }
// );
function QRcodeShow(employer) {
  GetEmployerDetailInfo(employer);
  // data.tmp = [employer];
}
function GetEmployerDetailInfo(employer) {
  switch (store.state.user.role) {
    case 1:
      admin_employer_detailAPI
        .getEmployerDetailInfo(employer.id)
        .then((res) => {
          if (res.data.error_code == 0) {
            let employer_detail = res.data.data;
            employer.employer_detail.passport_image_url =
              employer_detail.passport_image_url;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
          data.tmp = [employer];
        })
        .then(() => {
          data.printQRDialog = true;
        });
      break;
    case 2:
      manager_employer_detailAPI
        .getEmployerDetailInfo(employer.id)
        .then((res) => {
          if (res.data.error_code == 0) {
            let employer_detail = res.data.data;
            employer.employer_detail.passport_image_url =
              employer_detail.passport_image_url;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
          data.tmp = [employer];
        })
        .then(() => {
          data.printQRDialog = true;
        });
      break;
  }
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 80px">
      <Accordion class="accordion-custom p-col-12" :activeIndex="0">
        <AccordionTab>
          <template #header>
            <i class="pi pi-search p-mr-1"></i>
            <span>{{ i18n.$t("Employer").EmployerTable.Search }}</span>
          </template>
          <p style="text-align: left">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <InputText
                style="width: 150px"
                v-model="
                  data.employerFilters[
                    i18n.$t('Employer').EmployerTable.ID.Field
                  ].value
                "
                :placeholder="i18n.$t('Employer').EmployerTable.ID.Show"
              />
            </span>
            <span class="p-input-icon-left p-ml-1">
              <i class="pi pi-search" />
              <InputText
                style="width: 150px"
                v-model="
                  data.employerFilters[
                    i18n.$t('Employer').EmployerTable.Name.Field
                  ].value
                "
                :placeholder="i18n.$t('Employer').EmployerTable.Name.Show"
              />
            </span>
            <span class="p-input-icon-left p-ml-1">
              <i class="pi pi-search" />
              <InputText
                style="width: 150px"
                v-model="
                  data.employerFilters[
                    i18n.$t('Employer').EmployerDetailTable.IdentityCardNumber
                      .Field
                  ].value
                "
                :placeholder="
                  i18n.$t('Employer').EmployerDetailTable.IdentityCardNumber
                    .Show
                "
              />
            </span>
            <span
              class="p-input-icon-left p-ml-1"
              v-if="store.state.user.role == 1"
            >
              <i class="pi pi-search" />
              <Dropdown
                v-model="data.OrganisationSearch"
                :options="data.selectOrganisationList"
                optionLabel="name"
                :filter="true"
                :placeholder="
                  i18n.$t('Employer').EmployerTable.Organisation.Show
                "
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div>{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </Dropdown>
            </span>
            <span
              class="p-input-icon-left p-ml-1"
              v-if="store.state.user.role == 1 || store.state.user.role == 2"
            >
              <i class="pi pi-search" />
              <Dropdown
                v-model="data.DepartmentsSearch"
                :options="data.selectDepartmentsList"
                optionLabel="name"
                :filter="true"
                :placeholder="
                  i18n.$t('Employer').EmployerDetailTable.Department.Show
                "
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div>{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </Dropdown>
            </span>
            <span
              class="p-input-icon-left p-ml-1"
              v-if="store.state.user.role == 1"
            >
              <i class="pi pi-search" />
              <Dropdown
                v-model="data.NationalitysSearch"
                :options="data.selectNationalitysList"
                optionLabel="name"
                :filter="true"
                :placeholder="
                  i18n.$t('Employer').EmployerDetailTable.Nationality.Show
                "
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div>{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </Dropdown>
            </span>
            <span
              class="p-input-icon-left p-ml-1"
              v-if="store.state.user.role == 1"
            >
              <i class="pi pi-search" />
              <Dropdown
                v-model="data.WorkPermitSearch"
                :options="data.selectWorkPermitsList"
                optionLabel="name"
                :filter="true"
                :placeholder="
                  i18n.$t('Employer').EmployerTable.WorkPermits.Show
                "
                :showClear="true"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div>{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
              </Dropdown>
            </span>
          </p>
          <div class="p-col-12" style="text-align: right">
            <Button
              v-if="store.state.employer_search == 'apply_bouns'"
              class="p-button-success p-mr-1"
              icon="pi pi-check"
              :label="i18n.$t('Employer').EmployerTable.ChooseAll"
              @click="ChooseAllBouns()"
            />
            <Button
              v-if="store.state.employer_search == 'apply_bouns'"
              class="p-button-success p-mr-1"
              icon="pi pi-check"
              :label="i18n.$t('Employer').EmployerTable.CancelChooseAll"
              @click="CancelChooseAllBouns()"
            />

            <span
              class="p-component p-button p-button-info"
              @click="
                {
                  data.loading = true;
                  data.page = 1;
                  data.employers = [];
                  getEmployerList();
                }
              "
              >{{ i18n.$t("Employer").EmployerTable.SearchClick }}</span
            >
          </div>
          <div
            v-if="store.state.employer_search == 'apply_bouns'"
            class="p-col-12"
            style="text-align: left"
          >
            People:{{ store.state.bouns_employer.length }}
          </div>
          <p style="text-align: right" v-if="store.state.user.role == 2">
            <Button
              v-if="store.state.employer_search == 'salary_manager'"
              :label="i18n.$t('Employer').ExportExcelSalary"
              icon="pi pi-book"
              class="p-button-secondary p-mr-2"
              @click="ExportExcelSalary"
            />
          </p>
          <p style="text-align: right" v-if="store.state.user.role == 1">
            <!-- <label class="button-up p-mr-2 p-mt-2">
              <input
                id="upload_img"
                style="display: none"
                type="file"
                @change="readFile"
              />
              <i class="pi pi-plus"></i>
              {{ i18n.$t("Employer").EmployerTable.Excel }}
            </label> -->
            <Button
              v-if="
                store.state.user.role == 1 &&
                store.state.employer_search == 'employer'
              "
              :label="i18n.$t('Employer').ExportExcelEmployer"
              icon="pi pi-book"
              class="p-button-secondary p-mr-2"
              @click="ExportExcelEmployer"
            />
            <Button
              v-if="store.state.employer_search == 'employer'"
              :label="i18n.$t('Employer').EmployerTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="AddEmployer"
            />
            <Button
              v-if="
                store.state.user.role == 1 &&
                store.state.employer_search == 'salary_manager'
              "
              :label="i18n.$t('Employer').ExportExcelYear"
              icon="pi pi-book"
              class="p-button-secondary p-mr-2"
              @click="ExportExcelYear"
            />
            <Button
              v-if="
                store.state.user.role == 1 &&
                store.state.employer_search == 'salary_manager'
              "
              :label="i18n.$t('Employer').ExportExcelWater"
              icon="pi pi-book"
              class="p-button-secondary p-mr-2"
              @click="ExportExcelWater"
            />
            <Button
              v-if="
                store.state.user.role == 1 &&
                store.state.employer_search == 'salary_manager'
              "
              :label="i18n.$t('Employer').ExportExcelSalary"
              icon="pi pi-book"
              class="p-button-secondary p-mr-2"
              @click="ExportExcelSalary"
            />
            <Button
              v-if="
                store.state.user.role == 1 &&
                store.state.employer_search == 'salary_manager'
              "
              :label="i18n.$t('Employer').ImportExcelSalary"
              icon="pi pi-plus"
              class="p-button-info p-mr-2"
              @click="ImportExcelSalary"
            />
            <Button
              v-if="
                store.state.user.role == 1 &&
                store.state.employer_search == 'salary_manager'
              "
              :label="i18n.$t('Employer').ImportSalary"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="AddSettlement(true)"
            />
            <Button
              v-if="
                store.state.user.role == 1 &&
                store.state.employer_search == 'salary_manager'
              "
              :label="i18n.$t('Employer').ImportBouns"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="AddSettlement(false)"
            />
            <Button
              v-if="
                store.state.user.role == 1 &&
                store.state.employer_search == 'salary_manager'
              "
              :label="i18n.$t('Employer').ImportIncomeTax"
              icon="pi pi-plus"
              class="p-button-danger p-mr-2"
              @click="AddIncomeTax"
            />
            <Button
              v-if="
                (store.state.user.id == 2 || store.state.user.id == 5) &&
                store.state.employer_search == 'salary_manager'
              "
              :label="i18n.$t('Employer').EmployerTable.DeleteSalary"
              icon="pi pi-trash"
              class="p-button-danger p-mr-2"
              @click="DeleteSalary"
            />
          </p>
        </AccordionTab>
      </Accordion>
      <div
        class="p-grid"
        style="height: calc(100vh - 240px); overflow: auto"
        @scroll="handleScroll"
      >
        <div
          :key="index"
          v-for="(employer, index) in data.employers"
          class="p-col-12 p-md-4 p-lg-3"
          style="
            height: calc(calc(calc(100vh - 240px) / 2) + 55px);
            min-height: 320px;
          "
        >
          <div
            style="height: 25px; width: 100%; background-color: CornflowerBlue"
          >
            &nbsp;
          </div>
          <Card
            style="
              height: calc(calc(calc(100vh - 240px) / 2) + 20px);
              min-height: 290px;
              width: 100%;
            "
            class="p-text-left"
          >
            <template #content>
              <p>
                <span style="display: inline-block; width: 75px"
                  >{{ i18n.$t("Employer").EmployerTable.ID.Show }}:</span
                >{{ employer.id }}
              </p>
              <p>
                <span style="display: inline-block; width: 75px"
                  >{{ i18n.$t("Employer").EmployerTable.Name.Show }}:</span
                >{{ employer.name }}
              </p>
              <p>
                <span style="display: inline-block; width: 75px"
                  >{{
                    i18n.$t("Employer").EmployerTable.EnglishName.Show
                  }}:</span
                >{{ employer.english_name }}
              </p>
              <p class="p-mb-1 p-mt-1">
                <span style="display: inline-block; width: 75px"
                  >{{ i18n.$t("Employer").EmployerTable.Positions.Show }}:</span
                >
                <Chip
                  :key="position_idex"
                  class="p-mr-1"
                  v-for="(position, position_idex) in employer.positions"
                  :label="position.name"
                />
              </p>
              <p class="p-mb-1">
                <span style="display: inline-block; width: 75px"
                  >{{
                    i18n.$t("Employer").EmployerTable.WorkPermits.Show
                  }}:</span
                >
                <Chip
                  :key="work_permit_idex"
                  class="p-mr-1"
                  v-for="(
                    work_permit, work_permit_idex
                  ) in employer.work_permits"
                  :label="work_permit.name"
                />
              </p>
              <p>
                <span style="display: inline-block; width: 75px"
                  >{{ i18n.$t("Employer").EmployerTable.Status.Show }}:</span
                >{{
                  employer.status == 1
                    ? i18n.$t("Employer").EmployerTable.DisableAuth
                    : employer.status == 2
                    ? i18n.$t("Employer").EmployerTable.EnableAuth
                    : employer.status == 3
                    ? i18n.$t("Employer").EmployerTable.FreezeAuth
                    : i18n.$t("Employer").EmployerTable.UnknowAuth
                }}
              </p>
            </template>
            <template #footer>
              <div v-if="store.state.employer_search == 'employer'">
                <Button
                  class="p-button-danger"
                  icon="pi pi-times"
                  v-if="employer.status == 2 && store.state.user.role == 1"
                  :label="i18n.$t('Employer').EmployerTable.Fire"
                  @click="FireDialog(employer)"
                />
                <Button
                  class="p-button-info"
                  icon="pi pi-replay"
                  v-if="employer.status == 3 && store.state.user.role == 1"
                  :label="i18n.$t('Employer').EmployerTable.ReFire"
                  @click="ReFireDialog(employer)"
                />
                <Button
                  icon="pi pi-search"
                  :label="i18n.$t('Employer').EmployerTable.Detail"
                  style="margin-left: 0.5em"
                  @click="Detail(employer)"
                />
                <Button
                  icon="pi pi-qrcode"
                  class="p-button-secondary"
                  :label="i18n.$t('Employer').EmployerTable.QRCode"
                  style="margin-left: 0.5em"
                  @click="QRcodeShow(employer)"
                />
              </div>
              <div
                v-if="
                  store.state.employer_search == 'apply_bouns' &&
                  employer.role != 1 &&
                  employer.status == 2
                "
              >
                <Button
                  v-if="
                    store.state.bouns_employer
                      .map((e) => e.id)
                      .indexOf(employer.id) == -1
                  "
                  class="p-button-info"
                  icon="pi pi-check"
                  :label="i18n.$t('Employer').EmployerTable.Choose"
                  @click="ChooseBouns(employer)"
                />
                <Button
                  v-else
                  class="p-button-danger"
                  icon="pi pi-trash"
                  :label="i18n.$t('Employer').EmployerTable.Delete"
                  @click="RemoveChooseBouns(employer)"
                />
              </div>
              <div v-if="store.state.employer_search == 'apply_salary'">
                <Button
                  class="p-button-info"
                  icon="pi pi-check"
                  :label="i18n.$t('Employer').EmployerTable.Choose"
                  @click="Choose(employer)"
                />
              </div>
              <div v-if="store.state.employer_search == 'salary_manager'">
                <Button
                  class="p-button-info"
                  icon="pi pi-dollar"
                  :label="i18n.$t('Employer').EmployerTable.Manager"
                  @click="Manager(employer)"
                />
              </div>
            </template>
          </Card>
        </div>
        <div v-if="data.loading" class="p-grid p-col-12">
          <div
            :key="index"
            v-for="(item, index) in [1, 2, 3, 4, 5, 6, 7, 8]"
            class="p-col-12 p-md-4 p-lg-3"
          >
            <div class="custom-skeleton p-4 p-pt-4">
              <div class="p-pl-2 p-pr-2">
                <Skeleton width="100%" class="p-mb-2"></Skeleton>
                <Skeleton width="100%" class="p-mb-2"></Skeleton>
                <Skeleton width="100%" class="p-mb-2"></Skeleton>
                <Skeleton width="100%" class="p-mb-2"></Skeleton>
                <Skeleton width="100%" class="p-mb-2"></Skeleton>
              </div>
              <div class="p-grid p-mt-3 p-pl-3 p-mb-4">
                <Skeleton
                  class="p-mr-2"
                  width="5rem"
                  height="2.5rem"
                  animation="none"
                ></Skeleton>
                <Skeleton width="5rem" height="2.5rem"></Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Employer').AddEmployer.Title"
      :modal="true"
      class="p-fluid"
    >
      <!-- <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.UserName.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_employer.username"
          required="true"
        />
      </div> -->
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.PassWord.Show }}</label
        >
        <InputText
          id="name"
          v-model.trim="data.add_employer.password"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.Organisation.Show }}</label
        >
        <Dropdown
          v-model="data.AddEmpolyerOrganisationSearch"
          :options="data.selectOrganisationList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').EmployerTable.Organisation.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Positions.Show
        }}</label>
        <MultiSelect
          v-model="data.AddEmpolyerPositionsSearch"
          :options="data.selectPositionsList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.Positions.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerTable.Positions.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.WorkPermits.Show
        }}</label>
        <MultiSelect
          v-model="data.AddEmpolyerWorkPermitsSearch"
          :options="data.selectWorkPermitsList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.WorkPermits.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerTable.WorkPermits.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field p-d-none">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.Currency.Show }}</label
        >
        <MultiSelect
          v-model="data.AddEmpolyerCurrencySearch"
          :options="data.selectCurrencyList"
          optionLabel="name"
          :selectionLimit="1"
          :placeholder="i18n.$t('Employer').EmployerTable.Currency.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerTable.Currency.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.Name.Show }}</label
        >
        <InputText
          id="name"
          v-model.trim="data.add_employer.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.EnglishName.Show }}</label
        >
        <InputText
          id="name"
          v-model.trim="data.add_employer.english_name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.Salary.Show }}</label
        >
        <InputNumber
          v-model="data.add_employer.salary"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerTable.Role.Show }}</label
        >
        <Dropdown
          v-model="data.AddEmpolyerRoleSearch"
          :options="
            2030 <= store.state.user.id && store.state.user.id <= 2034
              ? [
                  {
                    id: 3,
                    name: 'Employee',
                  },
                ]
              : data.selectRoleList
          "
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').EmployerTable.Role.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Organisation').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Organisation').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddEmployer"
        />
      </template>
    </Dialog>
    <Sidebar
      style="width: 70%; min-width: 375px"
      v-model:visible="data.detailDialog"
      position="right"
    >
      <Info />
    </Sidebar>
    <Sidebar
      style="width: 70%; min-width: 375px"
      v-model:visible="data.salary_manager"
      position="right"
    >
      <Salary />
    </Sidebar>
    <!-- 匯出年度薪資視窗 -->
    <Dialog
      v-model:visible="data.export_excel_year"
      :style="{ width: '450px' }"
      :header="i18n.$t('Employer').ExportExcelYear"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Organisation.Show
        }}</label>
        <MultiSelect
          v-model="data.YearOrganisationsSearch"
          :options="data.selectOrganisationList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.Organisation.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerTable.Organisation.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.Departments.Show
        }}</label>
        <MultiSelect
          v-model="data.YearDepartmentsSearch"
          :options="data.selectDepartmentsList"
          optionLabel="name"
          :placeholder="
            i18n.$t('Employer').EmployerDetailTable.Departments.Show
          "
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerDetailTable.Departments.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SettlementOfMonth }}</label>
        <Calendar
          id="range"
          v-model="data.export_excel_year_settlement_of_month"
          dateFormat="yy/mm/dd"
          selectionMode="range"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').SettlementOfMonth"
          :showButtonBar="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').ReFireConfirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.export_excel_year = false"
        />
        <Button
          :label="i18n.$t('Employer').FireConfirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          :disabled="data.disalbe_year"
          @click="SaveExportExcelYear"
        />
      </template>
    </Dialog>
    <!-- 匯出水單視窗 -->
    <Dialog
      v-model:visible="data.export_excel_water"
      :style="{ width: '450px' }"
      :header="i18n.$t('Employer').ExportExcelWater"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SettlementOfMonth }}</label>
        <Calendar
          id="range"
          v-model="data.export_excel_water_settlement_of_month"
          dateFormat="yy/mm/dd"
          selectionMode="range"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').SettlementOfMonth"
          :showButtonBar="true"
        />
      </div>
      <div class="p-field p-d-none">
        <label for="name">{{ i18n.$t("Employer").SystemCurrency.Title }}</label>
        <Dropdown
          v-model="data.CurrencyForWater"
          :options="data.selectCurrencyForWaterList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').SystemCurrency.Title"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SalaryItems }}</label>
        <MultiSelect
          v-model="data.SalaryItemForWater"
          :options="data.selectSalaryItemList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').SalaryItems"
          :filter="true"
          display="chip"
        >
        </MultiSelect>
      </div>
      <div class="p-field p-d-none">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Organisation.Show
        }}</label>
        <MultiSelect
          v-model="data.ExportExcelWaterOrganisationSearch"
          :options="data.selectOrganisationList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.Organisation.Show"
          :filter="true"
          display="chip"
        >
        </MultiSelect>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').Confirm.Close"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.export_excel_water = false"
        />
        <Button
          :label="i18n.$t('Employer').Confirm.PrintWater"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveExportExcelWater(printDomLocal, true)"
        />
        <Button
          :label="i18n.$t('Employer').Confirm.PrintWaterLocal"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveExportExcelWater(printDomLocal, false)"
        />
      </template>
    </Dialog>
    <!-- 匯出薪資視窗 -->
    <Dialog
      v-model:visible="data.export_excel_salary"
      :style="{ width: '450px' }"
      :header="i18n.$t('Employer').ExportExcelSalary"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SettlementOfMonth }}</label>
        <Calendar
          id="range"
          v-model="data.export_excel_salary_settlement_of_month"
          dateFormat="yy/mm/dd"
          selectionMode="range"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').SettlementOfMonth"
          :showButtonBar="true"
        />
      </div>
      <!-- <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SystemCurrency.Title }}</label>
        <Dropdown
          v-model="data.CurrencyForExcelSalary"
          :options="data.selectCurrencyForWaterList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').SystemCurrency.Title"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div> -->
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SalaryItems }}</label>
        <MultiSelect
          v-model="data.SalaryItemForExcelSalary"
          :options="data.selectSalaryItemList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').SalaryItems"
          :filter="true"
          display="chip"
        >
        </MultiSelect>
      </div>
      <div v-if="store.state.user.role == 1" class="p-field p-d-none">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Organisation.Show
        }}</label>
        <MultiSelect
          v-model="data.ExportExcelSalaryOrganisationSearch"
          :options="data.selectOrganisationList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.Organisation.Show"
          :filter="true"
          display="chip"
        >
        </MultiSelect>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').ReFireConfirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.export_excel_salary = false"
        />
        <Button
          :label="i18n.$t('Employer').FireConfirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveExportExcelSalary"
        />
      </template>
    </Dialog>
    <!-- 匯入Excel視窗 -->
    <Dialog
      v-model:visible="data.import_excel_salary"
      :style="{ width: '450px' }"
      :header="i18n.$t('Employer').ImportExcelSalary"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Organisation.Show
        }}</label>
        <Dropdown
          v-model="data.ExcelOrganisationSearch"
          :options="data.selectOrganisationList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').EmployerTable.Organisation.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field p-d-none">
        <label for="name">{{
          i18n.$t("Salary").SalaryTable.SalaryItemId.Show
        }}</label>
        <Dropdown
          v-model="data.ExcelSalaryItemSearch"
          :options="data.selectSalaryItemList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Salary').SalaryTable.SalaryItemId.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field p-d-none">
        <label for="name">{{
          i18n.$t("Salary").SalaryTable.CurrencyName.Show
        }}</label>
        <Dropdown
          v-model="data.ExcelCurrencySearch"
          :options="data.selectCurrencyList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Salary').SalaryTable.CurrencyName.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SettlementOfMonth }}</label>
        <Calendar
          id="range"
          v-model="data.excel_settlement_of_month"
          dateFormat="yy/mm/dd"
          :maxDate="
            store.state.user.id == 5 ||
            store.state.user.id == 11 ||
            store.state.user.id == 14 ||
            store.state.user.id == 15
              ? ''
              : data.maxDate
          "
          :minDate="store.state.user.id == 5 ? '' : data.minDate"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').SettlementOfMonth"
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").PayDate }}</label>
        <Calendar
          id="range"
          v-model="data.excel_pay_date"
          view="month"
          dateFormat="yy/mm"
          :minDate="store.state.user.id == 5 ? '' : getLastMonthNew()"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').PayDate"
          :showButtonBar="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').ReFireConfirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.import_excel_salary = false"
        />
        <label class="p-button-text p-button p-component">
          <input
            id="upload_img"
            style="display: none"
            type="file"
            @change="SaveExcelSalary"
          />
          <i class="pi pi-check"></i>
          {{ i18n.$t("Employer").ImportSalary }}
        </label>
      </template>
    </Dialog>
    <!-- 復職確認視窗 -->
    <Dialog
      v-model:visible="data.fireDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Employer').FireConfirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.employer"
          >{{ i18n.$t("Employer").FireConfirm.Content }}
          <b>{{ data.employer.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').FireConfirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.fireDialog = false"
        />
        <Button
          :label="i18n.$t('Employer').FireConfirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="Fire"
        />
      </template>
    </Dialog>
    <!-- 離職視窗 -->
    <Dialog
      v-model:visible="data.refireDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Employer').ReFireConfirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.employer"
          >{{ i18n.$t("Employer").ReFireConfirm.Content }}
          <b>{{ data.employer.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').ReFireConfirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.refireDialog = false"
        />
        <Button
          :label="i18n.$t('Employer').ReFireConfirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="ReFire"
        />
      </template>
    </Dialog>
    <!-- 匯入薪資視窗 -->
    <Dialog
      v-model:visible="data.AddSettlement"
      :style="{ width: '550px' }"
      :header="i18n.$t('Employer').ImportSalary"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Salary").SalaryTable.SalaryItemId.Show
        }}</label>
        <Dropdown
          v-model="data.SalaryItemSearch"
          :options="data.selectSalaryItemList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Salary').SalaryTable.SalaryItemId.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SettlementOfMonth }}</label>
        <Calendar
          id="range"
          v-model="data.settlement_of_month"
          :view="data.check_input_salary ? 'date' : 'month'"
          :dateFormat="data.check_input_salary ? 'yy/mm/dd' : 'yy/mm'"
          :selectionMode="data.check_input_salary ? 'range' : 'single'"
          :maxDate="data.maxDate"
          :minDate="data.minDate"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').SettlementOfMonth"
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").PayDate }}</label>
        <Calendar
          id="range"
          v-model="data.pay_date"
          view="month"
          dateFormat="yy/mm"
          :minDate="new Date()"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').PayDate"
          :showButtonBar="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').ReFireConfirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.AddSettlement = false"
        />
        <Button
          v-if="!data.check_input_salary"
          :label="i18n.$t('Employer').SettlementBorrow"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveAddSettlementBorrow"
        />
        <Button
          v-if="data.check_input_salary"
          :label="i18n.$t('Employer').SettlementSalary"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveAddSettlementSalary"
        />
        <Button
          v-if="!data.check_input_salary"
          :label="i18n.$t('Employer').SettlementBouns"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveAddSettlementBouns"
        />
      </template>
    </Dialog>
    <!-- 匯入所得稅視窗 -->
    <Dialog
      v-model:visible="data.AddIncomeTax"
      :style="{ width: '450px' }"
      :header="i18n.$t('Employer').ImportIncomeTax"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SalaryItemsHealthy }}</label>
        <Dropdown
          v-model="data.SalaryItemHealthySearch"
          :options="data.selectSalaryItemList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').SalaryItemsHealthy"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SalaryItemsTax }}</label>
        <Dropdown
          v-model="data.SalaryItemTaxSearch"
          :options="data.selectSalaryItemList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').SalaryItemsTax"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SettlementOfMonth }}</label>
        <Calendar
          id="range"
          v-model="data.settlement_of_month"
          dateFormat="yy/mm/dd"
          :maxDate="data.maxDate"
          :minDate="data.minDate"
          selectionMode="range"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').SettlementOfMonth"
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").PayDate }}</label>
        <Calendar
          id="range"
          v-model="data.pay_date"
          view="month"
          dateFormat="yy/mm"
          :minDate="new Date()"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').PayDate"
          :showButtonBar="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').ReFireConfirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.AddIncomeTax = false"
        />
        <Button
          :label="i18n.$t('Employer').SettlementIncomeTax"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveAddIncomeTax"
        />
      </template>
    </Dialog>

    <!-- 刪除薪資視窗 -->
    <Dialog
      v-model:visible="data.DeleteSalary"
      :style="{ width: '550px' }"
      :header="i18n.$t('Employer').EmployerTable.DeleteSalary"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Salary").SalaryTable.SalaryItemId.Show
        }}</label>
        <Dropdown
          v-model="data.SalaryItemSearch"
          :options="data.selectSalaryItemList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Salary').SalaryTable.SalaryItemId.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Employer").SettlementOfMonth }}</label>
        <Calendar
          id="range"
          v-model="data.settlement_of_month"
          dateFormat="yy/mm/dd"
          selectionMode="range"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').SettlementOfMonth"
          :showButtonBar="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Employer').ReFireConfirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.DeleteSalary = false"
        />
        <Button
          :label="i18n.$t('Employer').EmployerTable.DeleteSalary"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveDeleteSalary"
        />
      </template>
    </Dialog>
  </div>
  <div class="p-d-none">
    <div ref="printDom">
      <PrintWater />
    </div>
  </div>
  <div class="p-d-none">
    <div ref="printDomLocal">
      <PrintWaterLocal />
    </div>
  </div>
  <!-- 列印視窗 -->
  <Dialog
    v-model:visible="data.printQRDialog"
    :style="{ width: '900px', minWidth: '350px' }"
    :header="i18n.$t('Employer').PrintEmployerQR.Title"
    :modal="true"
    class="p-fluid"
  >
    <div ref="printDomQR" style="display: flex; width: 100%">
      <div style="width: 100%; display: flex; flex-wrap: wrap">
        <div
          class="p-mt-1"
          :key="index"
          style="
            width: 385px;
            height: 250px;
            border: 1px solid;
            border-radius: 20px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 0.5px;
            margin-bottom: 0.5px;
            align-items: center !important;
          "
          v-for="(item, index) in data.tmp"
        >
          <div
            style="
              width: 50%;
              flex: 0 0 auto;
              padding: 0.5rem;
              align-items: center !important;
              flex-wrap: wrap !important;
              display: flex !important;
              font-size: 16px;
              font-weight: 800;
            "
          >
            <!-- <Avatar
              style="width: 80px; height: 80px"
              :image="item.employer_detail.passport_image_url"
              class="mr-2 p-mb-1"
              shape="circle"
            /> -->
            <div
              style="
                margin-bottom: 0.2rem !important;
                border-radius: 50%;
                background-color: transparent;
                box-sizing: border-box;
                width: 80px;
                height: 80px;
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                font-size: 1rem;
              "
            >
              <img
                style="
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  overflow-clip-margin: content-box;
                  overflow: clip;
                "
                :src="item.employer_detail.passport_image_url"
              />
            </div>
            <label for="english" style="width: 100%"
              >{{ i18n.$t("Employer").EmployerTable.ID.Show }}:{{
                item.id
              }}</label
            >
            <label for="english" style="width: 100%"
              >{{ i18n.$t("Employer").EmployerTable.Name.Show }}:{{
                item.name
              }}</label
            >
            <label for="weight" style="width: 100%"
              >{{ i18n.$t("Employer").EmployerTable.EnglishName.Show }}:{{
                item.english_name
              }}</label
            >
          </div>
          <div
            style="
              width: 40%;
              flex: 0 0 auto;
              padding: 0.6rem;
              align-items: center !important;
              flex-wrap: wrap !important;
              display: flex !important;
              justify-content: end;
            "
          >
            <img style="height: 70px; width: 70px" :src="africa_logo" />
            <QRCodeVue3
              style="margin-top: 15px"
              :width="140"
              :height="140"
              :qrOptions="{
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'Q',
              }"
              :imageOptions="{
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0,
              }"
              :dotsOptions="{
                type: 'classy',
                color: '#26249a',
                gradient: {
                  type: 'linear',
                  rotation: 0,
                  colorStops: [
                    { offset: 0, color: '#26249a' },
                    { offset: 1, color: '#26249a' },
                  ],
                },
              }"
              :value="
                JSON.stringify({
                  qr_type: 'e',
                  id: item.id,
                  /*
                  bed_number: item.bed_number,
                  lot_number: item.lot_number,
                  */
                  // purchase_order_id: item.purchase_order_id,
                  // item_id: item.item_id,
                  /*
                  purchase_size_item_id: item.purchase_size_item_id,
                  */
                  // purchase_size_item_name: item.purchase_size_item_name,
                  /*
                  purchase_main_card_name: item.purchase_main_card_name,
                  cylinder_number: item.cylinder_number,
                  report_count: item.report_count,
                  */
                  // color: item.color,
                  // description: item.description,
                })
              "
            />
            <!-- <div style="width: 100%; text-align: right">
              &nbsp;&nbsp;ID:{{ item.id }}<br />
            </div> -->
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('Employer').PrintEmployerQR.Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.printQRDialog = false"
      />
      <Button
        :label="i18n.$t('Employer').PrintEmployerQR.Print"
        icon="pi pi-check"
        class="p-button-text"
        @click="printHTML(printDomQR)"
      />
    </template>
  </Dialog>
  <DataTable
    class="p-d-none"
    :value="data.salary_year.data"
    ref="dt_salary_year"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :header="i18n.$t('Salary').SalaryTable.RecipientEmployerId.Show"
      :field="i18n.$t('Salary').SalaryTable.RecipientEmployerId.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.RecipientEmployerName.Show"
      :field="i18n.$t('Salary').SalaryTable.RecipientEmployerName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.RecipientEmployerEnglishName.Show"
      :field="i18n.$t('Salary').SalaryTable.RecipientEmployerEnglishName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.OrganisationName.Show"
      :field="i18n.$t('Salary').SalaryTable.OrganisationName.Field"
    >
    </Column>

    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.DepartmentName.Show"
      :field="i18n.$t('Salary').SalaryTable.DepartmentName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.PositionName.Show"
      :field="i18n.$t('Salary').SalaryTable.PositionName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.NationalityName.Show"
      :field="i18n.$t('Salary').SalaryTable.NationalityName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.Sex.Show"
      :field="i18n.$t('Salary').SalaryTable.Sex.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.Birthday.Show"
      :field="i18n.$t('Salary').SalaryTable.Birthday.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.StartWorking.Show"
      :field="i18n.$t('Salary').SalaryTable.StartWorking.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.Salary.Show"
      :field="i18n.$t('Salary').SalaryTable.Salary.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.IdentityCardNumber.Show"
      :field="i18n.$t('Salary').SalaryTable.IdentityCardNumber.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.IncomeTaxNumber.Show"
      :field="i18n.$t('Salary').SalaryTable.IncomeTaxNumber.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.LocalBank.Show"
      :field="i18n.$t('Salary').SalaryTable.LocalBank.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.LocalBranchCode.Show"
      :field="i18n.$t('Salary').SalaryTable.LocalBranchCode.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.LocalAccountNumber.Show"
      :field="i18n.$t('Salary').SalaryTable.LocalAccountNumber.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.ForeignBank.Show"
      :field="i18n.$t('Salary').SalaryTable.ForeignBank.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.ForeignBranchCode.Show"
      :field="i18n.$t('Salary').SalaryTable.ForeignBranchCode.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.ForeignAccountNumber.Show"
      :field="i18n.$t('Salary').SalaryTable.ForeignAccountNumber.Field"
    >
    </Column>

    <Column
      :key="index"
      v-for="(item, index) in data.export_excel_year_tmp"
      class="p-col-2"
      :header="item.name"
      :field="item.name"
    >
    </Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_salary.data"
    ref="dt_salary"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :header="i18n.$t('Salary').SalaryTable.RecipientEmployerId.Show"
      :field="i18n.$t('Salary').SalaryTable.RecipientEmployerId.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.OrganisationName.Show"
      :field="i18n.$t('Salary').SalaryTable.OrganisationName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.RecipientEmployerName.Show"
      :field="i18n.$t('Salary').SalaryTable.RecipientEmployerName.Field"
    >
    </Column>

    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.RecipientEmployerEnglishName.Show"
      :field="i18n.$t('Salary').SalaryTable.RecipientEmployerEnglishName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="
        i18n.$t('Salary').SalaryTable.RecipientEmployerPaymentMethod.Show
      "
      :field="
        i18n.$t('Salary').SalaryTable.RecipientEmployerPaymentMethod.Field
      "
    >
    </Column>
    <Column
      class="p-col-2"
      :header="
        i18n.$t('Salary').SalaryTable.RecipientEmployerLocalBranchCode.Show
      "
      :field="
        i18n.$t('Salary').SalaryTable.RecipientEmployerLocalBranchCode.Field
      "
    >
    </Column>
    <Column
      class="p-col-2"
      :header="
        i18n.$t('Salary').SalaryTable.RecipientEmployerLocalAccountNumber.Show
      "
      :field="
        i18n.$t('Salary').SalaryTable.RecipientEmployerLocalAccountNumber.Field
      "
    >
    </Column>

    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.SettlementOfMonth.Show"
      :field="i18n.$t('Salary').SalaryTable.SettlementOfMonth.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.PayDate.Show"
      :field="i18n.$t('Salary').SalaryTable.PayDate.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.Dollar.Show"
      :field="i18n.$t('Salary').SalaryTable.Dollar.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.Hours.Show"
      :field="i18n.$t('Salary').SalaryTable.Hours.Field"
    >
    </Column>
    <Column
      class="p-col-1"
      :header="i18n.$t('Salary').SalaryTable.SalaryItemName.Show"
      :field="i18n.$t('Salary').SalaryTable.SalaryItemName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.CurrencyName.Show"
      :field="i18n.$t('Salary').SalaryTable.CurrencyName.Field"
    >
    </Column>
    <!-- <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.ExchangeRate.Show"
      :field="i18n.$t('Salary').SalaryTable.ExchangeRate.Field"
    >
    </Column> -->
    <!-- <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.ExchangeDollar.Show"
      :field="i18n.$t('Salary').SalaryTable.ExchangeDollar.Field"
    >
    </Column> -->
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.Description.Show"
      :field="i18n.$t('Salary').SalaryTable.Description.Field"
    >
    </Column>
  </DataTable>
  <DataTable
    class="p-d-none"
    :value="data.excel_employer.data"
    ref="dt_employer"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.ID.Show"
      :field="i18n.$t('Employer').EmployerTable.ID.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.UserName.Show"
      :field="i18n.$t('Employer').EmployerTable.UserName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.Name.Show"
      :field="i18n.$t('Employer').EmployerTable.Name.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.EnglishName.Show"
      :field="i18n.$t('Employer').EmployerTable.EnglishName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.Sex.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.Sex.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.Departments.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.Departments.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.Positions.Show"
      :field="i18n.$t('Employer').EmployerTable.Positions.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.Salary.Show"
      :field="i18n.$t('Employer').EmployerTable.Salary.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.StartWorking.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.StartWorking.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.BirthDay.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.BirthDay.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.IdentityCardNumber.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.IdentityCardNumber.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.IncomeTaxNumber.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.IncomeTaxNumber.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.Phone.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.Phone.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.WorkPermits.Show"
      :field="i18n.$t('Employer').EmployerTable.WorkPermits.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.Currency.Show"
      :field="i18n.$t('Employer').EmployerTable.Currency.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.Status.Show"
      :field="i18n.$t('Employer').EmployerTable.Status.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.OrganisationName.Show"
      :field="i18n.$t('Employer').EmployerTable.OrganisationName.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerTable.Role.Show"
      :field="i18n.$t('Employer').EmployerTable.Role.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.Nationalitys.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.Nationalitys.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.LocalBank.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.LocalBank.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.LocalAccountNumber.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.LocalAccountNumber.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.LocalBranchCode.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.LocalBranchCode.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.ForeignBank.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.ForeignBank.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="
        i18n.$t('Employer').EmployerDetailTable.ForeignAccountNumber.Show
      "
      :field="
        i18n.$t('Employer').EmployerDetailTable.ForeignAccountNumber.Field
      "
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.ForeignBranchCode.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.ForeignBranchCode.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="
        i18n.$t('Employer').EmployerDetailTable.WorkCardEffectiveDate.Show
      "
      :field="
        i18n.$t('Employer').EmployerDetailTable.WorkCardEffectiveDate.Field
      "
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.WorkCardExpiryDate.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.WorkCardExpiryDate.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.PassportExpireDate.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.PassportExpireDate.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.Email.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.Email.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.PassportNumber.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.PassportNumber.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.WorkCardNumber.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.WorkCardNumber.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="
        i18n.$t('Employer').EmployerDetailTable.ContractEffectiveDate.Show
      "
      :field="
        i18n.$t('Employer').EmployerDetailTable.ContractEffectiveDate.Field
      "
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Employer').EmployerDetailTable.ContractExpireDate.Show"
      :field="i18n.$t('Employer').EmployerDetailTable.ContractExpireDate.Field"
    >
    </Column>
  </DataTable>
</template>

<style lang="scss">
.custom-skeleton {
  border: 1px solid var(--surface-border);
  border-radius: 4px;
  ul {
    list-style: none;
  }
}
p {
  line-height: 1.2;
  margin: 0;
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #495057;
  border-radius: 16px;
}
</style>
