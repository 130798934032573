<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  onUpdated,
  toRefs,
  computed,
  watch,
} from "vue";
import { dateFormate } from "../tool/formate";
import { useStore } from "vuex";
const store = useStore();
// const i18n = useI18n();
// const toast = useToast();
const data = defineProps({
  tmp_salary: {
    type: Array,
    default: [],
  },
  // tmp_employer: {
  //   type: Array,
  //   default: [],
  // },
});

const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
</script>

<template>
  <div
    style="
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    "
  >
    <div
      style="
        width: 100%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div style="width: 100%; text-align: center">
        <h1>
          {{
            data.tmp_salary.length == 0
              ? ""
              : data.tmp_salary[0].organisation_name
          }}
          <br />RATE RECOMMENDATIONS/調薪單
        </h1>
      </div>
    </div>

    <div style="border: 1px solid black; width: 100%">
      <div
        style="
          font-size: 12px;
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          &nbsp; 系統號碼 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 7.9%; text-align: center"
        >
          &nbsp; 新員工編號 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 10%; text-align: center"
        >
          &nbsp; 名字 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 10%; text-align: center"
        >
          &nbsp; 部門 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          &nbsp; 職級 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          &nbsp; 出生年月日 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          &nbsp; 身分證號碼 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          &nbsp; 到職日 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          &nbsp; 生效日 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          &nbsp; 舊時薪 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 9%; text-align: center"
        >
          &nbsp; 新時薪 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 6%; text-align: center"
        >
          &nbsp; 時薪差額 &nbsp;
        </div>
      </div>
      <div
        style="
          font-size: 12px;
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          System ID No.
        </div>
        <div
          style="border-right: 1px solid black; width: 7.9%; text-align: center"
        >
          New Clock No.
        </div>
        <div
          style="border-right: 1px solid black; width: 10%; text-align: center"
        >
          Name
        </div>
        <div
          style="border-right: 1px solid black; width: 10%; text-align: center"
        >
          Department
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          Designations
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          Date of birth
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          ID No.
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          StartWorking
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          Effective Date
        </div>
        <div
          style="border-right: 1px solid black; width: 8%; text-align: center"
        >
          Old Hourly Rate
        </div>
        <div
          style="border-right: 1px solid black; width: 9%; text-align: center"
        >
          New Hourly Rate
        </div>
        <div
          style="border-right: 1px solid black; width: 6%; text-align: center"
        >
          Increment
        </div>
      </div>
      <div
        :key="index"
        v-for="(item, index) in data.tmp_salary"
        style="
          font-size: 12px;
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 8%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{ item.apply_employer_id }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 7.9%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{ item.employer_name }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 10%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{ item.employer_english_name }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 10%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{ item.department }}
          <!-- <span
            :key="department_index"
            v-for="(
              department_item, department_index
            ) in data.tmp_employer.find(
              (person) => person.employer_id === item.apply_employer_id
            ).departments != undefined
              ? data.tmp_employer.find(
                  (person) => person.employer_id === item.apply_employer_id
                ).departments
              : []"
          >
            {{
              department_index == 0
                ? department_item.name
                : "," + department_item.name
            }}
          </span> -->
          <!-- <div
            v-if="
              data.tmp_employer.find(
                (person) => person.employer_id === item.apply_employer_id
              ).departments != undefined
            "
          >
            <span
              :key="department_index"
              v-for="(
                department_item, department_index
              ) in data.tmp_employer.find(
                (person) => person.employer_id === item.apply_employer_id
              ).departments"
            >
              {{
                department_index == 0
                  ? department_item.name
                  : "," + department_item.name
              }}
            </span>
          </div> -->
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 8%;
            text-align: center;
            word-break: break-all;
          "
        >
          <span
            :key="position_index"
            v-for="(position_item, position_index) in item.positions"
          >
            {{
              position_index == 0
                ? position_item.name
                : "," + position_item.name
            }}
          </span>
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 8%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{
            dateFormate(
              // data.tmp_employer.find(
              //   (person) => person.employer_id === item.apply_employer_id
              // ).birthday
              item.birthday,
              "YYYY-MM-DD"
            )
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 8%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{
            // data.tmp_employer.find(
            //   (person) => person.employer_id === item.apply_employer_id
            // ).identity_card_number
            item.identity_card_number
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 8%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{
            dateFormate(
              // data.tmp_employer.find(
              //   (person) => person.employer_id === item.apply_employer_id
              // ).start_working,
              item.start_working,
              "YYYY-MM-DD"
            )
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 8%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{ dateFormate(item.effective_date, "YYYY-MM-DD") }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 8%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{ item.before_salary }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 9%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{ item.new_salary }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 6%;
            text-align: center;
            word-break: break-all;
          "
        >
          {{ item.add_salary }}
        </div>
      </div>
      <div
        style="
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          text-align: center;
        "
      >
        <div
          style="
            width: 98%;
            text-align: left;
            align-items: center;
            line-height: 45px;
          "
        >
          &nbsp; Prepared by: &nbsp;
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            width: 100%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              width: 100%;
              height: 100px;
              text-align: left;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              font-size: 14px;
              font-weight: bold;
            "
          >
            <div
              style="
                width: 20%;
                text-align: center;
                padding-bottom: 5px;
                padding-top: 5px;
                align-self: flex-end;
              "
            >
              <span style="border-top: 0.2rem solid black">Prepared by </span
              ><br />
            </div>
            <div
              style="
                width: 20%;
                text-align: center;
                padding-bottom: 5px;
                padding-top: 5px;
                align-self: flex-end;
              "
            >
              <span style="border-top: 0.2rem solid black">Factory Manager</span
              ><br />
            </div>
            <div
              style="
                width: 20%;
                text-align: center;
                padding-bottom: 5px;
                padding-top: 5px;
                align-self: flex-end;
              "
            >
              <span style="border-top: 0.2rem solid black">Personnel Dept</span
              ><br />
            </div>
            <div
              style="
                width: 20%;
                text-align: center;
                padding-bottom: 5px;
                padding-top: 5px;
                align-self: flex-end;
              "
            >
              <span style="border-top: 0.2rem solid black">Admin Director</span
              ><br />
            </div>
            <div
              style="
                width: 20%;
                text-align: center;
                padding-bottom: 5px;
                padding-top: 5px;
                align-self: flex-end;
              "
            >
              <span style="border-top: 0.2rem solid black">Director</span><br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
