import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Bouns {
  getBounsInfo(id) {
    return axios.get(
      `/api/manager/bonus/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getBounsList(
    apply_employer_id,
    apply_employer_name,
    status,
    apply_date_begin,
    apply_date_end,
    organisation_id,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/bonus/list`,
      {
        params: {
          apply_employer_id: apply_employer_id,
          apply_employer_name: apply_employer_name,
          apply_date_begin: apply_date_begin,
          apply_date_end: apply_date_end,
          status: status,
          organisation_id: organisation_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getEasyBounsList(apply_date_begin, apply_date_end, page, page_num) {
    return axios.get(
      `/api/manager/bonus/list`,
      {
        params: {
          apply_date_begin: apply_date_begin,
          apply_date_end: apply_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchBouns(datas) {
    return axios.post(
      `/api/manager/bonus/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  editBouns(
    id,
    dollar,
    currency_id,
    reason,
    status,
    audit_employer_id,
    apply_employer_id,
    organisation_id
  ) {
    return axios.put(
      `/api/manager/bonus/`,
      {
        id: id,
        dollar: dollar,
        currency_id: currency_id,
        reason: reason,
        status: status,
        audit_employer_id: audit_employer_id,
        apply_employer_id: apply_employer_id,
        organisation_id: organisation_id,
      },
      config
    );
  }
  editBounsStatus(id, status, reason, dollar) {
    return axios.put(
      `/api/manager/bonus/`,
      {
        id: id,
        status: status,
        reason: reason,
        dollar: dollar,
      },
      config
    );
  }
  editBounsBatchStatus(ids, status, pay_date, salary_item_id, settle_date) {
    return axios.put(
      `/api/manager/bonus/batch/import`,
      {
        ids: ids,
        status: status,
        pay_date: pay_date,
        salary_item_id: salary_item_id,
        settle_date: settle_date,
      },
      config
    );
  }
}
