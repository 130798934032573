export function dateFormate(dateString, formate) {
  let date_new = new Date(dateString);
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  if (GMT_8 % 1 != 0) {
    date.setMinutes(30 + date.getMinutes());
  }
  let formatted_date = "";
  switch (formate) {
    case "YYYY-MM-DD":
      // formatted_date =
      //   date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      formatted_date =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
      break;
    case "YYYY-MM":
      formatted_date = date.getFullYear() + "-" + (date.getMonth() + 1);
      break;
    case "YYYY年MM月DD日":
      formatted_date =
        date.getFullYear() +
        "年" +
        (date.getMonth() + 1) +
        "月" +
        date.getDate() +
        "日";
      break;
    case "DD-MM-YYYY":
      formatted_date =
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
      break;
    case "hh:mm":
      formatted_date =
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
      break;
    case "normal":
      formatted_date = date;
      break;
    case "normal hh:mm":
      formatted_date =
        date_new.getHours() +
        ":" +
        (date_new.getMinutes() < 10
          ? "0" + date_new.getMinutes()
          : date_new.getMinutes());
      break;
    case "normal YYYY-MM-DD":
      formatted_date =
        date_new.getFullYear() +
        "-" +
        (date_new.getMonth() + 1 < 10
          ? "0" + (date_new.getMonth() + 1)
          : date_new.getMonth() + 1) +
        "-" +
        (date_new.getDate() < 10
          ? "0" + date_new.getDate()
          : date_new.getDate());
      break;
    case "normal YYYY-MM-DD hh:mm":
      formatted_date =
        date_new.getFullYear() +
        "-" +
        (date_new.getMonth() + 1 < 10
          ? "0" + (date_new.getMonth() + 1)
          : date_new.getMonth() + 1) +
        "-" +
        (date_new.getDate() < 10
          ? "0" + date_new.getDate()
          : date_new.getDate()) +
        " " +
        date_new.getHours() +
        ":" +
        (date_new.getMinutes() < 10
          ? "0" + date_new.getMinutes()
          : date_new.getMinutes());
      break;
    case "normal_show":
      const GMT_8 =
        date_new.getHours() + (-8 - date_new.getTimezoneOffset() / 60);
      date_new.setHours(GMT_8);
      if (GMT_8 % 1 != 0) {
        date_new.setMinutes(30 + date_new.getMinutes());
      }
      // console.log(date);
      // console.log(date_new);

      formatted_date = date_new;
      break;
  }
  return formatted_date;
}

export function convertToUTC8(dateTime) {
  // 获取原始日期时间的时区偏移（单位：分钟）
  var offsetMinutes = dateTime.getTimezoneOffset();

  // 计算要加到原始时间上的分钟数，以使其在 UTC+8 时区中
  var targetOffset = offsetMinutes - -8 * 60; // UTC+8 时区的偏移是 -8 小时

  // 创建新的 Date 对象，将偏移量添加到原始时间上
  var targetDateTime = new Date(dateTime.getTime() - targetOffset * 60 * 1000);

  // 返回转换后的日期时间字符串
  return targetDateTime.toISOString();
}

export function getCurrentTimeZone() {
  var offsetMinutes = new Date().getTimezoneOffset();
  var offsetHours = Math.abs(offsetMinutes / 60);
  var offsetSign = offsetMinutes > 0 ? "-" : "+";

  // 将偏移量转换为格式：(+/-)HHMM
  var formattedOffset = offsetSign + pad(offsetHours, 2);

  return formattedOffset;
}

function pad(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}
