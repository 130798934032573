<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import {
  dateFormate,
  getCurrentTimeZone,
  convertToUTC8,
} from "../../tool/formate";
import Calendar from "primevue/calendar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Shift from "../../api/salary/admin/shift";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let shiftAPI = new Shift();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  selectShiftClassifyStatusList: [
    {
      id: 1,
      name: i18n.$t("Shift").ClassNight.NormalShiftClassifyStatus,
    },
    {
      id: 2,
      name: i18n.$t("Shift").ClassNight.NightShiftClassifyStatus,
    },
  ],
  selectShiftTypeStatusList: [
    {
      id: 1,
      name: i18n.$t("Shift").Class.Normal,
    },
    {
      id: 2,
      name: i18n.$t("Shift").Class.Add,
    },
  ],
  EditShiftTypeStatusearch: "",
  AddShiftTypeStatusearch: "",
  AddShiftClassifyStatusearch: "",
  EditShiftClassifyStatusearch: "",
  add_shift: {
    id: "",
    name: "",
    salary_multiplier: "",
    classify_status: "",
    type_status: "",
    remark: "",
    start_time: "",
    end_time: "",
    break_time_begin: "",
    break_time_end: "",
  },
  shiftLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  shifts: { data: null },
  shift: null,
  page: 1,
  page_num: 10,
  selectedShifts: null,
  shiftFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    type_status: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getShiftList();
  }
});

function getShiftList() {
  CheckUser();
  shiftAPI
    .getShiftList(
      data.shiftFilters.id.value,
      data.shiftFilters.name.value,
      data.shiftFilters.type_status.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.shifts.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.shiftLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.shiftLoading = false;
      }
    });
}

function saveEditShift() {
  var start = new Date(
    2016,
    8,
    10,
    data.shift.start_time.getHours(),
    data.shift.start_time.getMinutes(),
    0
  );
  var end = new Date(
    2016,
    8,
    10,
    data.shift.end_time.getHours(),
    data.shift.end_time.getMinutes(),
    0
  );
  var break_time_begin = "";
  if (
    data.shift.break_time_begin != null &&
    data.shift.break_time_begin != ""
  ) {
    break_time_begin = new Date(
      2016,
      8,
      10,
      data.shift.break_time_begin.getHours(),
      data.shift.break_time_begin.getMinutes(),
      0
    );
  }
  var break_time_end = "";
  if (data.shift.break_time_end != null && data.shift.break_time_end != "") {
    break_time_end = new Date(
      2016,
      8,
      10,
      data.shift.break_time_end.getHours(),
      data.shift.break_time_end.getMinutes(),
      0
    );
  }
  shiftAPI
    .editShift(
      data.shift.id,
      data.shift.name,
      String(data.shift.salary_multiplier),
      data.shift.classify_status,
      data.shift.type_status,
      convertToUTC8(start),
      convertToUTC8(end),
      break_time_begin == ""
        ? break_time_begin
        : convertToUTC8(break_time_begin),
      break_time_end == "" ? break_time_end : convertToUTC8(break_time_end),
      data.shift.remark
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Shift").EditSuccess,
          life: 3000,
        });

        data.shiftLoading = true;
        getShiftList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddShift() {
  var start = new Date(
    2016,
    8,
    10,
    data.add_shift.start_time.getHours(),
    data.add_shift.start_time.getMinutes(),
    0
  );
  var end = new Date(
    2016,
    8,
    10,
    data.add_shift.end_time.getHours(),
    data.add_shift.end_time.getMinutes(),
    0
  );
  var break_time_begin = "";

  if (
    data.add_shift.break_time_begin != null &&
    data.add_shift.break_time_begin != ""
  ) {
    break_time_begin = new Date(
      2016,
      8,
      10,
      data.add_shift.break_time_begin.getHours(),
      data.add_shift.break_time_begin.getMinutes(),
      0
    );
  }

  var break_time_end = "";
  if (
    data.add_shift.break_time_end != null &&
    data.add_shift.break_time_end != ""
  ) {
    break_time_end = new Date(
      2016,
      8,
      10,
      data.add_shift.break_time_end.getHours(),
      data.add_shift.break_time_end.getMinutes(),
      0
    );
  }

  shiftAPI
    .addShift(
      data.add_shift.name,
      String(data.add_shift.salary_multiplier),
      data.add_shift.classify_status,
      data.add_shift.type_status,
      convertToUTC8(start),
      convertToUTC8(end),
      break_time_begin == ""
        ? break_time_begin
        : convertToUTC8(break_time_begin),
      break_time_end == "" ? break_time_end : convertToUTC8(break_time_end),
      data.add_shift.remark
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Shift").AddSuccess,
          life: 3000,
        });

        data.shiftLoading = true;
        getShiftList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addShift() {
  CheckUser();
  data.AddShiftTypeStatusearch = "";
  data.AddShiftClassifyStatusearch = "";
  data.addDialog = true;
  data.add_shift = {
    id: "",
    name: "",
    salary_multiplier: "",
    classify_status: "",
    remark: "",
    start_time: "",
    end_time: "",
    break_time_begin: null,
    break_time_end: null,
    type_status: "",
  };
}
function editShift(shift) {
  CheckUser();
  for (let i = 0; i < data.selectShiftTypeStatusList.length; i++) {
    if (data.selectShiftTypeStatusList[i].id == shift.type_status) {
      data.EditShiftTypeStatusearch = data.selectShiftTypeStatusList[i];
      break;
    }
  }
  for (let i = 0; i < data.selectShiftClassifyStatusList.length; i++) {
    if (data.selectShiftClassifyStatusList[i].id == shift.classify_status) {
      data.EditShiftClassifyStatusearch = data.selectShiftClassifyStatusList[i];
      break;
    }
  }
  data.shift = {
    id: shift.id,
    name: shift.name,
    remark: shift.remark,
    start_time: dateFormate(shift.start_time, "normal"),
    end_time: dateFormate(shift.end_time, "normal"),
    break_time_begin:
      shift.break_time_begin == null
        ? ""
        : dateFormate(shift.break_time_begin, "normal"),
    break_time_end:
      shift.break_time_end == null
        ? ""
        : dateFormate(shift.break_time_end, "normal"),
    type_status: shift.type_status,
    salary_multiplier: shift.salary_multiplier,
    classify_status: shift.classify_status,
  };
  data.editDialog = true;
}
function confirmDeleteSelected() {
  CheckUser();
  data.deleteSelectedDialog = true;
}
function confirmDelete(shift) {
  CheckUser();
  data.shift = shift;
  data.deleteDialog = true;
}
function deleteShift() {
  shiftAPI.deleteShift([data.shift.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Shift").DeleteSuccess,
        life: 3000,
      });

      data.shiftLoading = true;
      getShiftList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedShift() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedShifts.length; i++) {
    selectDelete.push(data.selectedShifts[i].id);
  }
  shiftAPI.deleteShift(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Shift").DeleteSuccess,
        life: 3000,
      });

      data.shiftLoading = true;
      data.selectedShifts = [];
      getShiftList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}

watch(
  () => data.AddShiftTypeStatusearch,
  () => {
    data.add_shift.type_status =
      data.AddShiftTypeStatusearch == null
        ? ""
        : data.AddShiftTypeStatusearch.id;
  }
);
watch(
  () => data.EditShiftTypeStatusearch,
  () => {
    data.shift.type_status =
      data.EditShiftTypeStatusearch == null
        ? ""
        : data.EditShiftTypeStatusearch.id;
  }
);

watch(
  () => data.AddShiftClassifyStatusearch,
  () => {
    data.add_shift.classify_status =
      data.AddShiftClassifyStatusearch == null
        ? ""
        : data.AddShiftClassifyStatusearch.id;
  }
);
watch(
  () => data.EditShiftClassifyStatusearch,
  () => {
    data.shift.classify_status =
      data.EditShiftClassifyStatusearch == null
        ? ""
        : data.EditShiftClassifyStatusearch.id;
  }
);

watch(
  () => data.page,
  () => {
    data.shiftLoading = true;
    getShiftList();
  }
);
watch(
  () => data.shiftFilters.name.value,
  () => {
    data.shiftLoading = true;
    getShiftList();
  }
);
watch(
  () => data.shiftFilters.id.value,
  () => {
    data.shiftLoading = true;
    getShiftList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.shifts.data"
      :loading="data.shiftLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.shiftFilters"
      v-model:selection="data.selectedShifts"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Shift").ShiftTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.shiftFilters[i18n.$t('Shift').ShiftTable.ID.Field]
                        .value
                    "
                    :placeholder="i18n.$t('Shift').ShiftTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.shiftFilters[i18n.$t('Shift').ShiftTable.Name.Field]
                        .value
                    "
                    :placeholder="i18n.$t('Shift').ShiftTable.Name.Show"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Shift').ShiftTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addShift"
            />
            <Button
              :label="i18n.$t('Shift').ShiftTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="!data.selectedShifts || !data.selectedShifts.length"
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Shift').ShiftTable.ID.Field"
        :header="i18n.$t('Shift').ShiftTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Shift').ShiftTable.Name.Field"
        :header="i18n.$t('Shift').ShiftTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Shift').ShiftTable.ShiftTypeStatus.Field"
        :header="i18n.$t('Shift').ShiftTable.ShiftTypeStatus.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.type_status == 1
              ? i18n.$t("Shift").Class.Normal
              : slotProps.data.type_status == 2
              ? i18n.$t("Shift").Class.Add
              : i18n.$t("Shift").Class.Unknown
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Shift').ShiftTable.ShiftClassifyStatus.Field"
        :header="i18n.$t('Shift').ShiftTable.ShiftClassifyStatus.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.classify_status == 1
              ? i18n.$t("Shift").ClassNight.NormalShiftClassifyStatus
              : slotProps.data.classify_status == 2
              ? i18n.$t("Shift").ClassNight.NightShiftClassifyStatus
              : i18n.$t("Shift").ClassNight.Unknown
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Shift').ShiftTable.SalaryMultiplier.Field"
        :header="i18n.$t('Shift').ShiftTable.SalaryMultiplier.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Shift').ShiftTable.StartTime.Field"
        :header="i18n.$t('Shift').ShiftTable.StartTime.Show"
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.start_time, "hh:mm") }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Shift').ShiftTable.EndTime.Field"
        :header="i18n.$t('Shift').ShiftTable.EndTime.Show"
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.end_time, "hh:mm") }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Shift').ShiftTable.BreakTimeBegin.Field"
        :header="i18n.$t('Shift').ShiftTable.BreakTimeBegin.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.break_time_begin == null
              ? ""
              : dateFormate(slotProps.data.break_time_begin, "hh:mm")
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Shift').ShiftTable.BreakTimeEnd.Field"
        :header="i18n.$t('Shift').ShiftTable.BreakTimeEnd.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.break_time_end == null
              ? ""
              : dateFormate(slotProps.data.break_time_end, "hh:mm")
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Shift').ShiftTable.Remark.Field"
        :header="i18n.$t('Shift').ShiftTable.Remark.Show"
      ></Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editShift(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Shift').AddShift.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.ShiftTypeStatus.Show
        }}</label>
        <Dropdown
          v-model="data.AddShiftTypeStatusearch"
          :options="data.selectShiftTypeStatusList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Shift').ShiftTable.ShiftTypeStatus.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.ShiftClassifyStatus.Show
        }}</label>
        <Dropdown
          v-model="data.AddShiftClassifyStatusearch"
          :options="data.selectShiftClassifyStatusList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Shift').ShiftTable.ShiftClassifyStatus.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Shift").AddShift.Name }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_shift.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.SalaryMultiplier.Show
        }}</label>
        <InputNumber
          id="name"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          v-model.trim="data.add_shift.salary_multiplier"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.StartTime.Show
        }}</label>
        <Calendar
          inputId="time12"
          v-model="data.add_shift.start_time"
          :timeOnly="true"
          hourFormat="24"
          :stepMinute="30"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Shift").ShiftTable.EndTime.Show }}</label>
        <Calendar
          inputId="time12"
          v-model="data.add_shift.end_time"
          :timeOnly="true"
          hourFormat="24"
          :stepMinute="30"
        />
      </div>

      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.BreakTimeBegin.Show
        }}</label>
        <Calendar
          inputId="time12"
          v-model="data.add_shift.break_time_begin"
          :timeOnly="true"
          hourFormat="24"
          :stepMinute="30"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.BreakTimeEnd.Show
        }}</label>
        <Calendar
          inputId="time12"
          v-model="data.add_shift.break_time_end"
          :timeOnly="true"
          hourFormat="24"
          :stepMinute="30"
        />
      </div>

      <div class="p-field">
        <label for="name">{{ i18n.$t("Shift").AddShift.Remark }}</label>
        <Textarea
          id="remark"
          v-model="data.add_shift.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Shift').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Shift').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddShift"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Shift').EditShift.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.ShiftTypeStatus.Show
        }}</label>
        <Dropdown
          v-model="data.EditShiftTypeStatusearch"
          :options="data.selectShiftTypeStatusList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Shift').ShiftTable.ShiftTypeStatus.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.ShiftClassifyStatus.Show
        }}</label>
        <Dropdown
          v-model="data.EditShiftClassifyStatusearch"
          :options="data.selectShiftClassifyStatusList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Shift').ShiftTable.ShiftClassifyStatus.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Shift").EditShift.Name }}</label>
        <InputText id="name" v-model.trim="data.shift.name" required="true" />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.SalaryMultiplier.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.shift.salary_multiplier"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.StartTime.Show
        }}</label>
        <Calendar
          inputId="time12"
          v-model="data.shift.start_time"
          :timeOnly="true"
          hourFormat="24"
          :stepMinute="30"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Shift").ShiftTable.EndTime.Show }}</label>
        <Calendar
          inputId="time12"
          v-model="data.shift.end_time"
          :timeOnly="true"
          hourFormat="24"
          :stepMinute="30"
        />
      </div>

      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.BreakTimeBegin.Show
        }}</label>
        <Calendar
          inputId="time12"
          v-model="data.shift.break_time_begin"
          :timeOnly="true"
          hourFormat="24"
          :stepMinute="30"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Shift").ShiftTable.BreakTimeEnd.Show
        }}</label>
        <Calendar
          inputId="time12"
          v-model="data.shift.break_time_end"
          :timeOnly="true"
          hourFormat="24"
          :stepMinute="30"
        />
      </div>

      <div class="p-field">
        <label for="name">{{ i18n.$t("Shift").EditShift.Remark }}</label>
        <Textarea
          id="remark"
          v-model="data.shift.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Shift').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Shift').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditShift"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Shift').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.shift"
          >{{ i18n.$t("Shift").Confirm.Content }} <b>{{ data.shift.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Shift').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Shift').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteShift"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Shift').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Shift").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Shift').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Shift').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedShift"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
