import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Employer {
  getEmployerInfo(id) {
    return axios.get(
      `/api/manager/employer/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getEmployerList(
    id,
    name,
    organisation_id,
    department_ids,
    nationality_ids,
    work_permit_ids,
    identity_card_number,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/employer/list`,
      {
        params: {
          id: id,
          name: name,
          organisation_id: organisation_id,
          department_ids: department_ids,
          nationality_ids: nationality_ids,
          work_permit_ids: work_permit_ids,
          identity_card_number: identity_card_number,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getEmployerSelectList(name) {
    return axios.get(
      `/api/manager/employer/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
}
