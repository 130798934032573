<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import PaymentItem from "../../api/salary/admin/payment_item";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let payment_itemAPI = new PaymentItem();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  EditTaxedSearch: "",
  AddTaxedSearch: "",
  selectTaxedList: [
    {
      id: 1,
      name: i18n.$t("PaymentItem").Taxed.IsTaxed,
    },
    {
      id: 2,
      name: i18n.$t("PaymentItem").Taxed.NotTaxed,
    },
  ],
  add_payment_item: {
    id: "",
    chinese_name: "",
    english_name: "",
    description: "",
    status: "",
    taxed: "",
  },
  payment_itemLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  payment_items: { data: null },
  payment_item: null,
  page: 1,
  page_num: 10,
  selectedPaymentItems: null,
  payment_itemFilters: {
    id: { value: "", matchMode: "contains" },
    chinese_name: { value: "", matchMode: "contains" },
    english_name: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getPaymentItemList();
  }
});

function getPaymentItemList() {
  CheckUser();
  payment_itemAPI
    .getPaymentItemList(
      data.payment_itemFilters.id.value,
      data.payment_itemFilters.chinese_name.value,
      data.payment_itemFilters.english_name.value,
      0,
      0,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.payment_items.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.payment_itemLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.payment_itemLoading = false;
      }
    });
}

function saveEditPaymentItem() {
  payment_itemAPI
    .editPaymentItem(
      data.payment_item.id,
      data.payment_item.chinese_name,
      data.payment_item.english_name,
      data.payment_item.taxed
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PaymentItem").EditSuccess,
          life: 3000,
        });

        data.payment_itemLoading = true;
        getPaymentItemList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddPaymentItem() {
  payment_itemAPI
    .addPaymentItem(
      data.add_payment_item.chinese_name,
      data.add_payment_item.english_name,
      data.add_payment_item.taxed
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PaymentItem").AddSuccess,
          life: 3000,
        });

        data.payment_itemLoading = true;
        getPaymentItemList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addPaymentItem() {
  data.addDialog = true;
  data.AddTaxedSearch = "";
  data.add_payment_item = {
    id: "",
    chinese_name: "",
    english_name: "",
    description: "",
    status: "",
    taxed: "",
  };
}
function editPaymentItem(payment_item) {
  data.payment_item = { ...payment_item };
  if (payment_item.taxed == 1) {
    data.EditTaxedSearch = {
      id: 1,
      name: i18n.$t("PaymentItem").Taxed.IsTaxed,
    };
  } else {
    data.EditTaxedSearch = {
      id: 2,
      name: i18n.$t("PaymentItem").Taxed.NotTaxed,
    };
  }

  data.editDialog = true;
}

function editPaymentItemStatus(payment_item) {
  data.payment_item = { ...payment_item };
  data.edit_statusDialog = true;
}

function SaveEditPaymentItemStatus() {
  payment_itemAPI
    .editPaymentItemStatus(
      data.payment_item.id,
      data.payment_item.status == 1 ? 2 : 1
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("PaymentItem").EditSuccess,
          life: 3000,
        });

        data.payment_itemLoading = true;
        getPaymentItemList();
        data.edit_statusDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(payment_item) {
  data.payment_item = payment_item;
  data.deleteDialog = true;
}
function deletePaymentItem() {
  payment_itemAPI.deletePaymentItem([data.payment_item.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("PaymentItem").DeleteSuccess,
        life: 3000,
      });

      data.payment_itemLoading = true;
      getPaymentItemList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedPaymentItem() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedPaymentItems.length; i++) {
    selectDelete.push(data.selectedPaymentItems[i].id);
  }
  payment_itemAPI.deletePaymentItem(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("PaymentItem").DeleteSuccess,
        life: 3000,
      });

      data.payment_itemLoading = true;
      data.selectedPaymentItems = [];
      getPaymentItemList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.payment_itemLoading = true;
    getPaymentItemList();
  }
);
watch(
  () => data.payment_itemFilters.chinese_name.value,
  () => {
    data.payment_itemLoading = true;
    getPaymentItemList();
  }
);
watch(
  () => data.payment_itemFilters.english_name.value,
  () => {
    data.payment_itemLoading = true;
    getPaymentItemList();
  }
);
watch(
  () => data.payment_itemFilters.id.value,
  () => {
    data.payment_itemLoading = true;
    getPaymentItemList();
  }
);
watch(
  () => data.EditTaxedSearch,
  () => {
    data.payment_item.taxed =
      data.EditTaxedSearch == null ? "" : data.EditTaxedSearch.id;
  }
);
watch(
  () => data.AddTaxedSearch,
  () => {
    data.add_payment_item.taxed =
      data.AddTaxedSearch == null ? "" : data.AddTaxedSearch.id;
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.payment_items.data"
      :loading="data.payment_itemLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.payment_itemFilters"
      v-model:selection="data.selectedPaymentItems"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("PaymentItem").PaymentItemTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.payment_itemFilters[
                        i18n.$t('PaymentItem').PaymentItemTable.ID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('PaymentItem').PaymentItemTable.ID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.payment_itemFilters[
                        i18n.$t('PaymentItem').PaymentItemTable.ChineseName
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('PaymentItem').PaymentItemTable.ChineseName.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.payment_itemFilters[
                        i18n.$t('PaymentItem').PaymentItemTable.EnglishName
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('PaymentItem').PaymentItemTable.EnglishName.Show
                    "
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('PaymentItem').PaymentItemTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addPaymentItem"
            />
            <Button
              :label="i18n.$t('PaymentItem').PaymentItemTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedPaymentItems || !data.selectedPaymentItems.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('PaymentItem').PaymentItemTable.ID.Field"
        :header="i18n.$t('PaymentItem').PaymentItemTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('PaymentItem').PaymentItemTable.ChineseName.Field"
        :header="i18n.$t('PaymentItem').PaymentItemTable.ChineseName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('PaymentItem').PaymentItemTable.EnglishName.Field"
        :header="i18n.$t('PaymentItem').PaymentItemTable.EnglishName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('PaymentItem').PaymentItemTable.Taxed.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.taxed == 1
              ? i18n.$t("PaymentItem").Taxed.IsTaxed
              : slotProps.data.taxed == 2
              ? i18n.$t("PaymentItem").Taxed.NotTaxed
              : i18n.$t("PaymentItem").Taxed.Unknow
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('PaymentItem').PaymentItemTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("PaymentItem").Status.Disable
              : slotProps.data.status == 2
              ? i18n.$t("PaymentItem").Status.Enable
              : i18n.$t("PaymentItem").Status.Unknow
          }}
        </template>
      </Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editPaymentItem(slotProps.data)"
          />
          <Button
            :icon="slotProps.data.status == 1 ? 'pi pi-check' : 'pi pi-ban'"
            :class="
              slotProps.data.status == 1
                ? 'p-button-rounded p-button-info p-mr-2'
                : 'p-button-rounded p-button-danger p-mr-2'
            "
            @click="editPaymentItemStatus(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('PaymentItem').AddPaymentItem.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PaymentItem").PaymentItemTable.ChineseName.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_payment_item.chinese_name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PaymentItem").PaymentItemTable.EnglishName.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_payment_item.english_name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PaymentItem").PaymentItemTable.Taxed.Show
        }}</label>
        <Dropdown
          v-model="data.AddTaxedSearch"
          :options="data.selectTaxedList"
          :placeholder="i18n.$t('PaymentItem').PaymentItemTable.Taxed.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PaymentItem').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('PaymentItem').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddPaymentItem"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PaymentItem').EditPaymentItem.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PaymentItem").PaymentItemTable.ChineseName.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.payment_item.chinese_name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PaymentItem").PaymentItemTable.EnglishName.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.payment_item.english_name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("PaymentItem").PaymentItemTable.Taxed.Show
        }}</label>
        <Dropdown
          v-model="data.EditTaxedSearch"
          :options="data.selectTaxedList"
          :placeholder="i18n.$t('PaymentItem').PaymentItemTable.Taxed.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PaymentItem').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('PaymentItem').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditPaymentItem"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PaymentItem').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.payment_item"
          >{{ i18n.$t("PaymentItem").Confirm.Content }}
          <b>{{ data.payment_item.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PaymentItem').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('PaymentItem').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deletePaymentItem"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PaymentItem').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("PaymentItem").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PaymentItem').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('PaymentItem').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedPaymentItem"
        />
      </template>
    </Dialog>
    <!-- 單一啟用視窗 -->
    <Dialog
      v-model:visible="data.edit_statusDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('PaymentItem').ConfirmStatus.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.payment_item"
          >{{ i18n.$t("PaymentItem").ConfirmStatus.Content }}
          <b>{{ data.payment_item.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('PaymentItem').ConfirmStatus.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.edit_statusDialog = false"
        />
        <Button
          :label="i18n.$t('PaymentItem').ConfirmStatus.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveEditPaymentItemStatus"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
