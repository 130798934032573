<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

import { dateFormate } from "../../tool/formate";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import AdminPayment from "../../api/salary/admin/payment";
import ManagerPayment from "../../api/salary/manager/payment";
import AdminPaymentContent from "../../api/salary/admin/payment_content";
import ManagerPaymentContent from "../../api/salary/manager/payment_content";
import Company from "../../api/salary/self/company";
import Factory from "../../api/salary/self/factory";
import PaymentItem from "../../api/salary/self/payment_item";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let companyAPI = new Company();
let factoryAPI = new Factory();
let payment_itemAPI = new PaymentItem();
let userApi = new User();
let admin_paymentAPI = new AdminPayment();
let manager_paymentAPI = new ManagerPayment();
let admin_payment_contentAPI = new AdminPaymentContent();
let manager_payment_contentAPI = new ManagerPaymentContent();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  expandedRows: [],
  StatusSearch: "",
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("Payment").Status.NewPaymentAuditStatus,
    },
    {
      id: 2,
      name: i18n.$t("Payment").Status.RejectPaymentAuditStatus,
    },
    {
      id: 3,
      name: i18n.$t("Payment").Status.PassPaymentAuditStatus,
    },
    {
      id: 4,
      name: i18n.$t("Payment").Status.ReleasePaymentAuditStatus,
    },
    {
      id: 5,
      name: i18n.$t("Payment").Status.CanacelPaymentAuditStatus,
    },
  ],
  selectPaymentItemList: [],
  selectPaymentTypeList: [
    {
      id: 1,
      name: i18n.$t("Payment").PaymentType.Purchase,
    },
    {
      id: 2,
      name: i18n.$t("Payment").PaymentType.PettyCash,
    },
    {
      id: 4,
      name: i18n.$t("Payment").PaymentType.Application,
    },
  ],
  selectDeliveryMethodList: [
    {
      id: 1,
      name: i18n.$t("Payment").DeliveryMethod.Air,
    },
    {
      id: 2,
      name: i18n.$t("Payment").DeliveryMethod.Sea,
    },
    {
      id: 3,
      name: i18n.$t("Payment").DeliveryMethod.Land,
    },
  ],
  selectCompanyList: [],
  selectFactoryList: [],
  PaymentItemSearch: "",
  CompanySearch: "",
  FactorySearch: "",
  PaymentTypeSearch: "",
  DeliveryMethodSearch: "",
  paymentLoading: false,
  totalRecords: 0,
  passDialog: false,
  failDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  payments: { data: null },
  payment: null,
  status: 0,
  page: 1,
  page_num: 10,
  selectedPayments: null,
  paymentFilters: {
    id: { value: "", matchMode: "contains" },
    delivery_method: { value: "", matchMode: "contains" },
    payment_method: { value: "", matchMode: "contains" },
    company_id: { value: "", matchMode: "contains" },
    company_account_id: { value: "", matchMode: "contains" },
    factory_id: { value: "", matchMode: "contains" },
    status: { value: 1, matchMode: "contains" },
    payment_type: { value: "", matchMode: "contains" },
    created_at_begin: { value: "", matchMode: "contains" },
    created_at_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getPaymentList();
    getCompanySelectList();
    getFactorySelectList();
    getPaymentItemSelectList();
  }
});
function getPaymentItemSelectList() {
  payment_itemAPI.getPaymentItemSelectList().then((res) => {
    if (res.data.error_code == 0) {
      data.selectPaymentItemList = res.data.data.datas;
      //   console.log(data.selectPaymentItemList);
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getCompanySelectList() {
  companyAPI.getCompanySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCompanyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getFactorySelectList() {
  factoryAPI.getFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectFactoryList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

// let admin_payment_contentAPI = new AdminPaymentContent();
// let manager_payment_contentAPI = new ManagerPaymentContent();
function getPaymentList() {
  CheckUser();
  if (store.state.user.role == 1) {
    admin_paymentAPI
      .getPaymentList(
        data.paymentFilters.id.value,
        data.paymentFilters.delivery_method.value,
        data.paymentFilters.payment_method.value,
        data.paymentFilters.company_id.value,
        data.paymentFilters.company_account_id.value,
        data.paymentFilters.factory_id.value,
        data.paymentFilters.status.value,
        data.paymentFilters.payment_type.value,
        data.paymentFilters.created_at_begin.value,
        data.paymentFilters.created_at_end.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.totalRecords = res.data.data.total_count;
          data.payments.data = res.data.data.datas;
          data.page = res.data.data.page;

          data.paymentLoading = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });

          data.totalRecords = 0;
          data.paymentLoading = false;
        }
      });
  } else if (store.state.user.role == 2) {
    manager_paymentAPI
      .getPaymentList(
        data.paymentFilters.id.value,
        data.paymentFilters.delivery_method.value,
        data.paymentFilters.payment_method.value,
        data.paymentFilters.company_id.value,
        data.paymentFilters.company_account_id.value,
        data.paymentFilters.factory_id.value,
        data.paymentFilters.status.value,
        data.paymentFilters.payment_type.value,
        data.paymentFilters.created_at_begin.value,
        data.paymentFilters.created_at_end.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          data.totalRecords = res.data.data.total_count;
          data.payments.data = res.data.data.datas;
          data.page = res.data.data.page;

          data.paymentLoading = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });

          data.totalRecords = 0;
          data.paymentLoading = false;
        }
      });
  }
}

// function addPayment() {
//   CheckUser();
//   data.addDialog = true;
// }

function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(payment) {
  data.payment = payment;
  data.deleteDialog = true;
}
function deletePayment() {
  admin_paymentAPI.deletePayment([data.payment.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Payment").DeleteSuccess,
        life: 3000,
      });

      data.paymentLoading = true;
      getPaymentList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedPayment() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedPayments.length; i++) {
    selectDelete.push(data.selectedPayments[i].id);
  }
  admin_paymentAPI.deletePayment(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Payment").DeleteSuccess,
        life: 3000,
      });

      data.paymentLoading = true;
      data.selectedPayments = [];
      getPaymentList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.paymentFilters.id.value,
  () => {
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.CompanySearch,
  () => {
    data.paymentFilters.company_id.value =
      data.CompanySearch == null ? "" : data.CompanySearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.paymentFilters.status.value =
      data.StatusSearch == null ? "" : data.StatusSearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.FactorySearch,
  () => {
    data.paymentFilters.factory_id.value =
      data.FactorySearch == null ? "" : data.FactorySearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.PaymentTypeSearch,
  () => {
    data.paymentFilters.payment_type.value =
      data.PaymentTypeSearch == null ? "" : data.PaymentTypeSearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.DeliveryMethodSearch,
  () => {
    data.paymentFilters.delivery_method.value =
      data.DeliveryMethodSearch == null ? "" : data.DeliveryMethodSearch.name;
    data.paymentLoading = true;
    getPaymentList();
  }
);
function onRowExpand(e) {
  //   console.log(e.data.id);
  getPaymentContentList(e.data.id);
}
function getPaymentContentList(id) {
  if (store.state.user.role == 1) {
    admin_payment_contentAPI
      .getPaymentContentList(0, id, 0, "", 1, 999)
      .then((res) => {
        if (res.data.error_code == 0) {
          for (let i = 0; i < data.payments.data.length; i++) {
            if (data.payments.data[i].id == id) {
              data.payments.data[i].contents = res.data.data.datas;
            }
          }
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  } else if (store.state.user.role == 2) {
    manager_payment_contentAPI
      .getPaymentContentList(0, id, 0, "", 1, 999)
      .then((res) => {
        if (res.data.error_code == 0) {
          for (let i = 0; i < data.payments.data.length; i++) {
            if (data.payments.data[i].id == id) {
              data.payments.data[i].contents = res.data.data.datas;
            }
          }
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  }
}
function editStatus(payment, status) {
  data.payment = payment;
  data.status = status;
  if (status == 2) {
    data.failDialog = true;
  } else if (status == 3) {
    data.passDialog = true;
  }
}
function SaveEditStatus() {
  if (store.state.user.role == 1) {
    admin_paymentAPI
      .editPaymentStatus(data.payment.id, data.status)
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Payment").Success,
            life: 3000,
          });
          data.paymentLoading = true;
          getPaymentList();
          if (data.status == 2) {
            data.failDialog = false;
          } else if (data.status == 3) {
            data.passDialog = false;
          }
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  } else if (store.state.user.role == 2) {
    manager_paymentAPI
      .editPaymentStatus(data.payment.id, data.status)
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Payment").Success,
            life: 3000,
          });
          data.paymentLoading = true;
          getPaymentList();
          if (data.status == 2) {
            data.failDialog = false;
          } else if (data.status == 3) {
            data.passDialog = false;
          }
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  }
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.payments.data"
      :loading="data.paymentLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.paymentFilters"
      v-model:selection="data.selectedPayments"
      :row-hover="true"
      v-model:expandedRows="data.expandedRows"
      @rowExpand="onRowExpand"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Payment").PaymentTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.paymentFilters[
                        i18n.$t('Payment').PaymentTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Payment').PaymentTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.CompanySearch"
                    :options="data.selectCompanyList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Payment').PaymentTable.Company.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.PaymentTypeSearch"
                    :options="data.selectPaymentTypeList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Payment').PaymentTable.PaymentType.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
              <p style="text-align: left" class="p-mb-2">
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.FactorySearch"
                    :options="data.selectFactoryList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Payment').PaymentTable.Factory.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.DeliveryMethodSearch"
                    :options="data.selectDeliveryMethodList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Payment').PaymentTable.DeliveryMethod.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-mr-1 p-d-none">
                  <Dropdown
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Payment').PaymentTable.Status.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
            </AccordionTab>
          </Accordion>
          <!-- <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              v-if="store.state.user.role == 1"
              :label="i18n.$t('Payment').PaymentTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedPayments || !data.selectedPayments.length
              "
            />
          </div> -->
        </div>
      </template>
      <Column :expander="true" headerStyle="width: 1rem" />
      <!-- <Column
        v-if="store.state.user.role == 1"
        class="p-col-1"
        selectionMode="multiple"
      ></Column> -->
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.ID.Field"
        :header="i18n.$t('Payment').PaymentTable.ID.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.CustomOrderNumber.Field"
        :header="i18n.$t('Payment').PaymentTable.CustomOrderNumber.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.CompanyName.Field"
        :header="i18n.$t('Payment').PaymentTable.CompanyName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.PaymentTypeName.Field"
        :header="i18n.$t('Payment').PaymentTable.PaymentTypeName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.FactoryName.Field"
        :header="i18n.$t('Payment').PaymentTable.FactoryName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.DeliveryMethod.Field"
        :header="i18n.$t('Payment').PaymentTable.DeliveryMethod.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.Remark.Field"
        :header="i18n.$t('Payment').PaymentTable.Remark.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.Description.Field"
        :header="i18n.$t('Payment').PaymentTable.Description.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.PaymentMethod.Field"
        :header="i18n.$t('Payment').PaymentTable.PaymentMethod.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.TotalCount.Field"
        :header="i18n.$t('Payment').PaymentTable.TotalCount.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.TotalDollar.Field"
        :header="i18n.$t('Payment').PaymentTable.TotalDollar.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.TotalTaxed.Field"
        :header="i18n.$t('Payment').PaymentTable.TotalTaxed.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.CurrencyName.Field"
        :header="i18n.$t('Payment').PaymentTable.CurrencyName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :header="i18n.$t('Payment').PaymentTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("Payment").Status.NewPaymentAuditStatus
              : slotProps.data.status == 2
              ? i18n.$t("Payment").Status.RejectPaymentAuditStatus
              : slotProps.data.status == 3
              ? i18n.$t("Payment").Status.PassPaymentAuditStatus
              : slotProps.data.status == 4
              ? i18n.$t("Payment").Status.ReleasePaymentAuditStatus
              : slotProps.data.status == 5
              ? i18n.$t("Payment").Status.CanacelPaymentAuditStatus
              : "UnKnow"
          }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :header="i18n.$t('Payment').PaymentTable.PayDate.Show"
      >
        <template #body="slotProps"
          >{{ dateFormate(slotProps.data.pay_date, "YYYY-MM-DD") }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :header="i18n.$t('Payment').PaymentTable.SettlementOfMonth.Show"
      >
        <template #body="slotProps"
          >{{ dateFormate(slotProps.data.settlement_of_month, "YYYY-MM") }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :header="i18n.$t('Payment').PaymentTable.CreatedAt.Show"
      >
        <template #body="slotProps"
          >{{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      >
        <template #body="slotProps">
          <Button
            v-if="slotProps.data.status == 1"
            icon="pi pi-check"
            class="p-button-rounded p-button-info p-mr-2 p-button-sm"
            @click="editStatus(slotProps.data, 3)"
          />
          <Button
            v-if="slotProps.data.status == 1"
            icon="pi pi-ban"
            class="p-button-rounded p-button-danger p-mr-2 p-button-sm"
            @click="editStatus(slotProps.data, 2)"
          />
          <Button
            v-if="store.state.user.role == 1"
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning p-button-sm"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="orders-subtable" style="width: 100%">
          <div>
            <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.PaymentItem.Show }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.DepartmentId.Show }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.AccountItemId.Show }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.Spec.Show }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.TotalCount.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Dollar.Show }}
              </div>
              <div class="p-col-1">
                {{
                  i18n.$t("Payment").PaymentContentTable.CalculationUnit.Show
                }}
              </div>
            </div>
          </div>
          <div
            style="
              height: calc(100vh - 470px);
              overflow-y: scroll;
              overflow-x: hidden;
            "
          >
            <div
              :key="index"
              v-for="(item, index) in slotProps.data.contents"
              class="p-grid p-mt-1"
              style="border-bottom: 1px solid"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{
                  item.payment_item_chinese_name +
                  "(" +
                  item.payment_item_english_name +
                  ")"
                }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{ item.department_name }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{ item.account_item_name }}
                ({{
                  item.payment_item_taxed == 1
                    ? i18n.$t("PaymentItem").Taxed.IsTaxed
                    : item.payment_item_taxed == 2
                    ? i18n.$t("PaymentItem").Taxed.NotTaxed
                    : i18n.$t("PaymentItem").Taxed.Unknow
                }})
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{ item.spec }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{ item.total_count }}
                <!-- <InputNumber
              v-model="item.total_count"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            /> -->
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.dollar }}
                <!-- <InputNumber
              v-model="item.dollar"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            /> -->
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.calculation_unit }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
    <!-- 通過視窗 -->
    <Dialog
      v-model:visible="data.passDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Payment').AuditPayment.Pass"
      :modal="true"
      class="p-fluid"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.payment"
          >{{ i18n.$t("Payment").Confirm.CotentPass }}
          <b>{{ data.payment.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.passDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveEditStatus"
        />
      </template>
    </Dialog>
    <!-- 駁回視窗 -->
    <Dialog
      v-model:visible="data.failDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Payment').AuditPayment.Fail"
      :modal="true"
      class="p-fluid"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.payment"
          >{{ i18n.$t("Payment").Confirm.CotentFail }}
          <b>{{ data.payment.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.failDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveEditStatus"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Payment').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.payment"
          >{{ i18n.$t("Payment").Confirm.Content }}
          <b>{{ data.payment.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deletePayment"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Payment').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Payment").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedPayment"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
