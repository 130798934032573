import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Salary {
  getWaterExcel(settlement_of_month_begin, settlement_of_month_end) {
    return axios.get(
      `/api/self/salary/water/list`,
      {
        params: {
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
          page_num: 999,
        },
      },
      config
    );
  }
  getSalaryInfo(id) {
    return axios.get(
      `/api/self/salary/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getSalaryList(pay_date_begin, pay_date_end, page, page_num) {
    return axios.get(
      `/api/self/salary/list`,
      {
        params: {
          pay_date_begin: pay_date_begin,
          pay_date_end: pay_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
}
