<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import { dateFormate } from "../tool/formate";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import User from "../api/salary/auth/user";
import router from "../router";
import AdminCurrency from "../api/salary/admin/currency";
import AdminSalaryItem from "../api/salary/admin/salary_item";
import ManagerCurrency from "../api/salary/manager/currency";
import ManagerSalaryItem from "../api/salary/manager/salary_item";
import AdminSalary from "../api/salary/admin/salary";
import ManagerSalary from "../api/salary/manager/salary";

let userApi = new User();
const store = useStore();
let admin_currencyAPI = new AdminCurrency();
let admin_salary_itemAPI = new AdminSalaryItem();
let manager_currencyAPI = new ManagerCurrency();
let manager_salary_itemAPI = new ManagerSalaryItem();
let admin_salaryAPI = new AdminSalary();
let manager_salaryAPI = new ManagerSalary();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
const dt = ref();
const i18n = useI18n();
const toast = useToast();
const data = reactive({
  CurrencySearch: "",
  selectCurrencyList: [],
  SalaryItemSearch: "",
  selectSalaryItemList: [],
  add_salary: {
    salary_item_id: 0,
    recipient_employer_id: 0,
    description: "",
    dollar: 0,
    currency_id: 0,
    pay_date: "",
    settlement_of_month: "",
    hours: 0,
  },
  salaryLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  salarys: { data: null },
  salary: null,
  page: 1,
  page_num: 10,
  selectedSalarys: null,
  salaryFilters: {
    pay_date_begin: { value: "", matchMode: "contains" },
    pay_date_end: { value: "", matchMode: "contains" },
  },
  excel: { data: null },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getSalaryList();
  }
  getCurrencySelectList();
  getSalaryItemSelectList();
});
function Excel() {
  switch (store.state.user.role) {
    case 1:
      admin_salaryAPI
        .getSalaryList(
          store.state.employer.id,
          data.salaryFilters.pay_date_begin.value,
          data.salaryFilters.pay_date_end.value,
          1,
          500
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            let tmp = res.data.data.datas;
            for (let i = 0; i < tmp.length; i++) {
              tmp[i].pay_date = dateFormate(tmp[i].pay_date, "YYYY-MM");
              tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");
              tmp[i].exchange_dollar = tmp[i].dollar * tmp[i].exchange_rate;
              if (tmp[i].salary_positive_or_negative == 1) {
                tmp[i].dollar = "-" + tmp[i].dollar;
                tmp[i].exchange_dollar = "-" + tmp[i].exchange_dollar;
              }
            }
            data.excel.data = tmp;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          dt.value.exportCSV();
        });

      break;
    case 2:
      manager_salaryAPI
        .getSalaryList(
          store.state.employer.id,
          data.salaryFilters.pay_date_begin.value,
          data.salaryFilters.pay_date_end.value,
          1,
          500
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            let tmp = res.data.data.datas;
            for (let i = 0; i < tmp.length; i++) {
              tmp[i].pay_date = dateFormate(tmp[i].pay_date, "YYYY-MM");
              tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");
              tmp[i].exchange_dollar = tmp[i].dollar * tmp[i].exchange_rate;
              if (tmp[i].salary_positive_or_negative == 1) {
                tmp[i].dollar = "-" + tmp[i].dollar;
                tmp[i].exchange_dollar = "-" + tmp[i].exchange_dollar;
              }
            }

            data.excel.data = tmp;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          dt.value.exportCSV();
        });

      break;
  }
}
function getCurrencySelectList() {
  switch (store.state.user.role) {
    case 1:
      admin_currencyAPI.getCurrencySelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectCurrencyList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_currencyAPI.getCurrencySelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectCurrencyList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}
function getSalaryItemSelectList() {
  switch (store.state.user.role) {
    case 1:
      admin_salary_itemAPI.getSalaryItemSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectSalaryItemList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_salary_itemAPI.getSalaryItemSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectSalaryItemList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}
function getSalaryList() {
  switch (store.state.user.role) {
    case 1:
      admin_salaryAPI
        .getSalaryList(
          store.state.employer.id,
          data.salaryFilters.pay_date_begin.value,
          data.salaryFilters.pay_date_end.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.salarys.data = res.data.data.datas;
            for (let i = 0; i < data.salarys.data.length; i++) {
              if (data.salarys.data[i].salary_positive_or_negative == 1)
                data.salarys.data[i].dollar = data.salarys.data[i].dollar * -1;
            }

            data.page = res.data.data.page;
            data.salaryLoading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });

            data.totalRecords = 0;
            data.salaryLoading = false;
          }
        });

      break;
    case 2:
      manager_salaryAPI
        .getSalaryList(
          store.state.employer.id,
          data.salaryFilters.pay_date_begin.value,
          data.salaryFilters.pay_date_end.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.salarys.data = res.data.data.datas;
            for (let i = 0; i < data.salarys.data.length; i++) {
              if (data.salarys.data[i].salary_positive_or_negative == 1)
                data.salarys.data[i].dollar = data.salarys.data[i].dollar * -1;
            }
            data.page = res.data.data.page;
            data.salaryLoading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });

            data.totalRecords = 0;
            data.salaryLoading = false;
          }
        });

      break;
  }
}

function saveAddSalary() {
  switch (store.state.user.role) {
    case 1:
      admin_salaryAPI
        .addSalary(
          data.add_salary.salary_item_id,
          store.state.employer.id,
          data.add_salary.description,
          String(data.add_salary.dollar),
          // data.add_salary.currency_id,
          1,
          data.add_salary.pay_date,
          data.add_salary.settlement_of_month,
          String(data.add_salary.hours)
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("Salary").AddSuccess,
              life: 3000,
            });

            data.salaryLoading = true;
            getSalaryList();
            data.addDialog = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
    case 2:
      manager_salaryAPI
        .addSalary(
          data.add_salary.salary_item_id,
          store.state.employer.id,
          data.add_salary.description,
          String(data.add_salary.dollar),
          // data.add_salary.currency_id,
          1,
          data.add_salary.pay_date,
          data.add_salary.settlement_of_month,
          String(data.add_salary.hours)
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("Salary").AddSuccess,
              life: 3000,
            });

            data.salaryLoading = true;
            getSalaryList();
            data.addDialog = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
  }
}

function addSalary() {
  data.addDialog = true;
  data.add_salary = {
    salary_item_id: 0,
    recipient_employer_id: 0,
    description: "",
    dollar: 0,
    currency_id: 0,
    pay_date: "",
    settlement_of_month: "",
    hours: 0,
  };
}

function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(salary) {
  data.salary = salary;
  data.deleteDialog = true;
}
function deleteSalary() {
  switch (store.state.user.role) {
    case 1:
      admin_salaryAPI.deleteSalary([data.salary.id]).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Salary").DeleteSuccess,
            life: 3000,
          });

          data.salaryLoading = true;
          getSalaryList();
          data.deleteDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });

      break;
    case 2:
      manager_salaryAPI.deleteSalary([data.salary.id]).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Salary").DeleteSuccess,
            life: 3000,
          });

          data.salaryLoading = true;
          getSalaryList();
          data.deleteDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });

      break;
  }
}
function deleteSelectedSalary() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedSalarys.length; i++) {
    selectDelete.push(data.selectedSalarys[i].id);
  }

  switch (store.state.user.role) {
    case 1:
      admin_salaryAPI.deleteSalary(selectDelete).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Salary").DeleteSuccess,
            life: 3000,
          });

          data.salaryLoading = true;
          data.selectedSalarys = [];
          getSalaryList();
          data.deleteSelectedDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });

      break;
    case 2:
      manager_salaryAPI.deleteSalary(selectDelete).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Salary").DeleteSuccess,
            life: 3000,
          });

          data.salaryLoading = true;
          data.selectedSalarys = [];
          getSalaryList();
          data.deleteSelectedDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });

      break;
  }
}
function onPage(event) {
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.salaryLoading = true;
    getSalaryList();
  }
);
watch(
  () => data.CurrencySearch,
  () => {
    data.add_salary.currency_id =
      data.CurrencySearch == null ? "" : data.CurrencySearch.id;
  }
);
watch(
  () => data.SalaryItemSearch,
  () => {
    data.add_salary.salary_item_id =
      data.SalaryItemSearch == null ? "" : data.SalaryItemSearch.id;
  }
);
watch(
  () => data.add_date,
  () => {
    if (data.add_date != null) {
      data.add_date.setDate(data.add_date.getDate() + 1);
      data.add_salary.pay_date = data.add_date.toISOString();
    } else {
      data.add_salary.pay_date = "";
    }
  }
);
watch(
  () => data.add_settlement_of_month_date,
  () => {
    if (data.add_settlement_of_month_date != null) {
      data.add_settlement_of_month_date.setDate(
        data.add_settlement_of_month_date.getDate() + 1
      );
      data.add_salary.settlement_of_month =
        data.add_settlement_of_month_date.toISOString();
    } else {
      data.add_salary.settlement_of_month = "";
    }
  }
);

watch(
  () => data.date_search,
  async () => {
    data.salaryLoading = true;
    if (data.date_search != null) {
      const GMT_8 =
        data.date_search.getHours() -
        (8 + data.date_search.getTimezoneOffset() / 60);

      let date = new Date(data.date_search.toISOString());
      await date.setHours(GMT_8);
      data.salaryFilters.pay_date_begin.value = date.toISOString();
      data.salaryFilters.pay_date_end.value = date.toISOString();
    } else {
      data.salaryFilters.pay_date_begin.value = "";
      data.salaryFilters.pay_date_end.value = "";
    }
    getSalaryList();
  }
);
</script>

<template>
  <Toast />
  <div>
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.salarys.data"
      :loading="data.salaryLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.salaryFilters"
      v-model:selection="data.selectedSalarys"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Salary").SalaryTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.date_search"
                    class="p-mr-1"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('SpotExchangeRate').SpotExchangeRateTable
                        .RateOfMonth.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>
          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              v-if="store.state.user.role == 1"
              :label="i18n.$t('Salary').SalaryTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addSalary"
            />
            <Button
              v-if="
                store.state.user.role == 1 && store.state.employer.status == 2
              "
              :label="i18n.$t('Salary').SalaryTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger p-mr-2"
              @click="confirmDeleteSelected"
              :disabled="!data.selectedSalarys || !data.selectedSalarys.length"
            />
            <Button
              icon="pi pi-book"
              class="p-button-info"
              label="Excel"
              @click="Excel"
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Salary').SalaryTable.RecipientEmployerName.Field"
        :header="i18n.$t('Salary').SalaryTable.RecipientEmployerName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Salary').SalaryTable.OrganisationName.Field"
        :header="i18n.$t('Salary').SalaryTable.OrganisationName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.SalaryItemName.Field"
        :header="i18n.$t('Salary').SalaryTable.SalaryItemName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.Dollar.Field"
        :header="i18n.$t('Salary').SalaryTable.Dollar.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.Hours.Field"
        :header="i18n.$t('Salary').SalaryTable.Hours.Show"
      ></Column>
      <!-- <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.ExchangeRate.Field"
        :header="i18n.$t('Salary').SalaryTable.ExchangeRate.Show"
      ></Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('Salary').SalaryTable.ExchangeDollar.Show"
      >
        <template #body="slotProps">
          {{ slotProps.data.dollar * slotProps.data.exchange_rate }}
        </template>
      </Column> -->
      <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.CurrencyName.Field"
        :header="i18n.$t('Salary').SalaryTable.CurrencyName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.Description.Field"
        :header="i18n.$t('Salary').SalaryTable.Description.Show"
      ></Column>
      <Column
        class="p-col-1"
        :header="i18n.$t('Salary').SalaryTable.SettlementOfMonth.Show"
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.settlement_of_month, "YYYY-MM-DD") }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        :header="i18n.$t('Salary').SalaryTable.PayDate.Show"
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.pay_date, "YYYY-MM") }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        :header="i18n.$t('Salary').SalaryTable.CreatedEmployerName.Show"
        :field="i18n.$t('Salary').SalaryTable.CreatedEmployerName.Field"
      >
      </Column>
      <Column
        class="p-col-1"
        :header="i18n.$t('Salary').SalaryTable.CreatedAt.Show"
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
        </template>
      </Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            v-if="
              store.state.user.role == 1 && store.state.employer.status == 2
            "
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Salary').AddSalary.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Salary").SalaryTable.SalaryItemId.Show
        }}</label>
        <Dropdown
          v-model="data.SalaryItemSearch"
          :options="data.selectSalaryItemList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Salary').SalaryTable.SalaryItemId.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field p-d-none">
        <label for="name">{{
          i18n.$t("Salary").SalaryTable.CurrencyName.Show
        }}</label>
        <Dropdown
          v-model="data.CurrencySearch"
          :options="data.selectCurrencyList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Salary').SalaryTable.CurrencyName.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Salary").SalaryTable.Dollar.Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.add_salary.dollar"
          mode="currency"
          currency="USD"
          locale="en-US"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{ i18n.$t("Salary").SalaryTable.Hours.Show }}</label>
        <InputNumber
          id="name"
          v-model="data.add_salary.hours"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Salary").SalaryTable.PayDate.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.add_date"
          view="month"
          dateFormat="yy/mm"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Salary').SalaryTable.PayDate.Show"
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Salary").SalaryTable.SettlementOfMonth.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.add_settlement_of_month_date"
          dateFormat="yy/mm/dd"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Salary').SalaryTable.SettlementOfMonth.Show"
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Salary").SalaryTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_salary.description"
          required="true"
          rows="4"
          cols="43"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Salary').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Salary').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddSalary"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Salary').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.salary"
          >{{ i18n.$t("Salary").Confirm.Content }} <b>{{ data.salary.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Salary').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Salary').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSalary"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Salary').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Salary").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Salary').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Salary').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedSalary"
        />
      </template>
    </Dialog>
  </div>
  <DataTable
    class="p-d-none"
    :value="data.excel.data"
    ref="dt"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :field="i18n.$t('Salary').SalaryTable.RecipientEmployerName.Field"
      :header="i18n.$t('Salary').SalaryTable.RecipientEmployerName.Show"
    ></Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('Salary').SalaryTable.OrganisationName.Field"
      :header="i18n.$t('Salary').SalaryTable.OrganisationName.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Salary').SalaryTable.SalaryItemName.Field"
      :header="i18n.$t('Salary').SalaryTable.SalaryItemName.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Salary').SalaryTable.Dollar.Field"
      :header="i18n.$t('Salary').SalaryTable.Dollar.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Salary').SalaryTable.Hours.Field"
      :header="i18n.$t('Salary').SalaryTable.Hours.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Salary').SalaryTable.ExchangeRate.Field"
      :header="i18n.$t('Salary').SalaryTable.ExchangeRate.Show"
    ></Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('Salary').SalaryTable.ExchangeDollar.Show"
      :field="i18n.$t('Salary').SalaryTable.ExchangeDollar.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Salary').SalaryTable.CurrencyName.Field"
      :header="i18n.$t('Salary').SalaryTable.CurrencyName.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Salary').SalaryTable.Description.Field"
      :header="i18n.$t('Salary').SalaryTable.Description.Show"
    ></Column>
    <Column
      class="p-col-1"
      :header="i18n.$t('Salary').SalaryTable.PayDate.Show"
      :field="i18n.$t('Salary').SalaryTable.PayDate.Field"
    >
    </Column>
    <Column
      class="p-col-1"
      :header="i18n.$t('Salary').SalaryTable.CreatedAt.Show"
      :field="i18n.$t('Salary').SalaryTable.CreatedAt.Field"
    >
    </Column>
  </DataTable>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
